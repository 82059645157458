import {
  SET_ASSESSMENT_NAME,
  SET_YEAR_OF_ASSESSMENT,
  SET_SUSTAINIBILITY_TEAM_MESSAGE,
  SET_ESG_GOAL,
  SET_ABOUT_REPORT,
  SET_DOCUMENT,
  SET_DEFAULT,
  SET_COMPANY_NAME,
  SET_COMPANY_LOGO,
  SET_COMPANY_LOGO_URI,
  SET_SECTORS,
  SET_COMPANY_DESCRIPTION,
  SET_NUMBER_OF_EMPLOYEE,
  SET_COMPANY_EMAIL,
  SET_COMPANY_WEBSITE,
  SET_COMPANY_ADDRESS,
  SET_COMPANY_LANDMARK,
  SET_COMPANY_PINCODE,
  SET_COMPANY_STATE,
  SET_COMPANY_COUNTRY,
  SET_BRANCH_ADDRESS_BASIC,
  SET_BRANCH_LANDMARK,
  SET_BRANCH_PINCODE,
  SET_BRANCH_STATE,
  SET_BRANCH_COUNTRY,
  SET_BRANCH_ADDRESS_ARRAY,
  UNSET_BRANCH_ADDRESS,
  SET_ASSESSMENT_DETAILS,
  SET_MASTER_SECTOR,
  SET_FRAMEWORKS,
  SET_FRAMEWORK_ID,
  SET_TEMPLATE_TYPE,
  SET_BASIC_INFO_RIGHT_TAB,
  SET_BASIC_INFO_TAB,
  SET_REPORTING_REGION,
  SET_ALL_COUNTRIES,
  SET_NEW_YEAR_OF_ASSESSMENT,
} from "../../constants/basicinfoConst/basicinfoConst";
import {
  RESET_TEMPLATE_TYPE,
  SET_FRAMEWORK_DEFAULT_FILTER,
} from "../../constants/fielddata/fielddataConst";
import { SET_FILTERED_FRAMEWORKS } from "../../constants/frameworks/frameworkConstants";

const Intial_assessment = {
  assessmentName: "",
  yearOfAssessment: "",
  newYearOfAssessment: "",
  sustainibilityTeamMessage: "",
  esgGoal: "",
  aboutReport: "",
  document: null,
  default: false,
  companyName: "",
  companyLogo: "",
  companyLogoURI: "",
  sectors: [],
  companyDescription: "",
  numberOfEmployees: "",
  companyEmail: "",
  companyWebsite: "",
  companyAddress: "",
  companyLandmark: "",
  companyPincode: "",
  companyState: "",
  companyCountry: "",
  branchAddress: "",
  branchLandmark: "",
  branchPincode: "",
  branchState: "",
  branchCountry: "",
  branchAddressArray: [],
  masterSectors: [],
  default_filter: "",
  frameworks: [],
  frameworkId: "",
  sectorSelected: "",
  logo: "",
  templateType: "",
  basicInfoRightTab: 0,
  basicInfoTab: 0,
  reportingRegion: "",
  country_id: "",
  isDisclosureAssigned:"",
  allCountries: [],
  filteredFrameworks: {},
  objectDiff: {},
};

export default function assessmentApproval(state = Intial_assessment, action) {
  switch (action.type) {
    case SET_ASSESSMENT_NAME:
      return { ...state, assessmentName: action.payload };
    case SET_YEAR_OF_ASSESSMENT:
      return { ...state, yearOfAssessment: action.payload };
    case SET_NEW_YEAR_OF_ASSESSMENT:
      return { ...state, newYearOfAssessment: action.payload };
    case SET_SUSTAINIBILITY_TEAM_MESSAGE:
      return { ...state, sustainibilityTeamMessage: action.payload };
    case SET_ESG_GOAL:
      return { ...state, esgGoal: action.payload };
    case SET_ABOUT_REPORT:
      return { ...state, aboutReport: action.payload };
    case SET_DOCUMENT:
      return { ...state, document: action.payload };
    case SET_DEFAULT:
      return { ...state, default: action.payload };
    case SET_COMPANY_NAME:
      return { ...state, companyName: action.payload };
    case SET_COMPANY_LOGO:
      return { ...state, companyLogo: action.payload };
    case SET_COMPANY_LOGO_URI:
      return { ...state, companyLogoURI: action.payload };
    case SET_SECTORS:
      return { ...state, sectors: action.payload };
    case SET_COMPANY_DESCRIPTION:
      return { ...state, companyDescription: action.payload };
    case SET_NUMBER_OF_EMPLOYEE:
      return { ...state, numberOfEmployees: action.payload };
    case SET_COMPANY_EMAIL:
      return { ...state, companyEmail: action.payload };
    case SET_COMPANY_WEBSITE:
      return { ...state, companyWebsite: action.payload };
    case SET_COMPANY_ADDRESS:
      return { ...state, companyAddress: action.payload };
    case SET_COMPANY_LANDMARK:
      return { ...state, companyLandmark: action.payload };
    case SET_COMPANY_PINCODE:
      return { ...state, companyPincode: action.payload };
    case SET_COMPANY_STATE:
      return { ...state, companyState: action.payload };
    case SET_COMPANY_COUNTRY:
      return { ...state, companyCountry: action.payload };
    case SET_BRANCH_ADDRESS_BASIC:
      return { ...state, branchAddress: action.payload };
    case SET_BRANCH_LANDMARK:
      return { ...state, branchLandmark: action.payload };
    case SET_BRANCH_PINCODE:
      return { ...state, branchPincode: action.payload };
    case SET_BRANCH_STATE:
      return { ...state, branchState: action.payload };
    case SET_BRANCH_COUNTRY:
      return { ...state, branchCountry: action.payload };
    case SET_MASTER_SECTOR:
      return { ...state, masterSectors: action.payload };
    case SET_FRAMEWORKS:
      return { ...state, frameworks: action.payload };
    case RESET_TEMPLATE_TYPE:
      return { ...state, templateType: action.payload };
    case SET_FRAMEWORK_ID:
      return { ...state, frameworkId: action.payload };
    case SET_FRAMEWORK_DEFAULT_FILTER:
      return { ...state, default_filter: action.payload };
    case SET_BRANCH_ADDRESS_ARRAY:
      state.branchAddressArray.push(action.payload);
      return (state = { ...state });
    case UNSET_BRANCH_ADDRESS:
      state.branchAddressArray.splice(action.payload, 1);
      return (state = { ...state });
    case SET_TEMPLATE_TYPE:
      return { ...state, templateType: action.payload };
    case SET_BASIC_INFO_RIGHT_TAB:
      return { ...state, basicInfoRightTab: action.payload };
    case SET_BASIC_INFO_TAB:
      return { ...state, basicInfoTab: action.payload };
    case SET_REPORTING_REGION:
      return { ...state, reportingRegion: action.payload };
    case SET_ALL_COUNTRIES:
      return { ...state, allCountries: action.payload };
    case SET_FILTERED_FRAMEWORKS:
      return { ...state, filteredFrameworks: action.payload };
    case SET_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        objectDiff: {
          assessmentName:
            action.payload.assessment_name && action.payload.assessment_name,
          yearOfAssessment:
            action.payload.year_of_assessment !== ""
              ? action.payload.year_of_assessment
              : [{ startDate: "", endDate: "" }],
          newYearOfAssessment:
            action.payload.new_year_of_assessment !== ""
              ? action.payload.new_year_of_assessment
              : "",
          sustainibilityTeamMessage:
            action.payload.sustainability_team_message &&
            action.payload.sustainability_team_message,
          esgGoal: action.payload.esg_goal && action.payload.esg_goal,
          aboutReport:
            action.payload.about_report && action.payload.about_report,
          sectors: action.payload.sector && action.payload.sector,
          numberOfEmployees:
            action.payload.employee_count && action.payload.employee_count,
          companyEmail: action.payload.email && action.payload.email,
          companyWebsite: action.payload.website && action.payload.website,
          companyAddress:
            action.payload.head_office.companyAddress &&
            action.payload.head_office.companyAddress,
          companyLandmark:
            action.payload.head_office.companyLandmark &&
            action.payload.head_office.companyLandmark,
          companyPincode:
            action.payload.head_office.companyPincode &&
            action.payload.head_office.companyPincode,
          companyState:
            action.payload.head_office.companyState &&
            action.payload.head_office.companyState,
          companyCountry:
            action.payload.head_office.companyCountry &&
            action.payload.head_office.companyCountry,
          branchAddressArray:
            action.payload.branch_office && action.payload.branch_office,
          companyName:
            action.payload.comapny_name && action.payload.comapny_name,
          frameworkId:
            action.payload.framework_id && action.payload.framework_id,
          default_filter:
            action.payload.default_filter && action.payload.default_filter,
          logo: action.payload.logo && action.payload.logo,
          companyLogoURI:
            action.payload.logo &&
            action.payload.logo.url &&
            action.payload.logo.url,
          companyDescription: action.payload.company_operation,
          sectorSelected: action.payload.sectors_selected,
          templateType: action.payload.template_type,
          reportingRegion: action.payload.reporting_region,
          country_id: action.payload.country_id,
          isDisclosureAssigned:
          action.payload.is_disclosure_assigned &&
          action.payload.is_disclosure_assigned,
        },
        assessmentName:
          action.payload.assessment_name && action.payload.assessment_name,
        yearOfAssessment:
          action.payload.year_of_assessment !== ""
            ? action.payload.year_of_assessment
            : [{ startDate: "", endDate: "" }],
        newYearOfAssessment:
          action.payload.new_year_of_assessment !== ""
            ? action.payload.new_year_of_assessment
            : "",
        sustainibilityTeamMessage:
          action.payload.sustainability_team_message &&
          action.payload.sustainability_team_message,
        esgGoal: action.payload.esg_goal && action.payload.esg_goal,
        aboutReport: action.payload.about_report && action.payload.about_report,
        sectors: action.payload.sector && action.payload.sector,
        numberOfEmployees:
          action.payload.employee_count && action.payload.employee_count,
        companyEmail: action.payload.email && action.payload.email,
        companyWebsite: action.payload.website && action.payload.website,
        companyAddress:
          action.payload.head_office.companyAddress &&
          action.payload.head_office.companyAddress,
        companyLandmark:
          action.payload.head_office.companyLandmark &&
          action.payload.head_office.companyLandmark,
        companyPincode:
          action.payload.head_office.companyPincode &&
          action.payload.head_office.companyPincode,
        companyState:
          action.payload.head_office.companyState &&
          action.payload.head_office.companyState,
        companyCountry:
          action.payload.head_office.companyCountry &&
          action.payload.head_office.companyCountry,
        branchAddressArray:
          action.payload.branch_office && action.payload.branch_office,
        companyName: action.payload.comapny_name && action.payload.comapny_name,
        frameworkId: action.payload.framework_id && action.payload.framework_id,
        framework_preference:
          action.payload.framework_preference &&
          action.payload.framework_preference,
        default_filter:
          action.payload.default_filter && action.payload.default_filter,
        logo: action.payload.logo && action.payload.logo,
        companyLogoURI:
          action.payload.logo &&
          action.payload.logo.url &&
          action.payload.logo.url,
        companyDescription: action.payload.company_operation,
        sectorSelected: action.payload.sectors_selected,
        templateType: action.payload.template_type,
        reportingRegion: action.payload.reporting_region,
        country_id: action.payload.country_id,
        isDisclosureAssigned:
          action.payload.is_disclosure_assigned &&
          action.payload.is_disclosure_assigned,
      });
    //setting reporting region
    default:
      return state;
  }
}

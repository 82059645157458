import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import tickGreen from "../../../images/TickGreen.png";
import { basicInformationTabStyles } from "./basicInformationTabStyles";

const BasicInfoTabLeft = (props) => {
  const classes = basicInformationTabStyles();
  const { setBasicInfoRightTab, basicInfo } = props;
  const handleTabChange = (index) => {
    setBasicInfoRightTab(index);
  };

  const basicData = [
    {
      id: 0,
      mainHead: "Basic Assessment Details",
      subHead:
        "Companies Basic Assessment Details are required to be filled in.",
    },
    {
      id: 1,
      mainHead: "Basic Company Details",
      subHead: "Companies Basic Details are required to be filled in.",
    },
    {
      id: 2,
      mainHead: "Stakeholders",
      subHead: "Basic Details to filled In.",
    },
  ];

  const assessmentDetails =
    basicInfo.assessmentName !== "" &&
    basicInfo.yearOfAssessment[0].startDate !== "" &&
    basicInfo.reportingRegion !== undefined &&
    basicInfo.sustainibilityTeamMessage !== "" &&
    basicInfo.esgGoal !== "" &&
    basicInfo.aboutReport !== "";

  const companyDetails =
    basicInfo.companyName !== "" &&
    basicInfo.companyLogoURI !== "" &&
    basicInfo.sectors.length > 0 &&
    basicInfo.companyDescription !== "" &&
    basicInfo.numberOfEmployees !== "" &&
    basicInfo.companyEmail !== "" &&
    basicInfo.companyWebsite !== "" &&
    basicInfo.companyAddress !== "" &&
    basicInfo.companyLandmark !== "" &&
    basicInfo.companyPincode !== "" &&
    basicInfo.companyState !== "" &&
    basicInfo.companyCountry !== "" &&
    basicInfo.branchAddressArray.length > 0
    

  return (
    <div className="scroll" style={{ marginTop: 18 }}>
      {basicData.map((data, index) => (
        <Card
          variant="outlined"
          className={classes.cardContainerDefault}
          onClick={() => handleTabChange(index)}
          key={data.id}
          style={{
            background:
              data.id === basicInfo.basicInfoRightTab ? "#EBF1F8" : "#FDFDFD",
          }}
        >
          <CardContent className={classes.cardContent}>
            <div style={{ display: "flex" }}>
              <h1
                className={classes.mainHeadingDefault}
                style={{
                  borderLeft:
                    data.id === basicInfo.basicInfoRightTab &&
                    "4px solid #3374B9",
                  paddingLeft: data.id === basicInfo.basicInfoRightTab && 5,
                }}
              >
                {data.mainHead}
              </h1>
              {data.id < basicInfo.basicInfoRightTab &&
                (data.id === 0 ? assessmentDetails : companyDetails) && (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      marginLeft: "auto",
                    }}
                    src={tickGreen}
                    alt=""
                  />
                )}
            </div>

            <h3
              className={classes.subHeadingDefault}
              style={{
                color: data.id === basicInfo.basicInfoRightTab && "#505050",
              }}
            >
              {data.subHead}
            </h3>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default BasicInfoTabLeft;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useCubeQuery } from "@cubejs-client/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Line, Bar, Pie } from "react-chartjs-2";
import { useDeepCompareMemo } from "use-deep-compare";
import Typography from "@material-ui/core/Typography";
import Table from "rc-table";
import moment from "moment";
import {
  setBaseLineValue,
  setTargetLineValue,
} from "../../../actions/datahubActions/datahubActions";
import { Tooltip } from "@material-ui/core";
import { mode } from "crypto-js";
// import Table from "@material-ui/core/Table";
// import { useTable } from "react-table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
const dara = [
  {
    id: 0,
    name: "Total waste Recycled(in metric tonne) ",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Recycling.Recyled_value"],
        order: {
          "Recycling.Recyled_value": "desc",
        },
        dimensions: ["Recycling.Recycled_quantity"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Recycling.Recycled_quantity"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 1,
    name: "Percentage of Female Board of Directors",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Percentage_Female_BOD.femaleBOD_value"],
        order: {
          "Percentage_Female_BOD.femaleBOD_value": "desc",
        },
        dimensions: ["Percentage_Female_BOD.percentageoffemaleBOD"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 0,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Percentage_Female_BOD.percentageoffemaleBOD"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 2,
    name: "Waste generated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Waste.total_waste_generated"],
        order: {
          "Total_Waste.total_waste_generated": "desc",
        },
        dimensions: ["Total_Waste.total_waste"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Waste.total_waste"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 3,
    name: "Total Energy Generated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Energy.Total_Energy"],
        order: {
          "Total_Energy.Total_Energy": "desc",
        },
        dimensions: ["Total_Energy.Energy"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 8,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Energy.Energy"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 4,
    name: "Emission Intensity",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Emission.Total_Emission"],
        order: {
          "Total_Emission.Total_Emission": "desc",
        },
        dimensions: ["Total_Emission.Emission"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 6,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Emission.Emission"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 5,
    name: "Total Energy Consumed (in joules or multipls)",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Energy_Consumption.Total_Energy_Consumption"],
        order: {
          "Total_Energy_Consumption.Total_Energy_Consumption": "desc",
        },
        dimensions: ["Total_Energy_Consumption.Energy_Consumption"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 4,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Energy_Consumption.Energy_Consumption"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 6,
    name: "Rehabilitation and Resettlement",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Rehabilitated.Total_Rehabilitated"],
        order: {
          "Rehabilitated.Total_Rehabilitated": "desc",
        },
        dimensions: ["Rehabilitated.Rehabilitated"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 0,
      y: 24,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Rehabilitated.Rehabilitated"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 7,
    name: "Minimum Wages Paid",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Average_Wages.Total_Wages"],
        order: {
          "Average_Wages.Total_Wages": "desc",
        },
        dimensions: ["Average_Wages.Wages"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 4,
      y: 24,
      w: 8,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Average_Wages.Wages"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 8,
    name: "Total Waste Genrated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Waste_Generated_for_recycling.Total_Waste_Generated"],
        order: {
          "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc",
        },
        dimensions: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 32,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
];
const optionsBar = {
  plugins: {
    title: {
      display: false,
      text: "Chart.js Bar Chart - Stacked",
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const COLORS_SERIES = [
  "#5b8ff9",
  "#5ad8a6",
  "#5e7092",
  "#f6bd18",
  "#6f5efa",
  "#6ec8ec",
  "#945fb9",
  "#ff9845",
  "#299796",
  "#fe99c3",
];
const PALE_COLORS_SERIES = [
  "#d7e3fd",
  "#daf5e9",
  "#d6dbe4",
  "#fdeecd",
  "#dad8fe",
  "#dbf1fa",
  "#e4d7ed",
  "#ffe5d2",
  "#cce5e4",
  "#ffe6f0",
];
const commonOptions = {
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      position: "bottom",
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        padding: 12,
        minRotation: 0,
      },
    },
  },
};
const useDrilldownCallback = ({
  datasets,
  labels,
  onDrilldownRequested,
  pivotConfig,
}) => {
  return React.useCallback(
    (elements) => {
      if (elements.length <= 0) return;
      const { datasetIndex, index } = elements[0];
      const { yValues } = datasets[datasetIndex];
      const xValues = [labels[index]];
      if (typeof onDrilldownRequested === "function") {
        onDrilldownRequested(
          {
            xValues,
            yValues,
          },
          pivotConfig
        );
      }
    },
    [datasets, labels, onDrilldownRequested]
  );
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }

    if (type === "boolean") {
      if (typeof value === "boolean") {
        return value.toString();
      } else if (typeof value === "number") {
        return Boolean(value).toString();
      }

      return value;
    }

    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }

    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const TableRenderer = ({
  resultSet,
  pivotConfig,
  borderColor = "#ddd",
  backgroundColor = "#ffffff",
}) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);

  // Inline CSS styles for the table header, rows, and cells
  const customStyles = `
    .rc-table {
      
    }

    .rc-table-header {
      background-color: #f0f0f0;
    }

    .rc-table-header .rc-table-cell {
      padding: 12px 16px;
      font-weight: bold;
      color: #333;
    }
    
    .rc-table-thead tr {
      border: 1px solid #ddd !important;
      background-color: #cccccc50 !important;
    }

    .rc-table-row {
      background-color: #cccccc10 !important;
      border-bottom: 1px solid #ddd !important;
    }

    .rc-table-cell {
      padding: 10px 16px;
    }

    .rc-table-row:hover {
      background-color: #f9f9f9; 
    }
  `;

  // Append custom styles to the document head
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = customStyles;
  document.head.appendChild(styleSheet);

  return (
    <Table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        border: `1px solid ${borderColor}`,
      }}
      columns={tableColumns}
      data={dataSource}
      tableLayout="fixed"
      rowClassName={() => "custom-row"}
    />
  );
};

function wrapText(text, baseWidth, multiplier) {
  const words = text.split(" ");
  const maxWidth = baseWidth + text.length * multiplier;
  let lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = currentLine.length + word.length + 1; // +1 for the space
    if (width <= maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines.join("\n");
}

const LineChartRenderer = ({
  resultSet,
  pivotConfig,
  onDrilldownRequested,
  borderColor,
  lineChartCustomize,
  backgroundColor,
  chartY_AxisCustomize,
  baseline,
  targetline,
  hasline,
  axisLabels,
}) => {
  const dispatch = useDispatch();

  const { isKPiDrawerOpen } = useSelector((state) => state.dataHub);

  const colorBorder = borderColor;

  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s, index) => ({
        label: s.series.map(
          (r) =>
            resultSet?.loadResponse?.results[0]?.annotation?.measures[r?.x]
              ?.shortTitle ??
            s?.shortTitle ??
            ""
        ),
        data: s.series.map((r) => r?.value),
        yValues: [s.key],
        borderColor: colorBorder ? colorBorder[index] : COLORS_SERIES[index],
        // pointRadius: lineChartCustomize?.pointRadius || 2,
        // tension: lineChartCustomize?.tension || 0.3,
        // pointHoverRadius: lineChartCustomize?.pointHoverRadius || 0,
        // borderWidth: lineChartCustomize?.borderWidth || 1,
        // pointStyle: lineChartCustomize?.pointStyle || "rectRounded",
        fill: false,
      })),
    [
      resultSet,
      pivotConfig,
      colorBorder,
      lineChartCustomize?.pointRadius,
      lineChartCustomize?.tension,
      lineChartCustomize?.pointHoverRadius,
      lineChartCustomize?.borderWidth,
      lineChartCustomize?.pointStyle,
      axisLabels?.x_labels,
      axisLabels?.y_labels,
    ]
  );

  const skipped = (ctx, value) => value;

  const check = resultSet
    ?.series(pivotConfig)
    .map((s, index) => s?.series.map((r) => r.value));

  let allPoint = [];
  const getMaxValue = check.map((v) => allPoint.push(...v));

  const dataforLine =
    check.length > 1
      ? getMaxValue
      : [...getMaxValue, ...getMaxValue, ...getMaxValue, ...getMaxValue];

  const lineDataSets = [
    {
      type: "line",
      label: targetline === "#32A05F" ? "Target" : "Base",
      data: allPoint?.map((d, i, a) => Math.max(...a) + Math.min(...a) * 0.1),
      fill: false,
      borderColor: targetline,
      pointRadius: 5,
      borderDash: (ctx) => skipped(ctx, [6, 6]),
    },
    {
      type: "line",
      label: baseline === "#FFCC66" ? "Base" : "Target",
      data: allPoint?.map((d, i, a) => Math.min(...a) + Math.max(...a) * 0.01),
      fill: false,
      borderColor: baseline,
      borderDash: (ctx) => skipped(ctx, [6, 6]),
    },
  ];

  useEffect(() => {
    if (isKPiDrawerOpen) {
      dispatch(setBaseLineValue(lineDataSets[0].data[0]));
      dispatch(setTargetLineValue(lineDataSets[1].data[0]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const originalLabels = resultSet.categories(pivotConfig).map((c) =>
    // eslint-disable-next-line eqeqeq
    moment(c?.xValues[c?.xValues.length - 1])?._d == "Invalid Date"
      ? resultSet?.loadResponse?.results[0]?.annotation?.measures[c?.x]
          ?.shortTitle || c?.x
      : `${moment(c?.xValues[c?.xValues.length - 1])
          .startOf("quarter")
          .format("MMMM DD")} - ${moment(c?.xValues[c?.xValues.length - 1])
          .endOf("quarter")
          .format("MMMM DD")}`
  );
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label, baseWidth, multiplier)
  );

  const data = {
    labels: wrappedLabels,
    datasets: hasline
      ? [lineDataSets[1], lineDataSets[0], ...datasets]
      : datasets,
  };

  // moment(c?.xValues[c?.xValues.length - 1]).format("MMMM")

  const options = {
    // cutout: d === 4 && '80%',
    // rotation: d === 4 && 270,
    // circumference: d === 4 && 180,
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          display: true,
          align: "center",
          callback: function (value, index) {
            const label = this.getLabelForValue(index);
            return label.split("\n"); // Split the label into multiple lines
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: axisLabels?.x_labels,
          align: "center",
          color: "#242424",
          font: {
            size: 13,
            family: "Poppins",
          },
        },
      },

      y: {
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 10,
          // callback: function (value) {
          //   return Number(value).toFixed(0); // This ensures no decimals are shown
          // },
          // stepSize: 1,
        },
        title: {
          display: true,
          text: axisLabels?.y_labels,
          align: "center",
          color: "#242424",
          font: {
            size: 13,
            family: "Poppins",
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: "top",
        // text: title[0],
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context?.dataset?.label.length > 1
              ? Number(context?.raw)
              : context?.dataset?.label + ": " + Number(context?.raw);
          },
        },
      },
    },

    elements: {
      point: {
        radius: 5,
      },
    },
  };

  // const linePositions = {
  //   id: "linePositions",

  //   beforeDatasetsDraw(chart, args, pluginOptions) {

  //     const dataLength = chart.getDatasetMeta(0);

  //     //  chart.getDatasetMeta(0).data[0].x = 0;

  //     //  chart.getDatasetMeta(0).data[dataLength.length - 1].x = 5000

  //     //  chart.getDatasetMeta(1).data[0].x = 0;

  //     //  chart.getDatasetMeta(1).data[dataLength.length - 1].x = 5000

  //   },
  // };

  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data?.labels,
    pivotConfig,
    onDrilldownRequested,
  });
  return (
    <Line
      type="line"
      data={data}
      options={options}
      // plugins={[linePositions]}

      getElementAtEvent={getElementAtEvent}
    />
  );
};
const BarChartRenderer = ({
  resultSet,
  pivotConfig,
  borderColor,
  onDrilldownRequested,
  backgroundColor,
  barChartCustomize,
  chartX_AxisCustomize,
  chartY_AxisCustomize,
  baseline,
  targetline,
  inGeneralTab,
  hasline,
  axisLabels,
}) => {
  const dispatch = useDispatch();
  const { isKPiDrawerOpen } = useSelector((state) => state.dataHub);
  const color = backgroundColor;
  const colorBorder = borderColor;
  const stacked = !(pivotConfig?.x || []).includes("measures");
  // console.log(resultSet?.loadResponse?.results[0]?.annotation?.measures,resultSet.series(pivotConfig),resultSet.categories(pivotConfig))

  const datasets = useDeepCompareMemo(
    () =>
      resultSet?.series(pivotConfig).map((s, index) => {
        return {
          type: "bar",
          label: s.series.map(
            (r) =>
              resultSet?.loadResponse?.results[0]?.annotation?.measures[r?.x]
                ?.shortTitle ??
              s?.shortTitle ??
              ""
          ),
          data: s.series.map((r) => r?.value),
          // data: [10, 20, 30, 40],
          yValues: [s.key],
          borderColor: colorBorder
            ? stacked
              ? colorBorder[index]
              : colorBorder
            : COLORS_SERIES[index],
          backgroundColor: color
            ? stacked
              ? color[index]
              : color
            : COLORS_SERIES[index], /// change bars stack color

          fill: false,
          borderWidth: barChartCustomize?.borderWidth || 1,
          barThickness: barChartCustomize?.barThickness || 30,
          borderRadius: barChartCustomize?.borderRadius || 5,

          pointRadius: 0,
        };
      }),
    [
      resultSet,
      pivotConfig,
      color,
      colorBorder,
      barChartCustomize?.borderRadius,
      barChartCustomize?.borderWidth,
      barChartCustomize?.barThickness,
      axisLabels?.x_labels,
      axisLabels?.y_labels,
    ]
  );
  const skipped = (ctx, value) => value;

  const check = resultSet
    ?.series(pivotConfig)
    .map((s, index) => s?.series.map((r) => r.value));

  let allPoint = [];
  const getMaxValue = check.map((v) => allPoint.push(...v));

  // const dataforLine = resultSet
  //   ?.series(pivotConfig)
  //   .map((s, index) => s?.series.map((r) => r.value))[0];

  // const check = resultSet
  //   ?.series(pivotConfig)
  //   .map((s, index) => s?.series.map((r) => r.value));

  // const dataforLineMax = check
  //   .map((v) => Math.max(v[0]))
  //   .reduce((a, b) => a + b, 0);

  // const dataforLineMin = check
  //   .map((v) => Math.min(v[v.length - 1]))
  //   .reduce((a, b) => a + b, 0);

  // const dataLength = resultSet?.series(pivotConfig).map((s, index) => s);

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        stacked,
        beginAtZero: true,
        ticks: {
          display: true,
          align: "center",
          callback: function (value, index) {
            const label = this.getLabelForValue(index);
            return label.split("\n"); // Split the label into multiple lines
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: axisLabels?.x_labels,
          align: "center",
          color: "#242424",
          font: {
            size: 15,
            family: "Poppins",
          },
        },
      },

      y: {
        stacked,
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          // callback: function (value) {
          //   return Number(value).toFixed(0); // This ensures no decimals are shown
          // },
          // stepSize: 1,
        },
        title: {
          display: true,
          text: axisLabels?.y_labels,
          align: "center",
          color: "#242424",
          font: {
            size: 13,
            family: "Poppins",
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: "top",
        // text: title[0],
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context?.dataset?.label.length > 1
              ? Number(context?.raw)
              : context?.dataset?.label + ": " + Number(context?.raw);
          },
        },
      },
    },

    // elements: {
    //   point: {
    //     radius: 0,
    //   },
    // },
  };

  const lineDataSets = [
    {
      type: "line",
      label: targetline === "#32A05F" ? "Target" : "Base",
      data: allPoint?.map((d, i, a) => Math.max(...a) + Math.max(...a) * 0.1),
      fill: false,
      borderColor: targetline,
      pointRadius: 3,
      borderDash: (ctx) => skipped(ctx, [6, 6]),
      stacked: "combined",
    },
    {
      type: "line",
      label: baseline === "#FFCC66" ? "Base" : "Target",
      data: allPoint?.map((d, i, a) => Math.min(...a) + Math.max(...a) * 0.01),
      fill: false,
      borderColor: baseline,
      pointRadius: 3,
      borderDash: (ctx) => skipped(ctx, [6, 6]),
      stacked: "combined",
    },
  ];

  const originalLabels = resultSet.categories(pivotConfig).map((c) => {
    // eslint-disable-next-line eqeqeq
    return moment(c?.xValues[c?.xValues.length - 1])?._d == "Invalid Date"
      ? resultSet?.loadResponse?.results[0]?.annotation?.measures[c?.x]
          ?.shortTitle || c?.x
      : `${moment(c?.xValues[c?.xValues.length - 1])
          .startOf("quarter")
          .format("MMMM DD")} - ${moment(c?.xValues[c?.xValues.length - 1])
          .endOf("quarter")
          .format("MMMM DD")}`;
  });
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label, baseWidth, multiplier)
  );

  const data = {
    // labels: resultSet
    //   ?.categories(pivotConfig)
    //   .map((c) => moment(c?.xValues[c?.xValues.length - 1]).format("MMMM DD")),
    labels: wrappedLabels,
    datasets: hasline
      ? [lineDataSets[1], lineDataSets[0], ...datasets]
      : datasets,
    // datasets
  };

  const backColor = ["#245283", "#3374B9", "#C0D4E9"];

  const labelIndex = ["Scope 1", "Scope 2", "SCope 3"];

  const dataBar = {
    labels: resultSet
      ?.categories(pivotConfig)
      .map((c) => moment(c?.xValues[c?.xValues.length - 1]).format("MMMM DD")),
    datasets: [
      ...resultSet?.series(pivotConfig).map((s, index) => ({
        type: "bar",
        label: labelIndex[index],
        data: s.series.map((r) => r.value),
        // data: [10, 20, 30, 40],
        yValues: [s.key],
        borderColor: backColor[index],
        backgroundColor: backColor[index],

        fill: false,
        borderWidth: barChartCustomize?.borderWidth || 1,
        barThickness: barChartCustomize?.barThickness || 30,
        borderRadius: barChartCustomize?.borderRadius || 5,

        pointRadius: 0,
      })),
      // {
      //   label: "Dataset 1",
      //   data: [10, 20, 50, 10, 10, 20, 50, 10],
      //   backgroundColor: "#245283",
      //   borderWidth: 1,
      //   barThickness: 30,
      //   borderRadius: 5,
      // },
      // {
      //   label: "Dataset 2",
      //   data: [10, 20, 50, 10, 10, 20, 50, 10],
      //   backgroundColor: "#3374B9",
      //   borderWidth: 1,
      //   barThickness: 30,
      //   borderRadius: 5,
      // },
      // {
      //   label: "Dataset 3",
      //   data: [10, 20, 50, 10, 10, 20, 50, 10],
      //   backgroundColor: "#C0D4E9",
      //   borderWidth: 1,
      //   barThickness: 30,
      //   borderRadius: 5,
      // },
    ],
  };

  useEffect(() => {
    if (isKPiDrawerOpen) {
      dispatch(setBaseLineValue(lineDataSets[0].data[0]));
      dispatch(setTargetLineValue(lineDataSets[1].data[0]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data?.labels,
    onDrilldownRequested,
    pivotConfig,
  });

  // line Plugin
  const linePositions = {
    id: "linePositions",

    beforeDatasetsDraw(chart, args, pluginOptions) {
      const dataLength = chart.getDatasetMeta(0).data;

      if (dataLength.length > 0) {
        chart.getDatasetMeta(0).data[0].x = 0;

        chart.getDatasetMeta(0).data[dataLength.length - 1].x = 5000;

        chart.getDatasetMeta(1).data[0].x = 0;

        chart.getDatasetMeta(1).data[dataLength.length - 1].x = 5000;
      }
    },
  };

  return inGeneralTab ? (
    <Bar options={optionsBar} data={dataBar} />
  ) : (
    <Bar
      type="bar"
      data={data}
      options={options}
      // plugins={[linePositions]}
      getElementAtEvent={getElementAtEvent}
    />
  );
};
const AreaChartRenderer = ({
  resultSet,
  pivotConfig,
  onDrilldownRequested,
  borderColor,
  backgroundColor,
  chartY_AxisCustomize,
  baseline = "#FFCC66",
  targetline = "#32A05F",
  hasline,
  axisLabels,
}) => {
  const dispatch = useDispatch();
  const { isKPiDrawerOpen } = useSelector((state) => state.dataHub);
  const color = backgroundColor;
  //   pointRadius: lineChartCustomize?.pointRadius || 5,
  //         tension: lineChartCustomize?.tension || 0.3,
  //         pointHoverRadius: lineChartCustomize?.pointHoverRadius || 0,
  //         borderWidth: lineChartCustomize?.borderWidth || 2,
  //         pointStyle: lineChartCustomize?.pointStyle || "rectRounded",
  const datasets = useDeepCompareMemo(
    () =>
      resultSet?.series(pivotConfig).map((s, index) => ({
        label: s.series.map(
          (r) =>
            resultSet?.loadResponse?.results[0]?.annotation?.measures[r?.x]
              ?.shortTitle ??
            s?.shortTitle ??
            ""
        ),
        data: s.series.map((r) => r?.value),
        yValues: [s.key],
        pointRadius: 5,
        pointHoverRadius: 5,
        pointColor: PALE_COLORS_SERIES[index],
        borderColor: borderColor ? borderColor : COLORS_SERIES[index],
        backgroundColor: color ? color : COLORS_SERIES[index],
        borderWidth: 2,
        pointStyle: "circle",
        hitRadius: 10,
        order: 3,
        fill: {
          target: "origin",
          above: color ? color : COLORS_SERIES[index], // Area will be red above the origin
        },
        tension: 0.3,
      })),
    [resultSet, pivotConfig, color, axisLabels?.x_labels, axisLabels?.y_labels]
  );
  const skipped = (ctx, value) => value;

  const check = resultSet
    ?.series(pivotConfig)
    .map((s, index) => s?.series.map((r) => r.value));

  let allPoint = [];

  const getMaxValue = check.map((v) => allPoint.push(...v));

  const lineDataSets = [
    {
      type: "line",
      label: targetline === "#32A05F" ? "Target" : "Base",
      data: allPoint?.map((d, i, a) => Math.max(...a) + Math.max(...a) * 0.1),
      fill: false,
      borderColor: targetline,
      pointRadius: 0,
      borderDash: (ctx) => skipped(ctx, [6, 6]),
    },
    {
      type: "line",
      label: baseline === "#FFCC66" ? "Base" : "Target",
      data: allPoint?.map((d, i, a) => Math.min(...a) + Math.max(...a) * 0.01),
      fill: false,
      borderColor: baseline,
      pointRadius: 0,

      borderDash: (ctx) => skipped(ctx, [6, 6]),
    },
  ];

  useEffect(() => {
    if (isKPiDrawerOpen) {
      dispatch(setBaseLineValue(lineDataSets[0].data[0]));
      dispatch(setTargetLineValue(lineDataSets[1].data[0]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const originalLabels = resultSet.categories(pivotConfig).map((c) =>
    // eslint-disable-next-line eqeqeq
    moment(c?.xValues[c?.xValues.length - 1])?._d == "Invalid Date"
      ? resultSet?.loadResponse?.results[0]?.annotation?.measures[c?.x]
          ?.shortTitle || c?.x
      : `${moment(c?.xValues[c?.xValues.length - 1])
          .startOf("quarter")
          .format("MMMM DD")} - ${moment(c?.xValues[c?.xValues.length - 1])
          .endOf("quarter")
          .format("MMMM DD")}`
  );
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label, baseWidth, multiplier)
  );

  const data = {
    // labels: resultSet
    //   ?.categories(pivotConfig)
    //   .map((c) => moment(c?.xValues[c?.xValues.length - 1]).format("MMMM DD")),
    labels: wrappedLabels,
    datasets: hasline
      ? [lineDataSets[1], lineDataSets[0], ...datasets]
      : datasets,
    // datasets,

    // datasets
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          display: true,
          align: "center",
          callback: function (value, index) {
            const label = this.getLabelForValue(index);
            return label.split("\n"); // Split the label into multiple lines
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: axisLabels?.x_labels,
          align: "center",
          color: "#242424",
          font: {
            size: 15,
            family: "Poppins",
          },
        },
      },

      y: {
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          // callback: function (value) {
          //   return Number(value).toFixed(0); // This ensures no decimals are shown
          // },
          // stepSize: 1,
        },
        title: {
          display: true,
          text: axisLabels?.y_labels,
          align: "center",
          color: "#242424",
          font: {
            size: 13,
            family: "Poppins",
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: "top",
        // text: title[0],
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context?.dataset?.label.length > 1
              ? Number(context?.raw)
              : context?.dataset?.label + ": " + Number(context?.raw);
          },
        },
      },
    },

    // elements: {
    //   point: {
    //     radius: 0,
    //   },
    // },
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data?.labels,
    onDrilldownRequested,
    pivotConfig,
  });
  return (
    <Line
      type="area"
      data={data}
      options={options}
      getElementAtEvent={getElementAtEvent}
    />
  );
};
const PieChartRenderer = ({
  borderColor,
  backgroundColor,
  resultSet,
  pivotConfig,
  onDrilldownRequested,
  pieChartCustomize,
}) => {
  const color = backgroundColor;
  const colorBorder = borderColor;

  const optionsPie = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 0,
    rotation: 0,
    circumference: 360,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      title: {
        display: false,
        text: "title[0]",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context);
            return context?.label.split("\n");
          },
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s) => ({
        label: s.series.map(
          (r) =>
            resultSet?.loadResponse?.results[0]?.annotation?.measures[r?.x]
              ?.shortTitle ??
            s?.shortTitle ??
            ""
        ),
        data: s.series.map((r) => r?.value),
        yValues: [s.key],

        backgroundColor: color
          ? color
          : ["#FF6A00", "#FFB43D", "#8246AF", "#FF7675"],
        hoverBackgroundColor: colorBorder
          ? colorBorder
          : ["#FF6A00", "#FFB43D", "#8246AF", "#FF7675"],
      })),
    [resultSet, pivotConfig, color, colorBorder]
  );

  const originalLabels = resultSet.categories(pivotConfig).map(
    (c) =>
      resultSet?.loadResponse?.results[0]?.annotation?.measures[c?.x]
        ?.shortTitle || c?.x
    // eslint-disable-next-line eqeqeq
    // moment(c?.xValues[c?.xValues.length - 2])?._d == "Invalid Date"
    // c?.x.split(",")[0]
    // : moment(c?.xValues[c?.xValues.length - 1]).format("MMMM DD")
  );
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label, baseWidth, multiplier)
  );

  const data = {
    // labels: pieChartCustomize,
    // labels: resultSet.categories(pivotConfig).map((c) =>
    //   // eslint-disable-next-line eqeqeq
    //   moment(c?.xValues[c?.xValues.length - 1])?._d == "Invalid Date"
    //     ? c?.x
    //     : moment(c?.xValues[c?.xValues.length - 1]).format("MMMM DD")
    // ),
    labels: wrappedLabels,

    datasets,
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data?.labels,
    pivotConfig,
    onDrilldownRequested,
  });
  return (
    <Pie
      type="pie"
      data={data}
      options={optionsPie}
      getElementAtEvent={getElementAtEvent}
    />
  );
};
const TypeToChartComponent = {
  line: ({
    resultSet,
    borderColor,
    backgroundColor,
    pivotConfig,
    onDrilldownRequested,
    lineChartCustomize,
    chartY_AxisCustomize,
    baseline,
    targetline,
    hasline,
    axisLabels,
  }) => {
    return (
      <LineChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        lineChartCustomize={lineChartCustomize}
        chartY_AxisCustomize={chartY_AxisCustomize}
        baseline={baseline}
        targetline={targetline}
        hasline={hasline}
        axisLabels={axisLabels}
      />
    );
  },
  bar: ({
    resultSet,
    borderColor,
    pivotConfig,
    onDrilldownRequested,
    backgroundColor,
    barChartCustomize,
    chartX_AxisCustomize,
    chartY_AxisCustomize,
    baseline,
    targetline,
    inGeneralTab,
    hasline,
    axisLabels,
  }) => {
    return (
      <BarChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        barChartCustomize={barChartCustomize}
        chartX_AxisCustomize={chartX_AxisCustomize}
        chartY_AxisCustomize={chartY_AxisCustomize}
        baseline={baseline}
        targetline={targetline}
        inGeneralTab={inGeneralTab}
        hasline={hasline}
        axisLabels={axisLabels}
      />
    );
  },
  area: ({
    resultSet,
    borderColor,
    backgroundColor,
    pivotConfig,
    onDrilldownRequested,
    chartY_AxisCustomize,
    baseline,
    targetline,
    hasline,
    axisLabels,
  }) => {
    return (
      <AreaChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        chartY_AxisCustomize={chartY_AxisCustomize}
        baseline={baseline}
        targetline={targetline}
        hasline={hasline}
        axisLabels={axisLabels}
      />
    );
  },
  pie: ({
    resultSet,
    borderColor,
    backgroundColor,
    pivotConfig,
    onDrilldownRequested,
    pieChartCustomize,
  }) => {
    return (
      <PieChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        pieChartCustomize={pieChartCustomize}
      />
    );
  },
  number: ({ resultSet, backgroundColor }) => {
    return (
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          color: backgroundColor || "#242424",
        }}
      >
        {resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key])}
      </Typography>
    );
  },
  table: ({ resultSet, pivotConfig, borderColor, backgroundColor }) => {
    return (
      <TableRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
      />
    );
  },
};

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({
    ...a,
    ...b,
  }));

const renderChart =
  (Component) =>
  ({
    resultSet,
    pivotConfig,
    backgroundColor,
    borderColor,
    barChartCustomize,
    chartX_AxisCustomize,
    chartY_AxisCustomize,
    lineChartCustomize,
    pieChartCustomize,
    baseline,
    targetline,
    error,
    inGeneralTab,
    hasline,
    axisLabels,
    ...props
  }) => {
    return (
      (resultSet && (
        <Component
          pivotConfig={pivotConfig}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          resultSet={resultSet}
          barChartCustomize={barChartCustomize}
          chartX_AxisCustomize={chartX_AxisCustomize}
          chartY_AxisCustomize={chartY_AxisCustomize}
          lineChartCustomize={lineChartCustomize}
          pieChartCustomize={pieChartCustomize}
          baseline={baseline}
          targetline={targetline}
          inGeneralTab={inGeneralTab}
          hasline={hasline}
          axisLabels={axisLabels}
          {...props}
        />
      )) ||
      (error && error.toString()) || <CircularProgress />
    );
  };

const ChartRenderer = ({
  vizState = {},
  pivotConfig,
  backgroundColor,
  borderColor,
  barChartCustomize,
  chartX_AxisCustomize,
  chartY_AxisCustomize,
  lineChartCustomize,
  pieChartCustomize,
  baseline,
  targetline,
  inGeneralTab,
  hasline,
  axisLabels,
}) => {
  const { query, chartType, ...options } = vizState;

  const component = TypeToMemoChartComponent[chartType];

  const renderProps = useCubeQuery(query);

  return (
    component &&
    renderChart(component)({
      pivotConfig,
      backgroundColor,
      borderColor,
      barChartCustomize,
      chartX_AxisCustomize,
      chartY_AxisCustomize,
      lineChartCustomize,
      pieChartCustomize,
      baseline,
      targetline,
      inGeneralTab,
      hasline,
      axisLabels,
      ...options,
      ...renderProps,
    })
  );
};
export default ChartRenderer;

import React from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { CustomDataInput } from "./exploreQBuilder/SelectChartType";
import Mitigation from "./mitigation";
import ChartRenderer from "../../../screen/dashboardStatic/ChartRenderedDashboard";
import {
  setBaseLineValue,
  setKpiDrawerState,
  setTargetLineValue,
} from "../../../../actions/datahubActions/datahubActions";
function DashboardKpiDrawer({ open, onClose, chartData }) {
  const {
    chartY_AxisCustomize,
    vizState: { chartType },
  } = chartData;

  const dispatch = useDispatch();

  const { baselineValue, targetlineValue } = useSelector(
    (state) => state.dataHub
  );

  const { current_organisation } = useSelector((state) => state.login);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#32A05F",
    },
  }))(LinearProgress);

  const kpiProgress =
    targetlineValue && baselineValue
      ? (targetlineValue / baselineValue).toFixed(2) * 100
      : 0;

  const handleClose = () => {
    dispatch(setKpiDrawerState(false));

    onClose();
    dispatch(setBaseLineValue(0));
    dispatch(setTargetLineValue(0));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      // variant="persistent"
      PaperProps={{
        style: {
          width: "65%",
        },
      }}
    >
      <div
        className="scroll"
        style={{
          //   minWidth: 1200,
          height: "100vh",
          //   maxWidth: 1200,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          style={{ padding: "12px" }}
        >
          <Grid item>
            <Typography
              style={{ fontWeight: 600, fontSize: "23px" }}
              variant="h6"
            >
              {chartData.name}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <ExitToAppIcon />
            </IconButton>
          </Grid>
        </Grid>
        {chartType !== "pie" && (
          <div
            style={{
              display: "flex",
              padding: 8,
              margin: 0,
              width: "100%",
            }}
          >
            <div style={{ width: "20%", padding: 2 }}>
              <div>Baseline </div>
              <div style={{ marginTop: 8 }}>
                <CustomDataInput
                  value={baselineValue.toFixed(2)}
                  style={{ width: "100%" }}
                  type="text"
                />
              </div>
            </div>
            <div style={{ width: "20%", padding: 2 }}>
              <div>Target</div>
              <div style={{ marginTop: 8 }}>
                <CustomDataInput
                  value={targetlineValue.toFixed(2)}
                  style={{ width: "100%" }}
                  type="text"
                />
              </div>
            </div>
            <div style={{ width: "20%", padding: 2 }}>
              <div>Unit</div>
              <div style={{ marginTop: 8 }}>
                <CustomDataInput
                  style={{ width: "100%" }}
                  type="text"
                  value={chartY_AxisCustomize?.text || "Count"}
                />
              </div>
            </div>
            <div
              style={{
                dispaly: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "15%",
                padding: "10px 2px 2px 5px",
              }}
            >
              <div>
                <Typography>
                  <span style={{ color: "#FFCC66" }}>-----</span> Baseline
                </Typography>
              </div>
              <div>
                <Typography>
                  <span style={{ color: "#32A05F" }}>-----</span> Target
                </Typography>
              </div>
            </div>
            <div
              style={{
                dispaly: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "25%",
                padding: "10px 2px 2px 5px",
              }}
            >
              <div>Kpi Progress</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "100%" }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={kpiProgress > 100 ? 100 : kpiProgress}
                  />
                </div>
                <div>
                  <Typography
                    style={{ fontWeight: 600, fontSize: 20, padding: 5 }}
                  >
                    {kpiProgress > 100 ? 100 : kpiProgress}%
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: "10px", padding: "14px", height: "40%" }}>
          {chartData && (
            <ChartRenderer
              vizState={{
                query: {
                  ...chartData?.vizState?.query,
                  timeDimensions: chartData?.vizState?.query.hasOwnProperty(
                    "timeDimensions"
                  )
                    ? chartData?.vizState?.query?.timeDimensions.length > 0
                      ? [
                          {
                            dimension: `${
                              chartData?.vizState?.query?.measures[0].split(
                                "."
                              )[0]
                            }.date`,
                            granularity: "quarter",
                          },
                        ]
                      : []
                    : [],
                  filters: [
                    {
                      member: `${
                        chartData?.vizState?.query?.measures[0].split(".")[0]
                      }.organisationid`,
                      operator: "equals",
                      values: [current_organisation],
                    },
                  ],
                },
                chartType: chartData?.vizState?.chartType,
              }}
              pivotConfig={chartData?.pivotConfig}
              backgroundColor={chartData?.chartColors?.backgroundColors}
              borderColor={chartData?.chartColors?.borderColor}
              pieChartCustomize={chartData?.label}
              // chartY_AxisCustomize={chartData?.chartY_AxisCustomize}
              baseline={"#FFCC66"}
              targetline={"#32A05F"}
              hasline={true}
            />
          )}
        </div>
        <Divider style={{ marginTop: "10px" }} />
        <Mitigation title={chartData?.name} />
      </div>
    </Drawer>
  );
}

export default DashboardKpiDrawer;

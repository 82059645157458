import React, { useEffect } from "react";
import CustomButton from "../../UI/button/button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import proceedButtonUseStyles from "./proceedButtonStyles";

function ProceedButtonCard(props) {
  const classes = proceedButtonUseStyles();

  const {
    activeStep,
    updateBasicInfo,
    basicInfo,
    assessment_id,
    login,
    setActiveStep,
    set_snack_bar,
    finishAssessment,
    fieldData,
    materialityAssessment,
    esgStrategy,
    setTopicTab,
    setReportTab,
    setRedirectValue,
    setIsTutorialCompleted,
    setBasicInfoTab,
    setBasicInfoRightTab,
    updateBasicAssessmentDetails,
    updateBasicCompanyDetails,
    viewAssignedMatrixUsers,
    getInternalMaturityMatrix,
    getSelectedTopic,
    getMaterialityMapData,
  } = props;
  const handleFinishClick = () => {
    setRedirectValue(false);
    finishAssessment(login.token, assessment_id);
    set_snack_bar(false, "All steps are completed...");
    setReportTab(fieldData.reportTab - 2);
    setIsTutorialCompleted(true);
  };
  useEffect(() => {
    setRedirectValue(false);
  }, []);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {fieldData?.upsert ||
        materialityAssessment.isUpsert ||
        esgStrategy.isUpsert ? (
          <CustomButton
            name="exitBtn"
            color="primary"
            variant="outlined"
            className={classes.costumButton}
            disabled
          >
            {activeStep === 0 ? "Save & Exit" : "Exit"}
          </CustomButton>
        ) : (
          <Link className={classes.link} to="/survey_listing">
            <CustomButton
              name="exitBtn"
              color="primary"
              variant={activeStep === 5 ? "contained" : "outlined"}
              className={classes.costumButton}
              onClick={() => {
                if (activeStep === 0) {
                  setRedirectValue(false);
                  if (activeStep === 0) {
                    if (
                      basicInfo.basicInfoRightTab === 0 &&
                      basicInfo.basicInfoTab === 0
                    ) {
                      updateBasicAssessmentDetails(
                        login.token,
                        assessment_id,
                        basicInfo,
                        true
                      );
                    }
                    if (
                      basicInfo.basicInfoRightTab === 1 &&
                      basicInfo.basicInfoTab === 0
                    ) {
                      updateBasicCompanyDetails(
                        login.token,
                        assessment_id,
                        basicInfo
                      );
                    }
                  }
                }
              }}
              disabled={
                fieldData?.isUpsertMetricData ||
                materialityAssessment.isUpsert ||
                esgStrategy.isUpsert
              }
            >
              {activeStep === 0 || activeStep === 5 ? "Save & Exit" : "Exit"}
            </CustomButton>
          </Link>
        )}
        {(activeStep === 0 ||
          activeStep === 1 ||
          activeStep === 2 ||
          activeStep === 3 ||
          activeStep === 4) && (
          <>
            <CustomButton
              name="proceedBtn"
              color="primary"
              variant="contained"
              data-tut="reactour__proceed"
              onClick={() => {
                if (activeStep === 0) {
                  if (
                    basicInfo.basicInfoRightTab === 0 &&
                    basicInfo.basicInfoTab === 0
                  ) {
                    if (basicInfo.assessmentName === "") {
                      set_snack_bar(true, "Please enter Assessment Name !");
                    } else if (
                      basicInfo.reportingRegion === null ||
                      basicInfo.reportingRegion === undefined
                    ) {
                      set_snack_bar(true, "Please select a country!");
                    } else if (
                      basicInfo.frameworkId === "" ||
                      basicInfo.frameworkId === undefined
                    ) {
                      set_snack_bar(true, "Please select a framework!");
                    } else {
                      updateBasicAssessmentDetails(
                        login.token,
                        assessment_id,
                        basicInfo,
                        true
                      );
                      setBasicInfoRightTab(1);
                    }
                  }
                  if (
                    basicInfo.basicInfoRightTab === 1 &&
                    basicInfo.basicInfoTab === 0
                  ) {
                    updateBasicCompanyDetails(
                      login.token,
                      assessment_id,
                      basicInfo
                    );
                    setBasicInfoRightTab(2);
                  }
                  if (basicInfo.basicInfoRightTab === 2) {
                    setBasicInfoTab(1);
                    getInternalMaturityMatrix(login.token, assessment_id);
                    viewAssignedMatrixUsers(
                      login.token,
                      assessment_id,
                      login.current_organisation
                    );
                  }
                  if (basicInfo.basicInfoTab === 1) {
                    setActiveStep(activeStep + 1);
                  }
                }
                if (activeStep === 1) {
                  if (materialityAssessment.topicTab + 1 === 2) {
                    // getSelectedTopic(login.token, assessment_id);
                    getMaterialityMapData(login.token, assessment_id);
                  }
                  materialityAssessment.topicTab < 2
                    ? setTopicTab(materialityAssessment.topicTab + 1)
                    : setActiveStep(activeStep + 1);
                }
                activeStep === 2 &&
                  setActiveStep(activeStep + 1) &&
                  setTopicTab(materialityAssessment.topicTab - 2);
                activeStep === 3 && setActiveStep(activeStep + 1);
                // console.log(fieldData.reportTab)
                activeStep === 4 &&
                  (fieldData?.reportTab < 2
                    ? setReportTab(fieldData?.reportTab + 1)
                    : setActiveStep(activeStep + 1));
              }}
              className={classes.checkmargin}
              disabled={
                fieldData?.isUpsertMetricData ||
                materialityAssessment.isUpsert ||
                esgStrategy.isUpsert
              }
            >
              Proceed
            </CustomButton>
          </>
        )}
        {/* {activeStep === 5 && (
          <Link className={classes.link} to="/survey_listing">
            <CustomButton
              color="primary"
              variant="contained"
              className={classes.costumButton}
              onClick={handleFinishClick}
              data-tut="reactour__proceed"
            >
              Finish
            </CustomButton>
          </Link>
        )} */}
      </CardContent>
    </Card>
  );
}

export default ProceedButtonCard;

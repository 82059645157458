import SearchTextField from "../../../UI/textfield/searchTextField";

const Tab2Buttons = () => {
  const handleSearch = (keyword) => {
    console.log(keyword);
  };

  return <SearchTextField searchClick={handleSearch} />;
};
export default Tab2Buttons;

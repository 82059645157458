import React, { useState } from "react";
import Icon from "@material-ui/core/Icon";
import downloadGreen from "../../../images/downloadGreen.png";
import uploadWhite from "../../../images/uploadWhite.png";
import pin from "../../../images/pin.png";
import filter from "../../../images/filter.png";
import plugIn from "../../../images/plugIn.png";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomButton from "../../UI/button/button";
import { Popover, makeStyles, MenuItem } from "@material-ui/core";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { setSelectedKpiType } from "../../../actions/datahubActions/datahubActions";
import Tab2Buttons from "./tabsButtonComps/Tab2Buttons";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: "210px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#000",
    background: "#FFFFFF",
    textTransform: "capitalize",
    height: "4em",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
}));

function DataHubButtons(props) {
  const { value, uploadHandler } = props;
  const classes = useStyles();

  const [showMenu, setShowMenu] = useState(null);

  const { selectedKpiType } = useSelector((state) => state.dataHub);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleClose = () => {
    setShowMenu(null);
  };

  const downloadFile = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.click();
    handleClose();
  };

  return (
    <>
      {value === 1 && (
        <CustomButton
          startIcon={<Icon component={"img"} src={plugIn} />}
          color="secondary"
          variant="contained"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            borderRadius: "8px",
            textTransform: "capitalize",
            height: "35px",
            fontWeight: 500,
            fontSize: "14px",
          }}
          onClick={() => value === 1 && uploadHandler()}
        >
          Integration & Connected Apps
        </CustomButton>
      )}
      {value === 0 && (
        <>
          <CustomButton
            startIcon={<Icon component={"img"} src={downloadGreen} />}
            color="secondary"
            variant="outlined"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              background: "#FFFFFF",
              borderRadius: "8px",
              textTransform: "capitalize",
              height: "3em",
            }}
            onClick={handleClick}
          >
            Download Template
          </CustomButton>
          <CustomButton
            startIcon={<Icon component={"img"} src={uploadWhite} />}
            color="secondary"
            variant="contained"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              borderRadius: "8px",
              textTransform: "capitalize",
              marginLeft: "15px",
              height: "2.8em",
            }}
            onClick={() => value === 0 && uploadHandler()}
          >
            Upload
          </CustomButton>
          <Popover
            open={Boolean(showMenu)}
            anchorEl={showMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <CustomButton
              color="secondary"
              variant="outlined"
              className={classes.menuItem}
              onClick={() => {
                downloadFile(
                  "https://docs.google.com/spreadsheets/d/e/2PACX-1vSqlLY-6lHFSYk1O2h0je9P1FxNu3oZzqTtdXdyz7H7yN0ihr6rXq1z7aI6Zt9y5Gc2t22sDofjjxHI/pub?output=xlsx"
                );
              }}
            >
              BRSR Template
            </CustomButton>
            <CustomButton
              color="secondary"
              variant="outlined"
              className={classes.menuItem}
              onClick={() => {
                downloadFile(
                  "https://docs.google.com/spreadsheets/d/e/2PACX-1vRh_K5IO-dKTLxpFdQNy1_sXH2gweEMJs1ppPMuGYe76VeeaaSys0HQFpFdXZ8SFgZ39qrliWW6ZPjg/pub?output=xlsx"
                );
                // handleClose();
              }}
            >
              GRI Template
            </CustomButton>
          </Popover>
        </>
      )}
      {value === 2 && <Tab2Buttons />}
      {value === 3 && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <CustomButton
            endIcon={<Icon component={"img"} src={filter} />}
            // color="red"
            variant="outlined"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              background: "#FFFFFF",
              borderRadius: "8px",
              textTransform: "capitalize",
              height: "3em",
              color: "#15314E",
              borderColor: "#C0D4E9",
              width: "35%",
              justifyContent: "space-between",
              marginRight: "10px",
            }}
          >
            filter
          </CustomButton> */}
          <CustomButton
            endIcon={<Icon component={"img"} src={pin} />}
            variant="outlined"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              background: "#FFFFFF",
              borderRadius: "8px",
              textTransform: "capitalize",
              height: "3em",
              color: "#15314E",
              borderColor: "#C0D4E9",
              width: "35%",
              justifyContent: "space-between",
            }}
            onClick={handleClick}
          >
            {selectedKpiType}
          </CustomButton>
          {/* <MoreVertIcon style={{ marginLeft: "5px", cursor: "pointer" }} /> */}

          {/* Popover for Selected Kpi Type */}
          <Popover
            open={Boolean(showMenu)}
            anchorEl={showMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                dispatch(setSelectedKpiType("BRSR"));
                handleClose();
              }}
            >
              BRSR
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                dispatch(setSelectedKpiType("GRI"));
                handleClose();
              }}
            >
              GRI
            </MenuItem>
          </Popover>
        </div>
      )}
    </>
  );
}

export default React.memo(DataHubButtons);

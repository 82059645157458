import React from "react";
import { connect } from "react-redux";
import {
  getReports,
  setReportValue,
  // getViewSurvey,
  getSurveyResult,
  upsetSurveyResult,
  setSurveyResult,
  setSurveyType,
  setSurveyData,
  setUpsert,
  getTopicMetric,
  getMetrics,
  setReportTab,
  getReviewData,
  upsertMetricsData,
  setIsUpsertMetricData,
  setCurrentMetric,
  addMetricsData,
  updateOmission,
  assignMembers,
  updateFramework,
  viewAssignedMembers,
  setFreezeLoading,
  setFreezeUploaded,
  // metricResult,
  updateMetricResult,
  getBlockchainHashlinks,
  setBlockchainHashlinks,
  freezeData,
  setCurrentReviewMetrics,
  setReviewMetrics,
  setMetrics,
  getCollectMetrics,
  getAllMetrics,
  // getBrsrMetric,
  viewAssignMembers,
  deleteAssignedMember,
  setTopicMetric,
  setFrameworkDefaultFilter,
  resetTemplateType,
  viewChats,
  setFilteredMetrics,
  getdetailedMetrics,
  getDisclosureSettingsResponses,
  getDisclosureSettingsFiles,
  getSubmissionDetails,
  deleteFile,
  updateSettings,
  setDisclosureSettingsViewSubmission,
  getSettingsData,
  approveSubmission,
  setMetricChats,
  viewAllUsers_step5,
  changeStatus,
  // getAssignedMetric,
  assignMembers_step5,
  getSustainabilityDocx,
  getSustainabilityXBRL,
  getSustainabilityExcel,
} from "../../actions/fielddata/fieldDataAction";
import { setFrameworkId } from "../../actions/basicinfoAction/basicinfoAction";
import { viewActivityLog } from "../../actions/surveyListing/surveyListingAction";
import FieldData from "../../components/sustainabilityReport/fieldData";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import {
  viewAllUsers,
  removeFile,
} from "../../actions/generalSettings/generalSettingsAction";

export class FieldDataCont extends React.PureComponent {
  render() {
    return <FieldData {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  basicInfo: store.basicInfo,
  fieldData: store.fieldData,
  esgStrategy: store.esgStrategy,
  materialityAssessment: store.materialityAssessment,
  generalSettings: store.generalSettings,
});

export const mapDispatchToProps = (dispatch) => ({
  getReports: (payload) => {
    dispatch(getReports(payload));
  },
  setReportValue: (payload) => {
    dispatch(setReportValue(payload));
  },
  setReviewMetrics: (payload) => {
    dispatch(setReviewMetrics(payload));
  },
  setMetrics: (payload) => {
    dispatch(setMetrics(payload));
  },
  setFilteredMetrics: (payload) => {
    dispatch(setFilteredMetrics(payload));
  },
  // getViewSurvey: (token, report_id) => {
  // 	dispatch(getViewSurvey(token, report_id));
  // },
  getSurveyResult: (token, metric_id, assessment_id) => {
    dispatch(getSurveyResult(token, metric_id, assessment_id));
  },
  getDisclosureSettingsResponses: (token, metric_id, assessment_id) => {
    dispatch(getDisclosureSettingsResponses(token, metric_id, assessment_id));
  },
  upsetSurveyResult: (
    token,
    survey_id,
    assessment_id,
    data,
    organisation_id
  ) => {
    dispatch(
      upsetSurveyResult(token, survey_id, assessment_id, data, organisation_id)
    );
  },
  setSurveyResult: (payload) => {
    dispatch(setSurveyResult(payload));
  },
  setSurveyType: (payload) => {
    dispatch(setSurveyType(payload));
  },
  setSurveyData: (payload) => {
    dispatch(setSurveyData(payload));
  },
  setUpsert: (payload) => {
    dispatch(setUpsert(payload));
  },
  // getSelectedMetrics: (token, assessment_id) => {
  //   dispatch(getSelectedMetrics(token, assessment_id))
  // },
  viewActivityLog: (user_token, assessment_id, step_no) => {
    dispatch(viewActivityLog(user_token, assessment_id, step_no));
  },
  setCurrentReviewMetrics: (payload) => {
    dispatch(setCurrentReviewMetrics(payload));
  },
  // New
  getTopicMetric: (user_token, assessment_id, framework_id, template_type) => {
    dispatch(
      getTopicMetric(user_token, assessment_id, framework_id, template_type)
    );
  },
  setTopicMetric: (payload) => {
    dispatch(setTopicMetric(payload));
  },
  // getBrsrMetric: (token, framework_id) => {
  // 	dispatch(getBrsrMetric(token, framework_id));
  // },

  getMetrics: (
    user_token,
    assessment_id,
    metric_id,
    review_data,
    template_type
  ) => {
    dispatch(
      getMetrics(
        user_token,
        assessment_id,
        metric_id,
        review_data,
        template_type
      )
    );
  },
  getAllMetrics: (user_token, framework_id, template_type, assessment_id) => {
    dispatch(
      getAllMetrics(user_token, framework_id, template_type, assessment_id)
    );
  },
  viewAllUsers_step5: (token, assessment_id, metric_id, organisation_id) => {
    dispatch(
      viewAllUsers_step5(token, assessment_id, metric_id, organisation_id)
    );
  },

  getCollectMetrics: (
    user_token,
    assessment_id,
    metric_id,
    main_topic_id,
    template_type,
    organisation_id
  ) => {
    dispatch(
      getCollectMetrics(
        user_token,
        assessment_id,
        metric_id,
        main_topic_id,
        template_type,
        organisation_id
      )
    );
  },
  getdetailedMetrics: (user_token, metric_id, assessment_id) => {
    dispatch(getdetailedMetrics(user_token, metric_id, assessment_id));
  },
  getReviewData: (user_token, assessment_id) => {
    dispatch(getReviewData(user_token, assessment_id));
  },
  upsertMetricsData: (user_token, assessment_id, metric_id, comment, file) => {
    dispatch(
      upsertMetricsData(user_token, assessment_id, metric_id, comment, file)
    );
  },
  addMetricsData: (
    user_token,
    assessment_id,
    metric_id,
    parent_id,
    metric_data,
    comment,
    file,
    main_topic_ids,
    parent_metric_id,
    metric_name,
    metric_desc
  ) => {
    dispatch(
      addMetricsData(
        user_token,
        assessment_id,
        metric_id,
        parent_id,
        metric_data,
        comment,
        file,
        main_topic_ids,
        parent_metric_id,
        metric_name,
        metric_desc
      )
    );
  },

  updateOmission: (
    user_token,
    assessment_id,
    metric_id,
    parent_id,
    omit,
    main_topic_id,
    review_data,
    file,
    omission_reason,
    omission_explanation
  ) => {
    dispatch(
      updateOmission(
        user_token,
        assessment_id,
        metric_id,
        parent_id,
        omit,
        main_topic_id,
        review_data,
        file,
        omission_reason,
        omission_explanation
      )
    );
  },
  setReportTab: (payload) => {
    dispatch(setReportTab(payload));
  },
  setIsUpsertMetricData: (payload) => {
    dispatch(setIsUpsertMetricData(payload));
  },
  setCurrentMetric: (payload) => {
    dispatch(setCurrentMetric(payload));
  },
  assignMembers: (
    user_token,
    email_ids,
    step_no,
    assessment_id,
    organisation_id,
    metrics_ids,
    parent_metric_id,
    main_topic_ids,
    role,
    approver_emails,
    template_type
  ) => {
    dispatch(
      assignMembers(
        user_token,
        email_ids,
        step_no,
        assessment_id,
        organisation_id,
        metrics_ids,
        parent_metric_id,
        main_topic_ids,
        role,
        approver_emails,
        template_type
      )
    );
  },
  set_snack_bar: (status, message) => {
    dispatch(set_snack_bar(status, message));
  },
  updateFramework: (token, assessment_id, framework_id, template_type) => {
    dispatch(
      updateFramework(token, assessment_id, framework_id, template_type)
    );
  },
  viewAssignMembers: (token, assessment_id, metrics_ids) => {
    dispatch(viewAssignMembers(token, assessment_id, metrics_ids));
  },
  setFrameworkId: (id) => {
    dispatch(setFrameworkId(id));
  },
  resetTemplateType: (type) => {
    dispatch(resetTemplateType(type));
  },
  setFrameworkDefaultFilter: (filter) => {
    dispatch(setFrameworkDefaultFilter(filter));
  },
  viewAssignedMembers: (token, assessment_id, step_no, metric_id) => {
    dispatch(viewAssignedMembers(token, assessment_id, step_no, metric_id));
  },
  setFreezeLoading: (payload) => {
    dispatch(setFreezeLoading(payload));
  },
  setFreezeUploaded: (payload) => {
    dispatch(setFreezeUploaded(payload));
  },
  viewAllUsers: (token, organisation_id, keyword, metric_id) => {
    dispatch(viewAllUsers(token, organisation_id, keyword, metric_id));
  },
  // metricResult: (token, assessment_id, metric_id) => {
  // 	dispatch(metricResult(token, assessment_id, metric_id));
  // },
  updateMetricResult: (
    token,
    assessment_id,
    metric_id,
    index_no,
    parent_metric_id,
    main_topic_id,
    review_data
  ) => {
    dispatch(
      updateMetricResult(
        token,
        assessment_id,
        metric_id,
        index_no,
        parent_metric_id,
        main_topic_id,
        review_data
      )
    );
  },
  setBlockchainHashlinks: (payload) => {
    dispatch(setBlockchainHashlinks(payload));
  },
  freezeData: (token, assessment_id) => {
    dispatch(freezeData(token, assessment_id));
  },
  getBlockchainHashlinks: (token, assessment_id) => {
    dispatch(getBlockchainHashlinks(token, assessment_id));
  },
  deleteAssignedMember: (
    token,
    stakeholder_id,
    assessment_id,
    metric_ids,
    step_no
  ) => {
    dispatch(
      deleteAssignedMember(
        token,
        stakeholder_id,
        assessment_id,
        metric_ids,
        step_no
      )
    );
  },
  updateSettings: (
    token,
    metric_id,
    assessment_id,
    is_approval,
    settings_value,
    approvers_list
  ) => {
    dispatch(
      updateSettings(
        token,
        metric_id,
        assessment_id,
        is_approval,
        settings_value,
        approvers_list
      )
    );
  },
  removeFile: (
    token,
    assessment_id,
    step_no,
    url,
    field_name,
    assessment_topic_id,
    activity_id,
    kpi_id,
    metric_id
  ) => {
    dispatch(
      removeFile(
        token,
        assessment_id,
        step_no,
        url,
        field_name,
        assessment_topic_id,
        activity_id,
        kpi_id,
        metric_id
      )
    );
  },
  viewChats: (token, assessment_id, metric_id) => {
    dispatch(viewChats(token, assessment_id, metric_id));
  },
  setMetricChats: (payload) => {
    dispatch(setMetricChats([]));
  },
  getSettingsData: (token, assessment_id, metric_id) => {
    dispatch(getSettingsData(token, assessment_id, metric_id));
  },
  getDisclosureSettingsFiles: (token, metric_id, assessment_id) => {
    dispatch(getDisclosureSettingsFiles(token, metric_id, assessment_id));
  },
  getSubmissionDetails: (token, submission_id, user_id, user) => {
    dispatch(getSubmissionDetails(token, submission_id, user_id, user));
  },
  approveSubmission: (
    token,
    metric_id,
    assessment_id,
    user_id,
    organisation_id,
    chat_id,
    selected_index,
    parent_metric_id,
    is_approver
  ) => {
    dispatch(
      approveSubmission(
        token,
        metric_id,
        assessment_id,
        user_id,
        organisation_id,
        chat_id,
        selected_index,
        parent_metric_id,
        is_approver
      )
    );
  },
  changeStatus: (
    token,
    assessment_id,
    metric_id,
    status,
    parent_id,
    review_data
  ) => {
    dispatch(
      changeStatus(
        token,
        assessment_id,
        metric_id,
        status,
        parent_id,
        review_data
      )
    );
  },
  setDisclosureSettingsViewSubmission: (payload) => {
    dispatch(setDisclosureSettingsViewSubmission(payload));
  },
  deleteFile: (token, chat_id, reply_id, assessment_id, metric_id) => {
    dispatch(deleteFile(token, chat_id, reply_id, assessment_id, metric_id));
  },
  assignMembers_step5: (
    user_token,
    approvers,
    contributors,
    metrics_ids,
    assessment_id,
    organisation_id,
    parent_metric_id,
    main_topic_ids,
    template_type
  ) => {
    dispatch(
      assignMembers_step5(
        user_token,
        approvers,
        contributors,
        metrics_ids,
        assessment_id,
        organisation_id,
        parent_metric_id,
        main_topic_ids,
        template_type
      )
    );
  },
  getSustainabilityDocx: (token, assessment_id) => {
    dispatch(getSustainabilityDocx(token, assessment_id));
  },
  getSustainabilityXBRL: (token, assessment_id) => {
    dispatch(getSustainabilityXBRL(token, assessment_id));
  },
  getSustainabilityExcel: (token, assessment_id) => {
    dispatch(getSustainabilityExcel(token, assessment_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldDataCont);

import React from "react";
import { connect } from "react-redux";
import { updateBasicInfo } from "../../actions/proceedButton/proceedButtonAction";
import {
  updateBasicAssessmentDetails,
  updateBasicCompanyDetails
} from "../../actions/basicinfoAction/basicinfoAction";
import ProceedButtonCard from "../../components/UI/proceedButtonCard/proceedButtonCard";
import {
  setBasicInfoRightTab,
  setBasicInfoTab
} from "../../actions/basicinfoAction/basicinfoAction";
import {
  updateMaterialityAssessment,
  setActiveStep,
  updateMaterialityAssessmentTopic,
  finishAssessment
} from "../../actions/sustainabilityReport/sustainabilityReportAction";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { getMaterialityMapData, getSelectedTopic, setTopicTab } from "../../actions/materialityAction/materialityAction";
import { setReportTab } from "../../actions/fielddata/fieldDataAction";
import { setRedirectValue } from "../../actions/surveyListing/surveyListingAction";
import { setIsTutorialCompleted } from "../../actions/login/loginActions";
import { getInternalMaturityMatrix, viewAssignedMatrixUsers } from "../../actions/maturityMatrix/maturityMatrixActions";

class ProceedButtonCont extends React.PureComponent {
  render() {
    return <ProceedButtonCard {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  basicInfo: store.basicInfo,
  materialityAssessment: store.materialityAssessment,
  fieldData: store.fieldData,
  esgStrategy: store.esgStrategy
});

const mapDispatchToProps = (dispatch) => ({
  updateBasicInfo: (assessment_id, basicInfo, token, activeStep, isSave) => {
    dispatch(
      updateBasicInfo(assessment_id, basicInfo, token, activeStep, isSave)
    );
  },
  updateMaterialityAssessment: (
    token,
    assessmentId,
    topics,
    files,
    isSave,
    activeStep
  ) => {
    dispatch(
      updateMaterialityAssessment(
        token,
        assessmentId,
        topics,
        files,
        isSave,
        activeStep
      )
    );
  },
  updateMaterialityAssessmentTopic: (
    token,
    assessment_id,
    metrics,
    topicsArray,
    file,
    weightage,
    organisation_id
  ) => {
    dispatch(
      updateMaterialityAssessmentTopic(
        token,
        assessment_id,
        metrics,
        topicsArray,
        file,
        weightage,
        organisation_id
      )
    );
  },
  setActiveStep: (step) => {
    dispatch(setActiveStep(step));
  },
  set_snack_bar: (status, message) => {
    dispatch(set_snack_bar(status, message));
  },
  finishAssessment: (token, assessment_id) => {
    dispatch(finishAssessment(token, assessment_id));
  },
  setTopicTab: (payload) => {
    dispatch(setTopicTab(payload));
  },
  setReportTab: (payload) => {
    dispatch(setReportTab(payload));
  },
  setRedirectValue: (payload) => {
    dispatch(setRedirectValue(payload));
  },
  setIsTutorialCompleted: (payload) => {
    dispatch(setIsTutorialCompleted(payload));
  },
  updateBasicAssessmentDetails: (token, assessment_id, basicInfo, isSave) => {
    dispatch(
      updateBasicAssessmentDetails(token, assessment_id, basicInfo, isSave)
    );
  },
  setBasicInfoRightTab: (payload) => {
    dispatch(setBasicInfoRightTab(payload));
  },
  setBasicInfoTab: (payload) => {
    dispatch(setBasicInfoTab(payload));
  },
  updateBasicCompanyDetails: (token, assessmentId, basicInfo) => {
    dispatch(updateBasicCompanyDetails(token, assessmentId, basicInfo));
  },
  viewAssignedMatrixUsers: (token,assessment_id,organisation_id) => {
    dispatch(
      viewAssignedMatrixUsers(token,assessment_id,organisation_id)
    );
  },
  getInternalMaturityMatrix: (token,assessment_id) => {
    dispatch(
      getInternalMaturityMatrix(token,assessment_id)
    );
  },
  getSelectedTopic: (token,assessment_id) => {
    dispatch(
      getSelectedTopic(token,assessment_id)
    );
  },
  getMaterialityMapData: (token,assessment_id) => {
    dispatch(
      getMaterialityMapData(token,assessment_id)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProceedButtonCont);

import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import CustomTextfield from "../../UI/textfield/textfield";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CustomButton from "../../UI/button/button";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import blockchain from "../../../images/blockchain.png";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import LinearProgress from "@material-ui/core/LinearProgress";
import Collapse from "@material-ui/core/Collapse";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import topicIcon from "../../../images/topicIcon.svg";
import IconButton from "@material-ui/core/IconButton";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  withStyles,
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { emailValidation } from "../../../actions/regexValidation/regexValidation";
import SingleMetricReport from "./newSingleMetricReport";
import FinalSingleMetricReport from "./finalSingleReportData";
import SingleMetricReportReview from "./singleMetricReportReview";
import SearchIcon from "@material-ui/icons/Search";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MuiTableCell from "@material-ui/core/TableCell";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  Icon,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Slide,
  Switch,
  Tab,
  Tabs,
  Menu,
} from "@material-ui/core";
import UpdateDialogueContent from "./updateDialogueContent";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import {
  assignDisclosure,
  sortList,
  userCategoryList,
} from "../../../utils/reportData/reportDataRightUtils";
import Guidelines from "../reportListing/Guidelines";
import NearMeOutlinedIcon from "@material-ui/icons/NearMeOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import StatusMenu from "./statusMenu";
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    height: "100%",
  },
  metricDetailsHide: {
    display: "none",
    backgroundColor: "blue",
    transition: "transform 1s",
  },
  metricDetailsShow: {
    display: "block",
    backgroundColor: "green",
    transform: "translateY(100%)",
  },
  mainDiv: {
    padding: "5px 20px",
    minWidth: 1000,
    maxWidth: 1050,
    overflowY: "hidden",
  },
  stakeholderDiv: {
    padding: 25,
    minWidth: 600,
    maxWidth: 700,
    overflowY: "hidden",
  },
  AppBar: { boxShadow: "none" },
  tab: {
    fontSize: 12,
    minWidth: "85px",
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "poppins",
  },
  homeHeadingContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "660px",
    marginTop: "10px",
    marginRight: "8px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "600",
  },
  blockChainPaper: {
    padding: "16px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "172px",
    textTransform: "capitalize",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "59px",
    paddingTop: "100px",
  },
  assignMetricContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "5px 5px",
    "&:hover": {
      "& $button": {
        visibility: "visible",
      },
      backgroundColor: "#EBF1F8",
    },
  },
  inputText: {
    width: "250px",
    borderRadius: "8px",
    "& .MuiTextField-root": {
      height: 40,
    },
  },
  newAssignMetricContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: ".5px solid #AFAFAF",
    borderRadius: "8px",
    margin: "10px 0px",
    padding: "5px 5px",
    cursor: "pointer",
    "&:hover": {
      "& $button": {
        visibility: "visible",
      },
      backgroundColor: "#EBF1F8",
    },
  },
  subText: {
    fontSize: 13,
    color: "#707070",
  },
  subTextMain: {
    fontSize: 14,
    color: "#707070",
    textAlign: "left",
    marginLeft: "auto",
    marginRight: "auto",
  },
  assignMetricText: {
    fontSize: 13,

    fontWeight: "600",
  },
  metricDivider: {
    width: 2,
    height: 22,
    color: "#9FA1AF",
    // backgroundColor: '#9FA1AF',
    borderRadius: 1,
    margin: "0px 10px",
  },
  input: {
    margin: "10px 0px",
    padding: 5,
    flex: 1,
    border: "1px solid #F1F3F4",
    "& .MuiChip-root": {
      color: "#77787B",
      backgroundColor: "#FFFFFF",
      borderRadius: "5%",
      border: "2px solid #e5e5e5",
    },
  },
  placeholder: {
    "&::placeholder": {
      color: "#3374B9",
    },
  },

  user: {
    "&:hover": {
      borderRadius: "50px 0px 0px 50px",
      backgroundColor: " #d8e7f7",
    },
  },

  reportCardPaper: {
    paddingTop: "24px",
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "8px 8px 10px 8px",
    paddingBottom: "16px",
  },
  flexJustifyContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  reportCardTitle: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "600",
  },
  reportCardTime: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
  },
  avatarSmall: {
    width: 32,
    height: 32,
  },
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  grayText: {
    fontSize: 12,
    color: "#9FA1AF",
  },
  statusText: { fontSize: "12px" },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#9FA1AF",
    marginTop: 15,
  },
  box: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  typography: {
    fontWeight: "600",
  },
  typography1: {
    fontSize: 12,
    padding: "15px 0px",
  },
  box1: {
    backgroundColor: "#DDE9F6",
    borderRadius: 5,
    margin: "10px 0px",
    padding: 15,
  },
  box2: {
    display: "flex",
    justifyContent: "flex-end",
  },
  root: {
    flexGrow: 1,
  },
  scrollContainer: {
    height: 400,
    overflow: "auto",
    marginBottom: theme.spacing(3),
  },
  scroll: {
    position: "relative",
    width: "230%",
    backgroundColor: theme.palette.background.paper,
    height: "230%",
  },
  legend: {
    marginTop: theme.spacing(2),
    maxWidth: 300,
  },
  paper: {
    width: "560px",
    overflow: "auto",
    borderRadius: "5px",
    height: "250px",
    // marginLeft: "-80px",
    // marginBottom:"-20px",
    // // backgroundColor:"red"
    // // border:"1px solid red"/
  },
  CardLoader: { marginTop: 80, color: "#3374b9" },
  CardContentLoader: { display: "flex", justifyContent: "center" },
  loader: {
    marginTop: 80,
    color: "#3374b9",
  },
  select: {
    width: 200,
  },
  userPaper: {
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    // border: '1px solid #3374B9',
  },
  selectedUserPaper: {
    borderRadius: "5px",
    backgroundColor: "#EBF1F8",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    border: "1px solid #3374B9",
  },
  statusCell: {
    padding: "7px 2px",
  },
  omissionButton: {
    textTransform: "none",
    // float: 'right',
    // marginRight: '10px',
    // color: '#3374B9',
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  popper: {
    zIndex: 1500,
    // '&[x-placement*="bottom"] $arrow': {
    //   top: 0,
    //   left: 0,
    //   marginTop: "-0.9em",
    //   width: "3em",
    //   height: "1em",
    //   "&::before": {
    //     borderWidth: "0 1em 1em 1em",
    //     borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    //   },
    // },
    // '&[x-placement*="top"] $arrow': {
    //   bottom: 0,
    //   left: 0,
    //   marginBottom: "-0.9em",
    //   width: "3em",
    //   height: "1em",
    //   "&::before": {
    //     borderWidth: "1em 1em 0 1em",
    //     borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    //   },
    // },
    // '&[x-placement*="right-start"] $arrow': {
    //   left: 0,
    //   marginLeft: "-0.9em",
    //   height: "3em",
    //   width: "1em",
    //   marginTop: "10px",
    //   // marginLeft: "-100px",

    //   "&::before": {
    //     borderWidth: "1em 1em 1em 0",
    //     // borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    //     borderColor: `transparent #F7F6F6 transparent transparent`,
    //     // borderColor: `transparent red transparent transparent`,

    //     boxShadow:
    //       "box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",

    //     fontSize: 20,
    //   },
    // },
    // '&[x-placement*="left"] $arrow': {
    //   right: 0,
    //   marginRight: "-0.9em",
    //   height: "3em",
    //   width: "1em",
    //   "&::before": {
    //     borderWidth: "1em 0 1em 1em",
    //     borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    //   },
    // },
  },
  // arrow: {
  //   position: "absolute",
  //   fontSize: 7,
  //   width: "3em",
  //   height: "3em",
  //   // marginLeft: "-90px",

  //   "&::before": {
  //     content: '""',
  //     margin: "auto",
  //     display: "block",
  //     width: 0,
  //     height: 0,
  //     borderStyle: "solid",
  //   },
  // },
  button: {
    borderRadius: "50px",
    marginLeft: 10,
    fontSize: 10,
    visibility: "hidden",
    marginBottom: 10,
    // fontWeight: 100,
  },
  buttonVisible: {
    borderRadius: "50px",
    marginLeft: 10,
    fontSize: 10,
    visibility: "visible",
    marginBottom: 10,
    // fontWeight: 100,
  },
  arrows: {
    position: "absolute",
    width: "68px",
    height: "68px",
    left: "-37px",
    top: "72px",
    background: "#FFFFFF",
    borderRadius: "6px",
    transform: "rotate(45deg)",
  },
  floatingButton: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    bottom: "0px",
    left: "135px",
    border: "none",
    borderRadius: "8px",
    width: "450px",
    backdropFilter: "blur(5px)",
    animation: `$fadeEffect 400ms ${theme.transitions.easing.easeIn}`,
  },
  "@keyframes fadeEffect": {
    "0%": {
      opacity: 0,
      transform: "translateY(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0%)",
    },
  },
  mainGrid: { borderBottom: "1px solid #DADBE6" },
  Typography2: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  div1: { width: 300 },
  Typography3: { fontSize: "small" },
  CustomButton: { padding: "2px 10px", textTransform: "none" },
  clearButton: {
    width: "120px",
    textAlign: "center",
    textTransform: "none",
    fontSize: "small",
    fontFamily: "poppins",
    margin: "5px 0px 5px 1px",
    padding: "5px",
  },
  completeButton: {
    width: "150px",
    textAlign: "center",
    textTransform: "none",
    fontSize: "small",
    fontFamily: "poppins",
    margin: "5px 0px 5px 1px",
    backgroundColor: "#3374B9",
    padding: "5px",
    color: "white",
    "&:hover": {
      backgroundColor: "#3374B9",
      color: "white",
    },
  },
  input1: { display: "none" },
  pointer: { cursor: "pointer" },
  // BoxG: { borderBottom: '1px solid gray' },
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
    // [theme.breakpoints.down('lg')]: {
    //   width: '83%',
    //   marginTop: '8px'
    // }
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
});
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box>{children}</Box>}</div>;
}
const buttonTheme = createTheme({
  palette: {
    primary: { main: "#ffffff" },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#3374B9",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "#3374B9",
        },
      },
    },
  },
});

function TopicIcon() {
  return <img src={topicIcon} alt="topic" />;
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 40,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: "#FFFFFF",
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#DDE9F6",
  },
}))(LinearProgress);

const EncryptionProgress = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.reportCardPaper} elevation={2}>
      <Box
        sx={{ paddingBottom: 24, paddingLeft: 8, paddingRight: 8 }}
        className={classes.flexJustifyContent}
      >
        <Typography className={classes.reportCardTitle}>
          Encryption In Progress
        </Typography>
        <Typography className={classes.reportCardTime}>ETA : 2hrs</Typography>
      </Box>
      <Box paddingBottom="64px">
        <BorderLinearProgress variant="indeterminate" value={10} />
      </Box>
    </Paper>
  );
};

const ReportDataRight = (props) => {
  const {
    assessment_id,
    login,
    fieldData,
    viewAssignMembers,
    updateOmission,
    assignMembers,
    viewAssignedMembers,
    metricResult,
    updateMetricResult,
    generalSettings,
    viewAllUsers,
    viewAllUsers_step5,
    getBlockchainHashlinks,
    removeFile,
    getdetailedMetrics,
    deleteAssignedMember,
    setFilteredMetrics,
    getCollectMetrics,
    assignMembers_step5,
  } = props;
  const classes = useStyles();

  // assign dialog test
  const anchorRef = React.useRef(null);

  const [arrowRef, setArrowRef] = React.useState(null);

  const [arrow, setArrow] = React.useState(false);
  const [disablePortal, setDisablePortal] = React.useState(false);
  const [flip, setFlip] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState("right-start");
  const [preventOverflow, setPreventOverflow] = React.useState("scrollParent");

  // end here
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [isOmissionDialog, setIsOmissionDialog] = useState(false);
  const [isRemoveOmissionDialog, setIsRemoveOmissionDialog] = useState(false);
  const [isSelectSubMetricsOpen, setIsSelectSubMetricsOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userEmails, setUserEmails] = useState([]);
  const [expand, setExpand] = useState("");
  const [toggle, setToggle] = useState(false);
  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");
  const [currentOmitMetrics, setCurrentOmitMetrics] = useState("");
  const [omissionReason, setOmissionReason] = useState("");
  const [omissionComment, setOmissionComment] = useState("");
  const [assignMetric, setAssignMetric] = useState([]);
  const [assignMetricId, setAssignMetricId] = useState([]);
  const [allAssignMetricIds, setAllAssignMetricIds] = useState([]);
  const [finalData, setFinalData] = useState({
    open: false,
    index: null,
    data: null,
  });

  const [dataType, setDataType] = useState("");
  const [metricTitle, setMetricTitle] = useState("");
  const [metricDesc, setMetricDesc] = useState("");

  const [showReasons, setShowReasons] = useState(false);
  const [selectedSubmetricId, setSelectedSubmetricId] = useState("");
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [selectedMetricId, setSelectedMetricId] = useState("");
  const [sendGrandParentId, setSendGrandParentId] = useState(false);
  const [selectedGrandParentId, setSelectedGrandParentId] = useState("");
  const [guidelines, setGuidelines] = useState([]);
  const [emails, setEmails] = useState([]);
  const [approverEmails, setApproveremails] = useState([]);

  const [inputValue, setInputValue] = React.useState("");
  const [keyword, setKeyword] = useState("");

  const [multiselectValue, setMultiselectValue] = useState("");

  const option1 = generalSettings.allUsers.filter(
    (user) => typeof user.name === "string" && user.name.includes(inputValue)
  );
  const option2 = generalSettings.allUsers.filter(
    (user) => user
    // user.email.includes(inputValue)
  );

  const [sort, setSort] = useState("Show all");
  const [disclosureSort, setDisclosureSort] = useState("View All Disclosures");

  const [usersType, setUsersType] = useState("All");
  const [usersCategory, setUsersCategory] = useState("Show all");
  const [stakeholderType, setStakeholderType] = useState("All");

  const [category, setCategory] = useState("All Category");
  const [sortedUsers, setSortedUsers] = useState([]);
  const [assignMetricSort, setAssignMetricSort] = useState([]);

  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    fieldData?.currentMetric?._id &&
      viewAssignedMembers(
        login.token,
        assessment_id,
        5,
        fieldData.currentMetric._id
      );
    // to close the disclosure points page at the right card
    setFinalData({ open: false, index: null, data: null });
    setSort("Show all");
  }, [fieldData.currentMetric]);

  useEffect(() => {
    // viewAllUsers(
    //   login.token,
    //   login.current_organisation,
    //   keyword,
    //   fieldData.currentMetric._id
    // );
    fieldData?.currentMetric?._id &&
      viewAllUsers_step5(
        login.token,
        assessment_id,
        fieldData.currentMetric._id,
        login.current_organisation
      );
    // getStakeholder(login.token, assessment_id, 4);
  }, [login.current_organisation, isAssignDialogOpen]);

  // useEffect(() => {
  //   fieldData.metrics.metric_data &&
  //     fieldData.metrics.metric_data.map((metric) => {
  //       if (metric.guidelines) {
  //         setGuidelines((prevState) => [...prevState, metric.guidelines]);
  //       }
  //       if (!metric.guidelines && metric.sub_metrics_data.length !== 0) {
  //         metric.sub_metrics_data.map((sm) => {
  //           if (sm.guidelines) {
  //             setGuidelines((prevState) => [...prevState, sm.guidelines]);
  //           }
  //         });
  //       }
  //     });

  //   return () => {
  //     setGuidelines([]);
  //   };
  // }, [fieldData.metrics.metric_data]);

  const handleAssignDialogCancel = () => {
    setIsAssignDialogOpen(false);
    setAllAssignMetricIds([]);
    setAssignMetricId([]);
  };

  const handleAssignDialog = () => {
    if (metricTabNumber === 1) {
      if (
        emails.map((user) => user.email).length &&
        approverEmails.map((user) => user.email).length
      ) {
        assignMembers_step5(
          login.token,
          approverEmails.map((user) => user.email),
          emails.map((user) => user.email),
          assignMetricId,
          assessment_id,
          login.current_organisation,
          fieldData.currentMetric._id,
          fieldData.currentMetric.main_topic_ids,
          props.basicInfo.templateType
        );
        setIsAssignDialogOpen(false);
        setUserEmail("");
        setUserEmails([]);
        setAssignMetricId([]);
        setAllAssignMetricIds([]);
        setEmails([]);
        setApproveremails([]);
        setMetricTabNumber(0);
        setIsApproverAccordion(false);
        setExpand(false);
        // getCollectMetrics(
        // 	login.token,
        // 	assessment_id,
        // 	fieldData.currentMetric._id,
        // 	fieldData.currentMetric.main_topic_ids,
        // 	props.basicInfo.templateType,
        // 	localStorage.getItem("current_organisation")
        // );
      } else if (
        emails.map((user) => user.email).length &&
        !approverEmails.map((user) => user.email).length
      ) {
        assignMembers_step5(
          login.token,
          approverEmails.map((user) => user.email),
          emails.map((user) => user.email),
          assignMetricId,
          assessment_id,
          login.current_organisation,
          fieldData.currentMetric._id,
          fieldData.currentMetric.main_topic_ids,
          props.basicInfo.templateType
        );
        setIsAssignDialogOpen(false);
        setUserEmail("");
        setUserEmails([]);
        setAssignMetricId([]);
        setAllAssignMetricIds([]);
        setEmails([]);
        setApproveremails([]);
        setMetricTabNumber(0);
        setIsApproverAccordion(false);
        setExpand(false);
      } else if (
        approverEmails.map((user) => user.email).length &&
        !emails.map((user) => user.email).length
      ) {
        assignMembers_step5(
          login.token,
          approverEmails.map((user) => user.email),
          emails.map((user) => user.email),
          assignMetricId,
          assessment_id,
          login.current_organisation,
          fieldData.currentMetric._id,
          fieldData.currentMetric.main_topic_ids,
          props.basicInfo.templateType
        );
        setIsAssignDialogOpen(false);
        setUserEmail("");
        setUserEmails([]);
        setAssignMetricId([]);
        setAllAssignMetricIds([]);
        setEmails([]);
        setApproveremails([]);
        setMetricTabNumber(0);
        setIsApproverAccordion(false);
        setExpand(false);
      }
      // setTimeout(() => {
      //   if (approverEmails.map((user) => user.email).length) {
      //     // assignMembers(
      //     //   login.token,
      //     //   approverEmails.map((user) => user.email),
      //     //   5,
      //     //   assessment_id,
      //     //   login.current_organisation,
      //     //   assignMetricId,
      //     //   fieldData.currentMetric._id,
      //     //   fieldData.currentMetric.main_topic_ids,
      //     //   1
      //     // );
      //     setIsAssignDialogOpen(false);
      //     setUserEmail("");
      //     setUserEmails([]);
      //     setAssignMetricId([]);
      //     setAllAssignMetricIds([]);
      //     setEmails([]);
      //     setApproveremails([]);
      //     setMetricTabNumber(0);
      //     setIsApproverAccordion(false);
      //     setExpand(false);
      //   }
      //   // getCollectMetrics(
      //   //   login.token,
      //   //   assessment_id,
      //   //   fieldData.currentMetric._id,
      //   //   fieldData.currentMetric.main_topic_ids,
      //   //   props.basicInfo.templateType,
      //   //   localStorage.getItem("current_organisation")
      //   // );
      // }, [1500]);
    }
  };

  const handleOmissionDialog = () => {
    setIsOmissionDialog(false);
    setOmissionComment("");
    setOmissionReason("");
    setOmissionFile(null);
  };

  const handleRemoveOmissionDialog = () => {
    setIsRemoveOmissionDialog(false);
    setOmissionComment("");
    setOmissionReason("");
    setOmissionFile(null);
  };

  const handleSelectSubMetricDialog = () => {
    setIsSelectSubMetricsOpen(false);
  };
  // assignMetric.map(({ _id }) => _id);
  // const assignIds =
  //   fieldData.metrics.metric_data
  //     ? fieldData.metrics.metric_data.map(({_id, sub_metrics}) => sub_metrics.length > 0 ? sub_metrics.map(({_id}) => _id) : _id ) : []

  const sortedApproverList = (toMap) => {
    return toMap.map((user, index) => {
      return (
        <Paper
          key={index}
          onClick={() => {
            handleApproverEmails(user);
          }}
          className={
            approverEmails.map((user) => user.email).includes(user.email)
              ? classes.selectedUserPaper
              : classes.userPaper
          }
          style={{
            margin: "12px 3px",
            borderRadius: "8px",
          }}
          variant="outlined"
        >
          <Grid
            style={{ margin: "10px", padding: "5px" }}
            container
            alignItems="center"
            item
            md={12}
          >
            <Grid item md={1}>
              {approverEmails
                .map((user) => user.email)
                .includes(user.email) && <DoneIcon />}
            </Grid>
            <Grid item md={1}>
              <Avatar
                src={
                  user.profile_img && user.profile_img.url
                    ? user.profile_img.url
                    : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                }
              />
            </Grid>
            <Grid item md={7}>
              <Grid>{user.name}</Grid>
              <Grid>{user.email}</Grid>
            </Grid>
            <Grid item md={2}>
              Employee
            </Grid>
          </Grid>
        </Paper>
      );
    });
  };
  const [contributorSelectedEmails, setContributorSelectedEmails] = useState(
    []
  );
  const sortedContributorList = (toMap) =>
    toMap.map((user, i) => {
      return (
        <Grid key={i} style={{}}>
          <Grid
            style={{
              position: "relative",
              margin: i === 0 ? "10px 0px 5px 0px" : "5px 0px",
              padding: "5px",
            }}
            className={
              contributorSelectedEmails
                .map((user) => user.email)
                .includes(user.email)
                ? classes.selectedUserPaper
                : classes.userPaper
            }
            container
            alignItems="center"
            item
            md={12}
            sm={12}
            xs={12}
            key={user.name}
          >
            <Grid item md={1}>
              {contributorSelectedEmails
                .map((user) => user.email)
                .includes(user.email) && <DoneIcon />}
            </Grid>
            <Grid item md={1} sm={1} xs={1}>
              <Avatar
                src={
                  user.profile_img && user.profile_img.url
                    ? user.profile_img.url
                    : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                }
              />
            </Grid>
            <Grid
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleAssignedEmails(user);
                // contributorSelectedEmails.map((user) => user.email).includes(user.email) ? contributorSelectedEmails.push(user)
              }}
              item
              md={7}
              sm={7}
              xs={7}
            >
              <Grid>
                <Typography>{user.name}</Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontSize: "13px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user.designation}
                </Typography>
              </Grid>
            </Grid>
            {/* hhhhhhhhhhhhh */}
            <Grid style={{ display: "flex" }} item md={2} sm={2} xs={2}>
              <Grid
                onClick={() => {
                  `panel${i}` === expand && setToggle(!toggle);
                  setExpand(`panel${i}`);
                  setIsApproverAccordion(true);
                }}
                style={
                  `panel${i}` === expand && toggle && isApproverAccordion
                    ? {
                        display: "flex",
                        border: "none",
                        backgroundColor: "#303f9f",
                        borderRadius: "10px",
                        marginRight: "6px",
                        alignItems: "center",
                        width: "65px",
                        padding: "0px 5px",
                        cursor: "pointer",
                      }
                    : {
                        display: "flex",
                        border: "none",
                        backgroundColor: "#E9E9E9",
                        borderRadius: "10px",
                        marginRight: "6px",
                        alignItems: "center",
                        width: "65px",
                        padding: "0px 5px",
                        cursor: "pointer",
                      }
                }
              >
                <div
                  style={
                    `panel${i}` === expand && toggle
                      ? {
                          backgroundColor: "#E9E9E9",
                          border: "none",
                          padding: "4px",
                          borderRadius: "50%",
                          marginRight: "2px",
                        }
                      : {
                          backgroundColor: "#303f9f",
                          border: "none",
                          padding: "4px",
                          borderRadius: "50%",
                          marginRight: "2px",
                        }
                  }
                />
                <Typography
                  style={
                    `panel${i}` === expand && toggle
                      ? {
                          padding: "2px 5px",
                          color: "white",
                          fontSize: 13,
                        }
                      : { fontSize: 13, padding: "2px 5px" }
                  }
                >
                  {user.approvers && user.approvers.length}
                </Typography>
                {`panel${i}` === expand && toggle ? (
                  <KeyboardArrowUpIcon
                    style={
                      `panel${i}` === expand && toggle
                        ? { color: "white", fontSize: 18 }
                        : { color: "inherit", fontSize: 18 }
                    }
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={
                      `panel${i}` === expand && toggle
                        ? { color: "white", fontSize: 18 }
                        : { color: "inherit", fontSize: 18 }
                    }
                  />
                )}
              </Grid>
              <Grid
                style={
                  `panel${i}` === expand && toggle && !isApproverAccordion
                    ? {
                        display: "flex",
                        border: "none",
                        backgroundColor: "#32A05F",
                        borderRadius: "10px",
                        marginRight: "6px",
                        alignItems: "center",
                        width: "65px",
                        padding: "0px 5px",
                        cursor: "pointer",
                      }
                    : {
                        display: "flex",
                        border: "none",
                        backgroundColor: "#E9E9E9",
                        borderRadius: "10px",
                        marginRight: "6px",
                        alignItems: "center",
                        width: "65px",
                        padding: "0px 5px",
                        cursor: "pointer",
                      }
                }
                onClick={() => {
                  `panel${i}` === expand && setToggle(!toggle);
                  setExpand(`panel${i}`);
                  setIsApproverAccordion(false);
                }}
              >
                <div
                  style={
                    `panel${i}` === expand && toggle
                      ? {
                          backgroundColor: "white",
                          border: "none",
                          padding: "4px",
                          borderRadius: "50%",
                          marginRight: "2px",
                        }
                      : {
                          backgroundColor: "#32A05F",
                          border: "none",
                          padding: "4px",
                          borderRadius: "50%",
                          marginRight: "2px",
                        }
                  }
                />
                <Typography
                  style={
                    `panel${i}` === expand && toggle
                      ? {
                          padding: "2px 5px",
                          color: "white",
                          fontSize: 13,
                        }
                      : { padding: "2px 5px", fontSize: 13 }
                  }
                >
                  {Array.isArray(user.contributors)
                    ? user.contributors.length
                    : "0"}
                </Typography>
                {`panel${i}` === expand && toggle ? (
                  <KeyboardArrowUpIcon
                    style={
                      `panel${i}` === expand && toggle
                        ? { color: "white", fontSize: 18 }
                        : { color: "inherit", fontSize: 18 }
                    }
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={
                      `panel${i}` === expand && toggle
                        ? { color: "white", fontSize: 18 }
                        : { color: "inherit", fontSize: 18 }
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* {expand === `panel${i}` && ( */}
          <Grid
            style={
              expand === `panel${i}` && toggle
                ? {
                    position: "relative",
                    height: 140,
                    overflow: "hidden",
                    transition: "0.5s",
                    overflowY: "auto",
                  }
                : {
                    position: "relative",
                    height: 0,
                    overflow: "hidden",
                    transition: "0.5s",
                    overflowY: "auto",
                  }
            }
          >
            <Grid style={{ padding: "5px 15px" }}>
              {isApproverAccordion &&
                user.approvers &&
                user.approvers.map((metric, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "42px",
                          height: "30px",
                          padding: "5px",
                          border: "none",
                          backgroundColor: "#EBF1F8",
                          color: "#3374B9",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #3374B9",
                            backgroundColor: "#3374B9",
                            margin: "2px",
                            borderRadius: "5px",
                            width: "3px",
                            height: "18px",
                            fontWeight: "bold",
                          }}
                        ></div>
                        <div>
                          <Typography
                            style={{
                              padding: "2px 5px 2px 2px",
                            }}
                          >
                            {i + 1}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Typography>
                          {metric.metric_desc + metric.metric_name}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              {!isApproverAccordion &&
                user.contributors &&
                user.contributors.map((metric, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "42px",
                          height: "30px",
                          padding: "5px",
                          border: "none",
                          backgroundColor: "#EBF1F8",
                          color: "#3374B9",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #3374B9",
                            backgroundColor: "#3374B9",
                            margin: "2px",
                            borderRadius: "5px",
                            width: "3px",
                            height: "18px",
                            fontWeight: "bold",
                          }}
                        ></div>
                        <div>
                          <Typography
                            style={{
                              padding: "2px 5px 2px 2px",
                            }}
                          >
                            {i + 1}
                          </Typography>
                        </div>
                      </div>
                      <div style={{ alignItems: "center" }}>
                        <Typography>
                          {metric.metric_desc + metric.metric_name}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      );
    });

  let assignIds = [];
  if (
    fieldData.metrics.metric_data &&
    Array.isArray(fieldData.metrics.metric_data)
  ) {
    fieldData.metrics.metric_data.map((s) => {
      if (s.sub_metrics_data && s.sub_metrics_data.length > 0) {
        s.sub_metrics_data.map((s2) => {
          assignIds.push(s2._id);
        });
      } else assignIds.push(s._id);
    });
  }

  const handleOpenAssignMetric = (assignMetric) => {
    setIsAssignDialogOpen(true);
    setAssignMetric(assignMetric);

    assignMetric.map((metric) => {
      metric.sub_metrics_data.length === 0 &&
        setAllAssignMetricIds((prevState) => [...prevState, metric._id]);
    });
    assignMetric.map((metric) => {
      metric.sub_metrics_data.length !== 0 &&
        metric.sub_metrics_data.map((sm) =>
          setAllAssignMetricIds((prevState) => [...prevState, sm._id])
        );
    });
    viewAssignMembers(login.token, assessment_id, assignIds);
  };

  //handling "select all" checkbox and checkboxes
  const isSelectAllChecked =
    allAssignMetricIds.length > 0 &&
    assignMetricId.length === allAssignMetricIds.length;

  const handleAssignMetricCheckBox = (e, id) => {
    let value = id;
    if (e.target.innerText?.toLowerCase() === "select all") {
      setAssignMetricId(allAssignMetricIds);
      return;
    } else if (e.target.innerText?.toLowerCase() === "clear all") {
      setAssignMetricId([]);
      return;
    }
    const list = [...assignMetricId];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setAssignMetricId(list);
  };

  useEffect(() => {
    getBlockchainHashlinks(login.token, assessment_id);
  }, []);

  const ReportCard = ({ hashLink, userName, date, profileUrl, index }) => {
    const classes = useStyles();
    return (
      <Paper className={classes.reportCardPaper} elevation={2}>
        <Box
          sx={{ paddingBottom: 24, paddingLeft: 8, paddingRight: 8 }}
          className={classes.flexJustifyContent}
        >
          <Typography className={classes.reportCardTitle}>
            Report Data {index + 1}
          </Typography>
          <Typography className={classes.reportCardTime}>
            {formatDate(date)}
          </Typography>
        </Box>
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <BorderLinearProgress variant="determinate" value={100} />
          <a
            href={`https://mumbai.polygonscan.com/tx/${hashLink}`}
            rel="noreferrer"
            target="_blank"
          >
            <Typography
              style={{
                position: "absolute",
                color: "#3374B9",
                top: 0,
                fontSize: "16px",
                lineHeight: "24px",
                padding: "8px 24px",
              }}
            >
              {hashLink}
            </Typography>
          </a>
        </Box>
        <Box className={classes.flexJustifyContent} sx={{ paddingTop: 16 }}>
          <Box
            display="flex"
            alignItems="center"
            paddingTop="8px"
            paddingBottom="8px"
          >
            {!login.userProfile ? (
              <Avatar className={classes.avatarSmall}>
                {userName.charAt(0)}
              </Avatar>
            ) : (
              <Avatar className={classes.avatarSmall} src={profileUrl}></Avatar>
            )}
            <Typography
              className={classes.reportCardTime}
              style={{ paddingRight: "16px", paddingLeft: "16px" }}
            >
              Created By
            </Typography>
            <Typography
              style={{ color: "#3374B9" }}
              className={classes.reportCardTime}
            >
              {userName}
            </Typography>
          </Box>
          <Box>
            <CustomButton
              color="primary"
              variant="outlined"
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://mumbai.polygonscan.com/tx/${hashLink}`
                )
              }
            >
              Copy Link
            </CustomButton>
          </Box>
        </Box>
      </Paper>
    );
  };

  // const [referenceElement, setReferenceElement] = useState(null);
  // const [popperElement, setPopperElement] = useState(null);
  // const [arrowElement, setArrowElement] = useState(null);

  // const { styles, attributes } = usePopper(referenceElement, popperElement, {
  //   modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  // });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [metricUserId, setMetricUserId] = React.useState("");

  const handleClickButton = (event, metricUserId) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
    setMetricUserId(metricUserId);
  };

  const centerScroll = (element) => {
    if (!element) {
      return;
    }

    const container = element.parentElement;
    container.scrollTop = element.clientHeight / 4;
    container.scrollLeft = element.clientWidth / 4;
  };

  const id = open ? "scroll-playground" : null;
  const [metricTabNumber, setMetricTabNumber] = useState(0);

  const handleAssignedEmails = (user) => {
    const emailList = [...contributorSelectedEmails];

    const index = emailList.map((user) => user.email).indexOf(user.email);

    index === -1 ? emailList.push(user) : emailList.splice(index, 1);
    setContributorSelectedEmails(emailList);
    // setEmails(emailList);
  };

  const handleApproverEmails = (user) => {
    const emailList = [...approverEmails];
    const index = emailList.map((user) => user.email).indexOf(user.email);
    index === -1 ? emailList.push(user) : emailList.splice(index, 1);
    setApproveremails(emailList);
  };
  const [closeAssignDrawer, setCloseAssignDrawer] = useState(false);
  const handleClearAssignDialog = () => {
    setCloseAssignDrawer(false);
    setIsAssignDialogOpen(false);
    setUserEmail("");
    setUserEmails([]);
    setAssignMetricId([]);
    setAllAssignMetricIds([]);
    setEmails([]);
    setApproveremails([]);
    setMetricTabNumber(0);
    setIsApproverAccordion(null);
    setExpand(false);
  };
  const TableCell = withStyles({
    root: {
      borderBottom: "none",
      padding: 7,
    },
  })(MuiTableCell);

  const closeDialog = () => {
    return (
      <>
        <Dialog
          open={closeAssignDrawer}
          maxWidth="xs"
          style={{ position: "absolute", bottom: "57%", left: "67%" }}
          onClose={() => setCloseAssignDrawer(false)}
        >
          <DialogTitle>
            <Grid
              container
              justifyContent="center"
              className={classes.mainGrid}
            >
              <Grid item xs={8}>
                <Typography className={classes.Typography2}>
                  Close Assign Metric
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid>
              <div className={classes.div1}>
                {metricTabNumber === 0 && (
                  <Typography className={classes.Typography3}>
                    The selection of disclosures will not be available and have
                    to be done once again if you confirm.
                  </Typography>
                )}
                {metricTabNumber === 1 && (
                  <Typography className={classes.Typography3}>
                    The selection of contributors to disclosures will not be
                    available and have to be done once again if you confirm.
                  </Typography>
                )}
                {metricTabNumber === 2 && (
                  <Typography className={classes.Typography3}>
                    The selection of contributors to disclosures will be
                    available for assigning approvers.
                  </Typography>
                )}
              </div>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <CustomButton
              variant="outlined"
              color="primary"
              className={classes.CustomButton}
              onClick={() => {
                setCloseAssignDrawer(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              className={classes.CustomButton}
              onClick={() => {
                // handleAssignDialog();
                handleClearAssignDialog();
              }}
            >
              Confirm
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  const [metricFiltered, setMetricFiltered] = useState([]);
  const [reviewMetricFiltered, setReviewMetricFiltered] = useState([]);
  const [searchText, setSearchText] = useState("");

  const metriclist = (toMap) => {
    return toMap.map((metric, index) => (
      <React.Fragment key={index}>
        {metric.sub_metrics_data.length === 0 ? (
          <Grid
            key={index}
            container
            item
            md={12}
            onClick={(e) => {
              handleAssignMetricCheckBox(e, metric._id);
            }}
            className={classes.newAssignMetricContainer}
          >
            <Grid
              style={{
                textAlign: "center",
              }}
              item
              md={1}
            >
              {assignMetricId.includes(metric._id) && (
                <DoneIcon
                  style={{
                    textAlign: "center",
                    visiblity: assignMetricId.includes(metric._id)
                      ? "visible"
                      : "hidden",
                  }}
                />
              )}
            </Grid>
            {/* <CustomCheckBox
              name={metric.metric_name.substring(0, 4)}
              value={metric._id}
              checked={assignMetricId.includes(metric._id)}
              onChange={(e) =>
                handleAssignMetricCheckBox(e)
              }
             /> */}
            <Grid item md={10}>
              <Typography className={classes.assignMetricText}>
                {metric.metric_name}
              </Typography>

              <Typography className={classes.subText}>
                {metric.metric_desc}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <div className={classes.metricDivider}> </div>
              <div>
                <CustomButton
                  name="showSharedList"
                  onClick={(e) => handleClickButton(e, metric._id)}
                  aria-describedby={id}
                  ref={anchorRef}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={
                    assignMetricId.includes(metric._id)
                      ? classes.buttonVisible
                      : classes.button
                  }
                  startIcon={
                    <GroupOutlinedIcon
                      style={{
                        fontSize: "25px",
                        marginRight: "-7px",
                        visiblity: assignMetricId.includes(metric._id)
                          ? "visible"
                          : "hidden",
                      }}
                    />
                  }
                >
                  {fieldData.assignedMetricUsers.length > 0
                    ? fieldData.assignedMetricUsers.some(
                        ({ metric_id }) => metric_id === metric._id
                      )
                      ? fieldData.assignedMetricUsers.map(
                          (metricData) =>
                            metricData.metric_id === metric._id &&
                            metricData.assigned_users.length
                        )
                      : 0
                    : 0}
                </CustomButton>
              </div>
            </Grid>
            {open && assignPopper()}
          </Grid>
        ) : (
          <div
            style={{
              border: "1px solid #AFAFAF",
              borderRadius: "8px",
              margin: "10px 0px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 5px",
                marginLeft: 10,
              }}
            >
              <Typography
                style={{
                  fontSize: 13,

                  fontWeight: "600",
                }}
              >
                {metric.metric_name}
              </Typography>
              <div className={classes.metricDivider}></div>
              <Typography className={classes.subText}>
                {metric.metric_desc}
              </Typography>
            </Box>
            {metric.sub_metrics_data.map((sm, index) => (
              <Grid
                key={index}
                item
                md={12}
                container
                className={classes.assignMetricContainer}
                style={{
                  overflow: "hidden",
                  overflowX: "scroll",

                  paddingLeft: 25,
                }}
              >
                <Grid item md={1} style={{ textAlign: "center" }}>
                  {assignMetricId.includes(sm._id) && <DoneIcon />}
                </Grid>
                {/* <CustomCheckBox
                    value={sm._id}
                    checked={assignMetricId.includes(
                      sm._id
                    )}
                    onChange={(e) =>
                      handleAssignMetricCheckBox(e)
                    }
                  /> */}
                <Grid item md={10}>
                  <Typography
                    onClick={(e) => {
                      handleAssignMetricCheckBox(e, sm._id);
                    }}
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                    }}
                  >
                    {sm.metric_name}
                  </Typography>
                  {sm.metric_desc.length !== 0 && (
                    <div className={classes.metricDivider}></div>
                  )}
                  <Typography className={classes.subText}>
                    {sm.metric_desc}
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <CustomButton
                    onClick={(e) => handleClickButton(e, sm._id)}
                    aria-describedby={id}
                    ref={anchorRef}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={
                      assignMetricId.includes(sm._id)
                        ? classes.buttonVisible
                        : classes.button
                    }
                    startIcon={
                      <GroupOutlinedIcon
                        style={{
                          fontSize: "25px",
                          marginRight: "-7px",
                        }}
                      />
                    }
                  >
                    {fieldData.assignedMetricUsers.length > 0
                      ? fieldData.assignedMetricUsers.some(
                          ({ metric_id }) => metric_id === sm._id
                        )
                        ? fieldData.assignedMetricUsers.map(
                            (metricData) =>
                              metricData.metric_id === sm._id &&
                              metricData.assigned_users.length
                          )
                        : 0
                      : 0}
                  </CustomButton>
                </Grid>

                {open && assignPopper()}
              </Grid>
            ))}
          </div>
        )}
      </React.Fragment>
    ));
  };
  const handleAssignMetricSearch = (e) => {
    setSearchText(e.target.value);
    let filtered = assignMetric
      .map((item) => item)
      .filter((metric) => {
        if (metric.sub_metrics_data.length === 0) {
          if (
            metric.metric_name?.toLowerCase().includes(e.target.value.trim())
          ) {
            return metric;
          }
        } else if (metric.sub_metrics_data.length > 0) {
          let subm = metric.sub_metrics_data.filter((sm) => {
            if (sm.metric_name?.toLowerCase().includes(e.target.value.trim())) {
              return sm;
            }
          });
          // console.log('subm',subm)
          if (subm.length > 0) return metric;
        }
      });
    setMetricFiltered(filtered);
  };

  const handleReviewMetricSearch = (e) => {
    setSearchText(e.target.value);
    let filtered = fieldData.reviewMetric
      .map((item) => item)
      .filter((metric) => {
        if (metric.sub_metrics.length === 0) {
          if (
            metric.metric_name?.toLowerCase().includes(e.target.value.trim())
          ) {
            return metric;
          }
        } else if (metric.sub_metrics.length > 0) {
          let subm = metric.sub_metrics.filter((sm) => {
            if (sm.metric_name?.toLowerCase().includes(e.target.value.trim())) {
              return sm;
            }
          });
          // console.log('subm',subm)
          if (subm.length > 0) return metric;
        }
      });
    e.target.value === ""
      ? setReviewMetricFiltered([])
      : setReviewMetricFiltered(filtered);
  };
  const [searchedEmail, setSearchedEmail] = useState([]);
  const handleContributorSearch = (e, searchList) => {
    setSearchText(e.target.value);
    if (sortedUsers.length === 0) {
      let filtered = searchList.filter((user) => {
        if (
          user.email?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          user.name?.toLowerCase().includes(e.target.value?.toLowerCase())
        ) {
          return user;
        }
      });
      setSearchedEmail(filtered);
    } else {
      let filtered = sortedUsers.filter((user) => {
        if (
          user.email?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          user.name?.toLowerCase().includes(e.target.value?.toLowerCase())
        ) {
          return user;
        }
      });
      setSearchedEmail(filtered);
    }
  };
  const handleApproverSearch = (e) => {
    setSearchText(e.target.value);
    if (sortedUsers.length === 0) {
      let filtered = generalSettings.allUsers.filter((user) => {
        if (
          user.email.includes(e.target.value?.toLowerCase()) ||
          user.name.includes(e.target.value?.toLowerCase())
        ) {
          return user;
        }
      });
      setSearchedEmail(filtered);
    } else {
      let filtered = sortedUsers.filter((user) => {
        if (
          user.email.includes(e.target.value?.toLowerCase()) ||
          user.name.includes(e.target.value?.toLowerCase())
        ) {
          return user;
        }
      });
      setSearchedEmail(filtered);
    }
  };
  useEffect(() => {
    !isAssignDialogOpen && setUsersType("All");
  }, [isAssignDialogOpen]);

  const lesserthan = () => {
    if (
      usersType === "All" &&
      usersCategory === "Show all" &&
      searchText === ""
    ) {
      return sortedContributorList(fieldData.allStep5Users);
    } else if (usersType !== "All" || usersCategory !== "Show all") {
      return <Typography>No user found..</Typography>;
    } else if (searchedEmail.length > 0 && searchText !== "") {
      return sortedContributorList(searchedEmail);
    } else if (searchText === "") {
      return sortedContributorList(searchedEmail);
    } else {
      return <Typography>No user found..</Typography>;
    }
  };

  const greaterthan = () => {
    if (searchedEmail.length > 0 && searchText !== "") {
      return sortedContributorList(searchedEmail);
    } else if (searchText === "") {
      return sortedContributorList(sortedUsers);
    } else {
      return <Typography>No user Found....</Typography>;
    }
  };

  useEffect(() => {
    let filtered = assignMetric
      .map((item) => item)
      .filter((metric) => {
        if (metric.sub_metrics_data.length === 0) {
          if (assignMetricId.includes(metric._id)) {
            return metric;
          }
        } else if (metric.sub_metrics_data.length > 0) {
          let subm = metric.sub_metrics_data.filter((sm) => {
            if (assignMetricId.includes(sm._id)) {
              return sm;
            }
          });
          // console.log('subm',subm)
          if (subm.length > 0) return metric;
        }
      });
    disclosureSort === "Selected Disclosures" && setAssignMetricSort(filtered);
    disclosureSort === "View All Disclosures" &&
      setAssignMetricSort(assignMetric);
  }, [disclosureSort, assignMetricId.length]);

  const assignDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={isAssignDialogOpen}
        // onClose={handleAssignDialog}
      >
        <div className={classes.mainDiv}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <Typography
                style={{ fontWeight: "bold", fontSize: "18px" }}
                variant="body1"
              >
                Assign Metric
              </Typography>
            </Grid>
            <Grid>
              <MuiThemeProvider theme={theme}>
                <AppBar
                  position="static"
                  color="inherit"
                  className={classes.AppBar}
                >
                  <Tabs
                    value={metricTabNumber}
                    variant="scrollable"
                    onChange={(e, i) => {
                      setMetricTabNumber(i);
                      if (i === 1 || i === 2) {
                        setSortedUsers([]);
                        setUsersType("All");
                        setCategory("All Category");
                      }
                    }}
                  >
                    <Tab
                      disableRipple
                      disabled={assignMetricId.length === 0}
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>
                          Select Disclosure
                        </span>
                      }
                    />
                    <Tab
                      disableRipple
                      disabled={emails.length === 0}
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>
                          Request contribution
                        </span>
                      }
                    />
                    {/* <Tab
                      disableRipple
                      disabled={approverEmails.length === 0}
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>
                          Request Approval
                        </span>
                      }
                    /> */}
                  </Tabs>
                </AppBar>
              </MuiThemeProvider>
            </Grid>
            <Grid item>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setCloseAssignDrawer(true)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: 5 }}>
            <div style={{ height: "85vh", width: "100%" }}>
              <Grid>
                <TabPanel value={metricTabNumber} index={0}>
                  <Grid>
                    <Grid>
                      <Typography className={classes.subTextMain}>
                        Select the users to whom you need to send the metrics
                        and then select the sub metric accordingly. the icon on
                        the right to the metric shows the users to whom you have
                        assigned.
                      </Typography>
                    </Grid>
                    <Grid>
                      <CustomTextfield
                        label="Type in disclosure/disclosure no/metric to find."
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        minRows={3}
                        multiline
                        onChange={handleAssignMetricSearch}
                      />
                    </Grid>
                    <Grid container item md={12}>
                      <Grid item md={6}>
                        <FormControl
                          style={{ color: "#3374B9", width: "250px" }}
                        >
                          <Select
                            style={{
                              border: "1px solid #C0D4E9",
                              backgroundColor: "#FEFEFE",
                              borderRadius: "8px",
                              height: "40px",
                              marginTop: "15px",
                            }}
                            value={disclosureSort}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              setDisclosureSort(value);
                            }}
                            input={<OutlinedInput />}
                          >
                            {assignDisclosure.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container alignItems="center" item md={6}>
                        <Grid item>
                          {/* <Typography
                            style={{ padding: '5px 5px 5px 5px' }}
                          >{`Disclosure Assigned (${assignMetricId.length})`}</Typography> */}
                        </Grid>
                        <Grid item>
                          {/* <Button
                            style={{
                              width: '300px',
                              textAlign: 'center',
                              fontFamily: 'poppins',
                              margin: '5px 0px 5px 1px',
                              backgroundColor: '#32A05F',
                              padding: '5px',
                            }}
                            disabled={assignMetricId.length === 0}
                            variant="contained"
                            size="small"
                            onClick={() => setMetricTabNumber(1)}
                          >
                            Completed
                          </Button> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      md={12}
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#EBF1F8",
                        padding: "3px",
                        borderRadius: "5px",
                      }}
                    >
                      <Grid item md={2}>
                        <Typography
                          style={{ fontSize: "13px", marginLeft: "5px" }}
                        >
                          Checked
                        </Typography>
                      </Grid>
                      <Grid item md={9}>
                        <Typography style={{ fontSize: "13px" }}>
                          Disclosure/metric
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography style={{ fontSize: "13px" }}>
                          User Count
                        </Typography>
                      </Grid>
                    </Grid>
                    <div
                      className="scroll"
                      style={{
                        margin: "15px 0px",
                        paddingBottom: "35px",
                        height: "52vh",
                      }}
                    >
                      <Grid>
                        {fieldData.assignedMembers.length > 0 && (
                          <Box
                            style={{
                              // height: 100,
                              // overflow: 'scroll',
                              scrollbarWidth: "none",
                            }}
                          >
                            {false &&
                              fieldData.assignedMembers.length > 0 &&
                              fieldData.assignedMembers.map((user, idx) => (
                                <Box
                                  key={idx}
                                  my={1}
                                  className={classes.user}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    src={
                                      user.profile_img && user.profile_img.url
                                        ? user.profile_img.url
                                        : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                                    }
                                  />
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: 5,
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "600" }}>
                                      {user.name}
                                    </Typography>
                                    <Typography style={{ fontSize: 12 }}>
                                      {user.email}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    style={{ marginLeft: "auto" }}
                                    varient="body2"
                                  >
                                    Remove
                                  </Typography>
                                </Box>
                              ))}
                          </Box>
                        )}
                        {metricFiltered.length > 0 && searchText !== "" ? (
                          metriclist(metricFiltered)
                        ) : searchText === "" ? (
                          disclosureSort === "Selected Disclosures" ? (
                            assignMetricSort.length > 0 ? (
                              metriclist(assignMetricSort)
                            ) : (
                              <Typography>No Metric Selected</Typography>
                            )
                          ) : (
                            metriclist(assignMetric)
                          )
                        ) : (
                          <Typography>No Metric Found</Typography>
                        )}
                        {/* </Paper> */}
                      </Grid>
                    </div>
                  </Grid>
                </TabPanel>
                <TabPanel value={metricTabNumber} index={1}>
                  <Grid>
                    <Grid>
                      <Typography className={classes.subTextMain}>
                        Select the users to whom you need to send the metrics
                        and then select the sub metric accordingly. the icon on
                        the right to the metric shows the list of users selected
                        for contribution.
                      </Typography>
                    </Grid>
                    <Grid>
                      <CustomTextfield
                        value={searchText}
                        label="Type in user name/email ID or select from below list of users"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        minRows={3}
                        multiline
                        onChange={(e) =>
                          handleContributorSearch(e, fieldData.allStep5Users)
                        }
                      />
                    </Grid>
                    <Grid container>
                      {" "}
                      <Grid item>
                        {" "}
                        <FormControl
                          style={{ color: "#3374B9", width: "180px" }}
                        >
                          <Select
                            style={{
                              marginLeft: "8px",
                              border: "1px solid #C0D4E9",
                              backgroundColor: "#FEFEFE",
                              borderRadius: "8px",
                              height: "40px",
                              marginTop: "15px",
                            }}
                            value={usersType}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              setUsersType(value);
                              setSearchText("");
                              setSearchedEmail([]);
                            }}
                            input={<OutlinedInput />}
                          >
                            {userType.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>{" "}
                      <Grid item>
                        {" "}
                        <FormControl
                          style={{ color: "#3374B9", width: "180px" }}
                        >
                          <Select
                            style={{
                              marginLeft: "8px",
                              border: "1px solid #C0D4E9",
                              backgroundColor: "#FEFEFE",
                              borderRadius: "8px",
                              height: "40px",
                              marginTop: "15px",
                            }}
                            value={usersCategory}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              setUsersCategory(value);
                              setSearchText("");
                              setSearchedEmail([]);
                            }}
                            input={<OutlinedInput />}
                          >
                            {userCategoryList.map((item, index) => {
                              return (
                                <MenuItem
                                  style={{
                                    display: "flex",
                                  }}
                                  key={index}
                                  value={item}
                                >
                                  <Grid container>
                                    {/* <Grid item>
                                  <Icon>{item.icon}</Icon>
                                </Grid> */}
                                    <Grid item>
                                      <Typography
                                        style={{
                                          marginLeft: "7px",
                                          marginTop: "2px",
                                        }}
                                      >{`${item}`}</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>{" "}
                    </Grid>
                    <Grid container>
                      <Grid item md={7}>
                        <div
                          className="scroll"
                          style={{ paddingBottom: "25px" }}
                        >
                          {sortedUsers.length === 0
                            ? lesserthan()
                            : greaterthan()}

                          {/* {sortedUsers.length === 0  ? (
                            searchedEmail.length > 0 && searchText !== '' ? (
                              sortedContributorList(searchedEmail)
                            ) : searchText === ''  ? (
                              sortedContributorList(fieldData.allStep5Users)
                            ) : (
                              <Typography>No user Found.///</Typography>
                            )
                          ) : searchedEmail.length>0   && searchText !== '' ? (
                            sortedContributorList(searchedEmail)
                          ) : searchText === ''  ? (
                            sortedContributorList(sortedUsers)
                          ) : (
                            <Typography>No user Found....</Typography>
                          )} */}
                        </div>
                      </Grid>
                      <Grid item md={5}>
                        <Paper
                          style={{
                            margin: "12px 3px",
                            borderRadius: "8px",
                            height: "31vh",
                          }}
                          variant="outlined"
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography
                                style={{
                                  fontSize: "small",
                                  padding: "5px 5px 5px 5px",
                                }}
                              >{`Contributors (${emails.length})`}</Typography>
                            </Grid>
                            <Grid item>
                              <CustomButton
                                style={{
                                  width: "180px",
                                  textAlign: "center",
                                  fontFamily: "poppins",
                                  margin: "5px",
                                  fontSize: "10px",
                                  border: "0.5px solid #32A05F",
                                  color: "#32A05F",
                                  padding: "5px",
                                }}
                                variant="outlined"
                                size="small"
                                // disabled={
                                //   assignMetricId.length === 0 ||
                                //   emails.length === 0
                                // }
                                onClick={() => {
                                  setEmails((prevEmails) => {
                                    const uniqueEmails = new Set();
                                    const mergedEmails = [
                                      ...contributorSelectedEmails,
                                      ...prevEmails,
                                    ];

                                    const uniqueObjects = mergedEmails.filter(
                                      (obj) => {
                                        if (!uniqueEmails.has(obj.email)) {
                                          uniqueEmails.add(obj.email);
                                          return true;
                                        }
                                        return false;
                                      }
                                    );

                                    return uniqueObjects;
                                  });
                                  setContributorSelectedEmails([]);
                                  // handleAssignDialog();
                                  // setMetricTabNumber(2);
                                }}
                              >
                                Add Section to Contributor
                              </CustomButton>
                            </Grid>
                          </Grid>

                          <Grid>
                            <div
                              className="scroll"
                              style={{
                                margin: "10px 0px",
                                paddingBottom: "35px",
                                height: "25vh",
                              }}
                            >
                              {/* <Paper className={classes.userPaper} style={{ margin: '12px 3px', borderRadius:'8px' }} variant="outlined"> */}
                              {emails.length > 0 &&
                                emails.map((user) => {
                                  return (
                                    <Grid
                                      key={user.email}
                                      style={{
                                        padding: "7px",
                                        backgroundColor: "#E5E5E5",
                                      }}
                                      container
                                      alignItems="center"
                                      item
                                      md={12}
                                    >
                                      <Grid item md={2}>
                                        <Avatar
                                          src={
                                            user.profile_img &&
                                            user.profile_img.url
                                              ? user.profile_img.url
                                              : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                                          }
                                        />
                                      </Grid>
                                      <Grid item md={9}>
                                        <Grid>{user.name}</Grid>
                                        <Grid>{user.email}</Grid>
                                      </Grid>
                                      <Grid item md={1}>
                                        <RemoveCircleOutlineIcon
                                          onClick={() => {
                                            const emailList = [...emails];
                                            const index = emailList
                                              .map((user) => user.email)
                                              .indexOf(user.email);
                                            index === -1
                                              ? emailList.push(user)
                                              : emailList.splice(index, 1);
                                            setEmails(emailList);
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              {/* </Paper> */}
                            </div>
                          </Grid>
                        </Paper>
                        <Paper
                          style={{
                            margin: "12px 3px",
                            borderRadius: "8px",
                            height: "31vh",
                          }}
                          variant="outlined"
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography
                                style={{
                                  fontSize: "small",
                                  padding: "5px 5px 5px 5px",
                                }}
                              >{`Approvers (${approverEmails.length})`}</Typography>
                            </Grid>
                            <Grid item>
                              <CustomButton
                                style={{
                                  width: "180px",
                                  textAlign: "center",
                                  fontFamily: "poppins",
                                  margin: "5px",
                                  fontSize: "10px",
                                  border: "0.5px solid #32A05F",
                                  color: "#32A05F",
                                  padding: "5px",
                                }}
                                variant="outlined"
                                size="small"
                                // disabled={
                                //   assignMetricId.length === 0 ||
                                //   approverEmails.length === 0
                                // }
                                onClick={() => {
                                  setApproveremails((prevEmails) => {
                                    const uniqueEmails = new Set();
                                    const mergedEmails = [
                                      ...contributorSelectedEmails,
                                      ...prevEmails,
                                    ];

                                    const uniqueObjects = mergedEmails.filter(
                                      (obj) => {
                                        if (!uniqueEmails.has(obj.email)) {
                                          uniqueEmails.add(obj.email);
                                          return true;
                                        }
                                        return false;
                                      }
                                    );

                                    return uniqueObjects;
                                  });
                                  setContributorSelectedEmails([]);
                                  // handleAssignDialog();
                                  // setMetricTabNumber(2);
                                }}
                              >
                                Add Section to Approver
                              </CustomButton>
                            </Grid>
                          </Grid>

                          <Grid>
                            <div
                              className="scroll"
                              style={{
                                margin: "10px 0px",
                                paddingBottom: "35px",
                                height: "25vh",
                              }}
                            >
                              {/* <Paper className={classes.userPaper} style={{ margin: '12px 3px', borderRadius:'8px' }} variant="outlined"> */}
                              {approverEmails.length > 0 &&
                                approverEmails.map((user) => {
                                  return (
                                    <Grid
                                      key={user.email}
                                      style={{
                                        padding: "7px",
                                        backgroundColor: "#E5E5E5",
                                      }}
                                      container
                                      alignItems="center"
                                      item
                                      md={12}
                                    >
                                      <Grid item md={2}>
                                        <Avatar
                                          src={
                                            user.profile_img &&
                                            user.profile_img.url
                                              ? user.profile_img.url
                                              : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                                          }
                                        />
                                      </Grid>
                                      <Grid item md={9}>
                                        <Grid>{user.name}</Grid>
                                        <Grid>{user.email}</Grid>
                                      </Grid>
                                      <Grid item md={1}>
                                        <RemoveCircleOutlineIcon
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            const emailList = [
                                              ...approverEmails,
                                            ];
                                            const index = emailList
                                              .map((user) => user.email)
                                              .indexOf(user.email);
                                            index === -1
                                              ? emailList.push(user)
                                              : emailList.splice(index, 1);
                                            setApproveremails(emailList);
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              {/* </Paper> */}
                            </div>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={metricTabNumber} index={2}>
                  <Grid>
                    <Grid>
                      <Typography className={classes.subTextMain}>
                        Select the users to whom you need to send the metrics
                        and then select the sub metric accordingly. the icon on
                        the right to the metric shows the list of users selected
                        for contribution.
                      </Typography>
                    </Grid>
                    <Grid>
                      <CustomTextfield
                        label="Type in user name/email ID or select from below list of users"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        minRows={3}
                        multiline
                        value={searchText}
                        onChange={handleApproverSearch}
                      />
                    </Grid>
                    <Grid>
                      {" "}
                      <FormControl style={{ color: "#3374B9", width: "180px" }}>
                        <Select
                          style={{
                            marginLeft: "8px",
                            border: "1px solid #C0D4E9",
                            backgroundColor: "#FEFEFE",
                            borderRadius: "8px",
                            height: "40px",
                            marginTop: "15px",
                          }}
                          value={usersType}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setUsersType(value);
                            setSearchText("");
                            setSearchedEmail([]);
                          }}
                          input={<OutlinedInput />}
                        >
                          {userType.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl style={{ color: "#3374B9", width: "180px" }}>
                        <Select
                          style={{
                            marginLeft: "8px",
                            border: "1px solid #C0D4E9",
                            backgroundColor: "#FEFEFE",
                            borderRadius: "8px",
                            height: "40px",
                            marginTop: "15px",
                          }}
                          value={sort}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                          }}
                          input={<OutlinedInput />}
                        >
                          {sortList.map((item, index) => {
                            return (
                              <MenuItem
                                style={{
                                  display: "flex",
                                }}
                                key={index}
                                value={item.text}
                              >
                                <Grid container>
                                  <Grid item>
                                    <Icon>{item.icon}</Icon>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      style={{
                                        marginLeft: "7px",
                                        marginTop: "2px",
                                      }}
                                    >{`${item.text}`}</Typography>
                                  </Grid>
                                </Grid>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {/* <FormControl style={{ color: '#3374B9', width: '180px' }}>
                        <Select
                          style={{
                            marginLeft: '8px',
                            border: '1px solid #C0D4E9',
                            backgroundColor: '#FEFEFE',
                            borderRadius: '8px',
                            height: '40px',
                            marginTop: '15px',
                          }}
                          value={category}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setCategory(value);
                          }}
                          input={<OutlinedInput />}
                        >
                          {userCategory.map((item) => {
                            return (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl> */}
                    </Grid>
                    <Grid container>
                      <Grid item md={7}>
                        <div
                          className="scroll"
                          style={{ paddingBottom: "25px" }}
                        >
                          {sortedUsers.length === 0 ? (
                            searchedEmail.length > 0 && searchText !== "" ? (
                              sortedApproverList(searchedEmail)
                            ) : searchText === "" ? (
                              sortedApproverList(generalSettings.allUsers)
                            ) : (
                              <Typography>No user Found.</Typography>
                            )
                          ) : searchedEmail.length > 0 && searchText !== "" ? (
                            sortedApproverList(searchedEmail)
                          ) : searchText === "" ? (
                            sortedApproverList(sortedUsers)
                          ) : (
                            <Typography>No user Found.</Typography>
                          )}
                        </div>
                      </Grid>
                      <Grid item md={5}>
                        <Paper
                          style={{ margin: "12px 3px", borderRadius: "8px" }}
                          variant="outlined"
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              {/* <Typography
                                style={{
                                  fontSize: 'small',
                                  padding: '5px 5px 5px 5px',
                                }}
                              >{`Approvers (${approverEmails.length})`}</Typography> */}
                            </Grid>
                            <Grid item>
                              {/* <Button
                                style={{
                                  width: '180px',
                                  textAlign: 'center',
                                  fontFamily: 'poppins',
                                  margin: '5px',
                                  backgroundColor: '#32A05F',
                                  padding: '5px',
                                }}
                                variant="contained"
                                size="small"
                                disabled={
                                  assignMetricId.length === 0 ||
                                  emails.length === 0
                                }
                                onClick={() => {
                                  handleAssignDialog();
                                }}
                              >
                                Complete List
                              </Button> */}
                            </Grid>
                          </Grid>

                          <Grid>
                            <div
                              className="scroll"
                              style={{
                                margin: "10px 0px",
                                paddingBottom: "35px",
                                height: "50vh",
                              }}
                            >
                              {approverEmails.length > 0 &&
                                approverEmails.map((user) => {
                                  return (
                                    <Grid
                                      key={user.email}
                                      style={{
                                        padding: "7px",
                                        backgroundColor: "#E5E5E5",
                                      }}
                                      container
                                      alignItems="center"
                                      item
                                      md={12}
                                    >
                                      <Grid item md={2}>
                                        <Avatar
                                          src={
                                            user.profile_img &&
                                            user.profile_img.url
                                              ? user.profile_img.url
                                              : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                                          }
                                        />
                                      </Grid>
                                      <Grid item md={9}>
                                        <Grid>{user.name}</Grid>
                                        <Grid>{user.email}</Grid>
                                      </Grid>
                                      <Grid item md={1}>
                                        <RemoveCircleOutlineIcon
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleApproverEmails(user)
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </div>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </div>
          </Grid>
          <Grid style={{ width: "550px" }}>
            <Box sx={{ width: `calc(100px + 16px)` }}>
              <Slide
                direction="up"
                in={
                  metricTabNumber === 0
                    ? assignMetricId.length === 0
                      ? false
                      : true
                    : metricTabNumber === 1 &&
                      emails.length === 0 &&
                      approverEmails.length === 0
                    ? false
                    : true
                }
                mountOnEnter
                unmountOnExit
              >
                <Grid className={classes.floatingButton}>
                  {metricTabNumber === 0 && (
                    <>
                      <Button
                        varient="outlined"
                        color="primary"
                        className={classes.clearButton}
                        onClick={(e) => handleAssignMetricCheckBox(e)}
                      >
                        Clear All
                      </Button>
                      <Button
                        varient="outlined"
                        color="primary"
                        className={classes.clearButton}
                        onClick={(e) => handleAssignMetricCheckBox(e)}
                      >
                        Select All
                      </Button>
                    </>
                  )}
                  {metricTabNumber === 1 && (
                    <>
                      <Button
                        varient="outlined"
                        color="primary"
                        className={classes.clearButton}
                        onClick={() => {
                          setContributorSelectedEmails([]);
                          setEmails([]);
                          setApproveremails([]);
                        }}
                      >
                        Clear list
                      </Button>
                    </>
                  )}

                  <Button
                    varient="contained"
                    className={classes.completeButton}
                    onClick={() => {
                      setMetricFiltered([]);
                      setSearchText("");
                      metricTabNumber === 0 && setMetricTabNumber(1);
                      if (metricTabNumber === 1) {
                        handleAssignDialog();
                        // setMetricTabNumber(2);
                      }
                      metricTabNumber === 2 && handleAssignDialog();
                    }}
                  >
                    Complete List
                  </Button>
                </Grid>
              </Slide>
            </Box>
            {/* <Slide
            direction="down"
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={1000}
          >
          </Slide> */}
          </Grid>
        </div>
        {closeAssignDrawer && closeDialog()}
      </Drawer>
    );
  };

  const sortedStakeholderList = (toMap) => {
    return (
      toMap &&
      toMap.map((user, i) => {
        return (
          <Grid key={i} style={{}}>
            <Grid
              style={{
                position: "relative",
                margin: "5px",
                padding: "5px",
              }}
              container
              alignItems="center"
              item
              md={12}
              sm={12}
              xs={12}
            >
              <Grid item md={1} sm={1} xs={1}>
                <Avatar
                  src={
                    user.profile_img && user.profile_img.url
                      ? user.profile_img.url
                      : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                  }
                />
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Grid>
                  <Typography>{user.name}</Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "grey",
                      textAlign: "left",
                    }}
                  >
                    {user.designation}
                  </Typography>
                </Grid>
              </Grid>
              {/* hhhhhhhhhhhhh */}
              <Grid style={{ display: "flex" }} item md={2} sm={2} xs={2}>
                <Grid
                  onClick={() => {
                    `panel${i}` === expand && setToggle(!toggle);
                    setExpand(`panel${i}`);
                    setIsApproverAccordion(true);
                  }}
                  style={
                    `panel${i}` === expand && toggle && isApproverAccordion
                      ? {
                          display: "flex",
                          border: "none",
                          backgroundColor: "#303f9f",
                          borderRadius: "10px",
                          marginRight: "6px",
                          alignItems: "center",
                          width: "65px",
                          padding: "0px 5px",
                          cursor: "pointer",
                        }
                      : {
                          display: "flex",
                          border: "none",
                          backgroundColor: "#E9E9E9",
                          borderRadius: "10px",
                          marginRight: "6px",
                          alignItems: "center",
                          width: "65px",
                          padding: "0px 5px",
                          cursor: "pointer",
                        }
                  }
                >
                  <div
                    style={
                      `panel${i}` === expand && toggle
                        ? {
                            backgroundColor: "#E9E9E9",
                            border: "none",
                            padding: "4px",
                            borderRadius: "50%",
                            marginRight: "2px",
                          }
                        : {
                            backgroundColor: "#303f9f",
                            border: "none",
                            padding: "4px",
                            borderRadius: "50%",
                            marginRight: "2px",
                          }
                    }
                  />
                  <Typography
                    style={
                      `panel${i}` === expand && toggle
                        ? {
                            padding: "2px 5px",
                            color: "white",
                            fontSize: 13,
                          }
                        : { fontSize: 13, padding: "2px 5px" }
                    }
                  >
                    {user.approvers && user.approvers.length}
                  </Typography>
                  {`panel${i}` === expand && toggle ? (
                    <KeyboardArrowUpIcon
                      style={
                        `panel${i}` === expand && toggle
                          ? { color: "white", fontSize: 18 }
                          : { color: "inherit", fontSize: 18 }
                      }
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={
                        `panel${i}` === expand && toggle
                          ? { color: "white", fontSize: 18 }
                          : { color: "inherit", fontSize: 18 }
                      }
                    />
                  )}
                </Grid>
                <Grid
                  style={
                    `panel${i}` === expand && toggle && !isApproverAccordion
                      ? {
                          display: "flex",
                          border: "none",
                          backgroundColor: "#32A05F",
                          borderRadius: "10px",
                          marginRight: "6px",
                          alignItems: "center",
                          width: "65px",
                          padding: "0px 5px",
                          cursor: "pointer",
                        }
                      : {
                          display: "flex",
                          border: "none",
                          backgroundColor: "#E9E9E9",
                          borderRadius: "10px",
                          marginRight: "6px",
                          alignItems: "center",
                          width: "65px",
                          padding: "0px 5px",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => {
                    `panel${i}` === expand && setToggle(!toggle);
                    setExpand(`panel${i}`);
                    setIsApproverAccordion(false);
                  }}
                >
                  <div
                    style={
                      `panel${i}` === expand && toggle
                        ? {
                            backgroundColor: "white",
                            border: "none",
                            padding: "4px",
                            borderRadius: "50%",
                            marginRight: "2px",
                          }
                        : {
                            backgroundColor: "#32A05F",
                            border: "none",
                            padding: "4px",
                            borderRadius: "50%",
                            marginRight: "2px",
                          }
                    }
                  />
                  <Typography
                    style={
                      `panel${i}` === expand && toggle
                        ? {
                            padding: "2px 5px",
                            color: "white",
                            fontSize: 13,
                          }
                        : { padding: "2px 5px", fontSize: 13 }
                    }
                  >
                    {Array.isArray(user.contributors)
                      ? user.contributors.length
                      : "0"}
                  </Typography>
                  {`panel${i}` === expand && toggle ? (
                    <KeyboardArrowUpIcon
                      style={
                        `panel${i}` === expand && toggle
                          ? { color: "white", fontSize: 18 }
                          : { color: "inherit", fontSize: 18 }
                      }
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={
                        `panel${i}` === expand && toggle
                          ? { color: "white", fontSize: 18 }
                          : { color: "inherit", fontSize: 18 }
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* {expand === `panel${i}` && ( */}
            <Grid
              style={
                expand === `panel${i}` && toggle
                  ? {
                      position: "relative",
                      height: 140,
                      overflow: "hidden",
                      transition: "0.5s",
                      overflowY: "auto",
                    }
                  : {
                      position: "relative",
                      height: 0,
                      overflow: "hidden",
                      transition: "0.5s",
                      overflowY: "auto",
                    }
              }
            >
              <Grid style={{ padding: "5px 15px" }}>
                {isApproverAccordion && (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid>
                      {" "}
                      <div
                        style={{
                          backgroundColor: "#303f9f",
                          border: "none",
                          padding: "4px",
                          borderRadius: "50%",
                          marginRight: "4px",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        Assigned as Approver
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {isApproverAccordion &&
                  user.approvers &&
                  user.approvers.map((metric, idx1) => {
                    return (
                      <div
                        key={idx1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "42px",
                            height: "30px",
                            padding: "5px",
                            border: "none",
                            backgroundColor: "#EBF1F8",
                            color: "#3374B9",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #3374B9",
                              backgroundColor: "#3374B9",
                              margin: "2px",
                              borderRadius: "5px",
                              width: "3px",
                              height: "18px",
                              fontWeight: "bold",
                            }}
                          ></div>
                          <div>
                            <Typography
                              style={{
                                padding: "2px 5px 2px 2px",
                              }}
                            >
                              {i + 1}
                            </Typography>
                          </div>
                        </div>
                        <div>
                          <Typography>
                            {metric.metric_desc + metric.metric_name}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                {!isApproverAccordion && (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid>
                      {" "}
                      <div
                        style={{
                          backgroundColor: "#32A05F",
                          border: "none",
                          padding: "4px",
                          borderRadius: "50%",
                          marginRight: "4px",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "grey",
                          // textAlign: 'left',
                        }}
                      >
                        Assigned as Contributor
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!isApproverAccordion &&
                  user.contributors &&
                  user.contributors.map((metric, idx2) => {
                    return (
                      <div
                        key={idx2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "42px",
                            height: "30px",
                            padding: "5px",
                            border: "none",
                            backgroundColor: "#EBF1F8",
                            color: "#3374B9",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #3374B9",
                              backgroundColor: "#3374B9",
                              margin: "2px",
                              borderRadius: "5px",
                              width: "3px",
                              height: "18px",
                              fontWeight: "bold",
                            }}
                          ></div>
                          <div>
                            <Typography
                              style={{
                                padding: "2px 5px 2px 2px",
                              }}
                            >
                              {i + 1}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ alignItems: "center" }}>
                          <Typography>
                            {metric.metric_desc + metric.metric_name}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
            {/* )} */}
          </Grid>
        );
      })
    );
  };
  const [isStakeholderDrawerOpen, setIsStakeholderDrawerOpen] = useState(false);
  useEffect(() => {
    !isStakeholderDrawerOpen && setStakeholderType("All");
  }, [isStakeholderDrawerOpen]);
  const stakeholderDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={isStakeholderDrawerOpen}
        onClose={() => setIsStakeholderDrawerOpen(false)}
      >
        <div className={classes.stakeholderDiv}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <Typography
                style={{ fontWeight: "bold", fontSize: "18px" }}
                variant="body1"
              >
                Stakeholders
              </Typography>
            </Grid>
            <Grid></Grid>
            <Grid item>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsApproverAccordion(null);
                  setExpand(false);
                  setIsStakeholderDrawerOpen(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: 25 }}>
            <div style={{ height: "85vh", width: "100%" }}>
              <Grid>
                <Grid>
                  <Grid>
                    <Typography className={classes.subTextMain}>
                      Select the users to whom you need to send the metrics and
                      then select the sub metric accordingly. the icon on the
                      right to the metric shows the users to whom you have
                      assigned.
                    </Typography>
                  </Grid>
                  <Grid>
                    <CustomTextfield
                      label="Type in user name/email ID"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      minRows={3}
                      multiline
                      onChange={(e) =>
                        handleContributorSearch(
                          e,
                          fieldData.metrics.assigned_users_data
                        )
                      }
                    />
                  </Grid>
                  <Grid justifyContent="space-between" container item md={12}>
                    <Grid item md={6}>
                      <FormControl style={{ color: "#3374B9", width: "250px" }}>
                        <Select
                          style={{
                            marginLeft: "8px",
                            border: "1px solid #C0D4E9",
                            backgroundColor: "#FEFEFE",
                            borderRadius: "8px",
                            height: "40px",
                            marginTop: "15px",
                          }}
                          value={stakeholderType}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setStakeholderType(value);
                            setSearchText("");
                            setSearchedEmail([]);
                          }}
                          input={<OutlinedInput />}
                        >
                          {userType.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <Grid style={{ margin: "10px 0px 0px 5px" }}>
                        <Grid style={{ display: "flex", alignItems: "center" }}>
                          <Grid>
                            {" "}
                            <div
                              style={{
                                backgroundColor: "#303f9f",
                                border: "none",
                                padding: "4px",
                                borderRadius: "50%",
                                marginRight: "4px",
                              }}
                            />
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "13px",
                                color: "grey",
                                textAlign: "left",
                              }}
                            >
                              Assigned as Approver
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid style={{ display: "flex", alignItems: "center" }}>
                          <Grid>
                            {" "}
                            <div
                              style={{
                                backgroundColor: "#32A05F",
                                border: "none",
                                padding: "4px",
                                borderRadius: "50%",
                                marginRight: "4px",
                              }}
                            />
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "13px",
                                color: "grey",
                                textAlign: "left",
                              }}
                            >
                              Assigned as Contributor
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {/* <Button
                            style={{
                              width: '300px',
                              textAlign: 'center',
                              fontFamily: 'poppins',
                              margin: '5px 0px 5px 1px',
                              backgroundColor: '#32A05F',
                              padding: '5px',
                            }}
                            disabled={assignMetricId.length === 0}
                            variant="contained"
                            size="small"
                            onClick={() => setMetricTabNumber(1)}
                          >
                            Completed
                          </Button> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#EBF1F8",
                      padding: "3px",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid item md={2} xs={2}>
                      <Typography
                        style={{ fontSize: "13px", marginLeft: "5px" }}
                      >
                        Checked
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography style={{ fontSize: "13px" }}>
                        Stakeholder name
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <Typography style={{ fontSize: "13px" }}>
                        No. of Disclosures
                      </Typography>
                    </Grid>
                  </Grid>
                  <div
                    className="scroll"
                    style={{
                      margin: "15px 0px",
                      paddingBottom: "35px",
                      height: "52vh",
                    }}
                  >
                    {sortedUsers.length === 0 ? (
                      stakeholderType !== "All" ? (
                        <Typography>No user</Typography>
                      ) : searchedEmail.length > 0 && searchText !== "" ? (
                        sortedStakeholderList(searchedEmail)
                      ) : searchText === "" ? (
                        sortedStakeholderList(
                          fieldData.metrics.assigned_users_data
                        )
                      ) : (
                        <Typography>No user Found.</Typography>
                      )
                    ) : searchedEmail.length > 0 && searchText !== "" ? (
                      sortedStakeholderList(searchedEmail)
                    ) : searchText === "" ? (
                      sortedStakeholderList(sortedUsers)
                    ) : (
                      <Typography>No user Found.</Typography>
                    )}
                    {/* {sortedUsers.length === 0 ? (
                      searchedEmail.length > 0 && searchText !== "" ? (
                        sortedStakeholderList(searchedEmail)
                      ) : searchText === "" ? (
                        sortedStakeholderList(
                          fieldData.metrics.assigned_users_data
                        )
                      ) : (
                        <Typography>No user Found.</Typography>
                      )
                    ) : searchedEmail.length > 0 && searchText !== "" ? (
                      sortedStakeholderList(searchedEmail)
                    ) : searchText === "" ? (
                      sortedStakeholderList(sortedUsers)
                    ) : (
                      <Typography>No user Found.</Typography>
                    )} */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
        {closeAssignDrawer && closeDialog()}
      </Drawer>
    );
  };

  const assignPopper = () => {
    return (
      <ClickAwayListener onClickAway={handleClickButton}>
        <Popper
          id={id}
          open={open}
          placement="right-start"
          disablePortal={false}
          className={classes.popper}
          anchorEl={anchorEl}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: "viewport",
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
        >
          <Paper className={classes.paper} variant="outlined">
            <DialogTitle>Assigned User List</DialogTitle>
            {fieldData.assignedMetricUsers.length > 0 ? (
              fieldData.assignedMetricUsers.map(
                (metricData) =>
                  metricData.metric_id === metricUserId &&
                  metricData.assigned_users.map((userData, index) => (
                    <DialogContent key={index}>
                      <List dense>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontWeight: 500, fontSize: "16px" }}
                              >
                                {userData.name}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "#9FA1AF",
                                }}
                              >
                                {userData.designation}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              name="rmvAsgnUser"
                              edge="end"
                              aria-label="delete"
                              style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "#BBBBBB",
                              }}
                              onClick={() => {
                                deleteAssignedMember(
                                  login.token,
                                  userData._id,
                                  assessment_id,
                                  assignIds,
                                  5 // step_no
                                );
                              }}
                            >
                              Remove
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </DialogContent>
                  ))
              )
            ) : (
              <DialogContent>
                {/* <DialogContentText>user_name</DialogContentText> */}
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography style={{ fontWeight: 700 }}>
                          no user added
                        </Typography>
                      }
                      // secondary="Secondary text"
                    />
                  </ListItem>
                </List>
              </DialogContent>
            )}

            <DialogActions>
              <CustomButton
                id="not assign"
                // onClick={handleAssignDialogCancel}
                variant="outlined"
                color="primary"
                onClick={handleClickButton}
              >
                Cancel
              </CustomButton>

              <CustomButton
                // disabled={assignMetricId.length === 0 || emails.length === 0}
                id="assign"
                // onClick={handleAssignDialog}
                variant="contained"
                color="primary"
                onClick={handleClickButton}
              >
                Confirm User List
              </CustomButton>
            </DialogActions>
          </Paper>
        </Popper>
      </ClickAwayListener>
    );
  };

  let container = document.getElementById("container");
  useEffect(() => {
    if (fieldData.reportTab === 1 && container) {
      container.scrollTop = 0;
      setSearchText("");
      setReviewMetricFiltered([]);
    }
  }, [fieldData.currentReviewMetric]);

  const omissionOptions = [
    "Omit without reason",
    "Not applicable",
    "Confidentiality constraints",
    "Specific legal prohibitions",
    "Information unavailable",
    "Other, Please specify",
  ];

  const omissionReasonTable = [
    {
      reason: "Not applicable",
      desc: "Specify the reason(s) why the disclosure is considered to be not applicable.",
    },
    {
      reason: "Confidentiality constraints",
      desc: "Describe the specific confidentiality constraints prohibiting the disclosure",
    },
    {
      reason: "Specific legal prohibitions",
      desc: "Describe the specific legal prohibitions.",
    },
    {
      reason: "Information unavailable",
      desc: "Describe the specific steps being taken to obtain the information and the expected timeframe for doing so. If the reason for omission is due to the fact that the necessary information cannot be obtained, or is not of adequate quality to report (as may sometimes be the case when the Boundary for a material topic extends beyond the reporting organization), explain this situation",
    },
  ];

  const [omissionFile, setOmissionFile] = useState(null);

  const omissionDialog = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isOmissionDialog}
        onClose={handleOmissionDialog}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={5}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Are You Sure You Want To Omit
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 850 }}>
            <Typography style={{ fontWeight: "600" }}>Part Omitted</Typography>
            <Box
              style={{
                // backgroundColor: '#DDE9F6',
                borderRadius: 5,
                // margin: '10px 0px',
                padding: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "42px",
                    height: "30px",
                    padding: "5px",
                    border: "none",
                    backgroundColor: "#EBF1F8",
                    color: "#3374B9",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #3374B9",
                      backgroundColor: "#3374B9",
                      margin: "2px",
                      borderRadius: "5px",
                      width: "3px",
                      height: "18px",
                      fontWeight: "bold",
                    }}
                  ></div>
                  <div>
                    <Typography
                      style={{
                        padding: "2px 5px 2px 2px",
                      }}
                    >
                      {currentOmitMetrics.metric_name.split(":")[0]}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography
                    style={{ fontWeight: "600", paddingLeft: "10px" }}
                  >
                    {currentOmitMetrics.metric_name.split(":")[1]}
                  </Typography>
                </div>
              </div>
              {currentOmitMetrics.metric_desc && (
                <Typography style={{ fontSize: 12, padding: "15px 0px" }}>
                  {currentOmitMetrics.metric_desc}
                </Typography>
              )}
            </Box>
            <Typography style={{ fontWeight: "600", marginBottom: "20px" }}>
              Reason for Omission
            </Typography>
            <CustomTextfield
              // name="selectReason"
              fullWidth
              label="Reason For Omission"
              select
              variant="outlined"
              style={{ background: "#F0F6FD", borderRadius: "15px" }}
              value={omissionReason}
              onChange={(e) => setOmissionReason(e.target.value)}
            >
              {omissionOptions.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </CustomTextfield>
            <>
              {false && !showReasons && (
                <CustomButton
                  style={{ marginTop: "16px" }}
                  onClick={() => setShowReasons(true)}
                >
                  Table 2 reasons for omission
                </CustomButton>
              )}

              <Collapse in={showReasons}>
                <Paper variant="outlined" style={{ marginTop: "16px" }}>
                  <Box display="flex" flexDirection="column">
                    {omissionReasonTable.map((r, i) => (
                      <Box
                        key={i}
                        display="flex"
                        justifyContent="space-between"
                        paddingTop="16px"
                        paddingBottom="16px"
                        paddingX="10px"
                      >
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            lineHeight: "24px",
                            textTransform: "capitalize",
                            color: "#242424",
                          }}
                        >
                          {r.reason}
                        </Typography>
                        <Box
                          display="flex"
                          maxWidth="60%"
                          textAlign="right"
                          color="#9FA1AF"
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: "24px",
                              textTransform: "capitalize",
                            }}
                          >
                            {r.desc}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Paper>
                <CustomButton
                  style={{ marginTop: "16px" }}
                  onClick={() => setShowReasons(false)}
                >
                  Hide Reasons
                </CustomButton>
              </Collapse>
            </>

            {/* <div className={classes.divider}></div> */}
            <Typography style={{ fontWeight: "600", padding: "15px 0px" }}>
              Describe The Omission in Details
            </Typography>
            <Box my={2}>
              <CustomTextfield
                name="expReason"
                label="Enter Explanation"
                fullWidth
                multiline
                minRows={5}
                size="small"
                variant="outlined"
                disabled={omissionReason === "Omit without reason"}
                value={omissionComment}
                onChange={(e) => setOmissionComment(e.target.value)}
              />
            </Box>
            <div>
              {omissionFile === "" || omissionFile === null ? (
                <div
                  style={{
                    width: "240px",
                    padding: "3px",
                    borderRadius: "5px",
                    backgroundColor: "#EBF1F8",
                  }}
                >
                  <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    onChange={(event) => setOmissionFile(event.target.files[0])}
                    className={classes.input1}
                  />
                  <label htmlFor="contained-button-activityFile">
                    <Box
                      display="flex"
                      component="span"
                      alignItems="center"
                      className={classes.pointer}
                    >
                      <AttachFileIcon
                        color="disabled"
                        size="small"
                        style={{ color: "#3374B9", fontsize: "1.2em" }}
                      />
                      <Box mx={2} className={classes.BoxG} width="100%">
                        <Typography
                          variant="caption"
                          style={{ color: "#3374B9" }}
                        >
                          Add Supporting Documents
                        </Typography>
                      </Box>
                    </Box>
                  </label>
                </div>
              ) : (
                <div
                  style={{
                    width: "240px",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  {/* <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    className={classes.input1}
                  /> */}
                  <label htmlFor="contained-button-activityFile">
                    <Box
                      display="flex"
                      component="span"
                      alignItems="center"
                      className={classes.pointer}
                    >
                      <Box mx={2} className={classes.BoxG} width="100%">
                        <Typography
                          variant="caption"
                          style={{ color: "#3374B9" }}
                        >
                          {omissionFile.name}
                        </Typography>
                      </Box>
                      <ClearIcon
                        style={{ color: "#3374B9", fontsize: "1.2em" }}
                        onClick={() => {
                          setOmissionFile(null);
                        }}
                      />
                    </Box>
                  </label>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleOmissionDialog}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              updateOmission(
                login.token,
                assessment_id,
                currentOmitMetrics._id,
                true,
                sendGrandParentId
                  ? selectedGrandParentId
                  : currentOmitMetrics.parent_id,
                currentOmitMetrics.main_topic_id,
                true,
                omissionFile,
                omissionReason,
                omissionComment
              );
              handleOmissionDialog();
              setSendGrandParentId(false);
              setOmissionComment("");
              setOmissionReason("");
            }}
            disabled={omissionReason === ""}
            variant="contained"
            color="primary"
          >
            Omit
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const removeOmissionDialog = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isRemoveOmissionDialog}
        onClose={handleRemoveOmissionDialog}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Remove Omission
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 850 }}>
            <Typography style={{ fontWeight: "600" }}>Part Omitted</Typography>
            <Box
              style={{
                // backgroundColor: '#DDE9F6',
                borderRadius: 5,
                margin: "10px 0px",
                padding: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "42px",
                    height: "30px",
                    padding: "5px",
                    border: "none",
                    backgroundColor: "#EBF1F8",
                    color: "#3374B9",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #3374B9",
                      backgroundColor: "#3374B9",
                      margin: "2px",
                      borderRadius: "5px",
                      width: "3px",
                      height: "18px",
                      fontWeight: "bold",
                    }}
                  ></div>
                  <div>
                    <Typography
                      style={{
                        padding: "2px 5px 2px 2px",
                      }}
                    >
                      {currentOmitMetrics.metric_name.split(":")[0]}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography
                    style={{ fontWeight: "600", paddingLeft: "10px" }}
                  >
                    {currentOmitMetrics.metric_name.split(":")[1]}
                  </Typography>
                </div>
              </div>
              {currentOmitMetrics.metric_desc && (
                <Typography style={{ fontSize: 12, padding: "15px 0px" }}>
                  {currentOmitMetrics.metric_desc}
                </Typography>
              )}
            </Box>
            <Typography style={{ fontWeight: "600", marginBottom: "20px" }}>
              Reason for Omission
            </Typography>
            <CustomTextfield
              // name="selectReason"
              fullWidth
              label="Reason For Omission"
              select
              variant="outlined"
              style={{ background: "#F0F6FD", borderRadius: "15px" }}
              value={currentOmitMetrics.omission_reason}
              // onChange={(e) => setOmissionReason(e.target.value)}
            >
              {omissionOptions.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </CustomTextfield>

            {/* <div className={classes.divider}></div> */}
            <Typography style={{ fontWeight: "600", padding: "15px 0px" }}>
              Describe The Omission in Details
            </Typography>
            <Box my={2}>
              <CustomTextfield
                name="expReason"
                label="Enter Explanation"
                fullWidth
                multiline
                minRows={5}
                size="small"
                variant="outlined"
                value={currentOmitMetrics.omission_explanation}
                // onChange={(e) => setOmissionComment(e.target.value)}
              />
            </Box>
            <div>
              {currentOmitMetrics?.omission_file &&
                currentOmitMetrics?.omission_file[0]?.name && (
                  <div
                    style={{
                      width: "240px",
                      padding: "3px",
                      borderRadius: "5px",
                    }}
                  >
                    {/* <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    className={classes.input1}
                  /> */}
                    <label htmlFor="contained-button-activityFile">
                      <Box
                        display="flex"
                        component="span"
                        alignItems="center"
                        className={classes.pointer}
                      >
                        <Box mx={2} className={classes.BoxG} width="100%">
                          <Typography
                            variant="caption"
                            style={{ color: "#3374B9" }}
                          >
                            {currentOmitMetrics.omission_file[0].name}
                          </Typography>
                        </Box>
                        <ClearIcon
                          style={{ color: "#3374B9", fontsize: "1.2em" }}
                          // onClick={() => {
                          // 	// console.log("remove file");
                          // 	setOmissionFile(null);
                          // }}
                        />
                      </Box>
                    </label>
                  </div>
                )}
            </div>
          </div>
          <div style={{ width: 850 }}>
            <Typography style={{ fontWeight: "600", paddingTop: "10px" }}>
              State the Reason For Reverting
            </Typography>

            {/* <Box paddingTop="24px">
              <Typography style={{ fontWeight: '600' }}>Omitted By</Typography>
              <Box display="flex" alignItems="center" paddingTop="24px">
                <Avatar
                  style={{ marginLeft: 20 }}
                  src={`https://avatars.dicebear.com/api/initials/${currentOmitMetrics?.omitted_by?.user_name}.svg?bold=1`}
                />
                <Box paddingLeft="8px">
                  <Typography style={{ fontWeight: '600' }}>
                    {currentOmitMetrics?.omitted_by?.user_name}
                  </Typography>
                  <Typography
                    style={{
                      color: '#9FA1AF',
                      fontSize: 14,
                      fontWeight: '400',
                      lineHeight: '21px',
                    }}
                  >
                    {currentOmitMetrics?.omitted_by?.user_email}
                  </Typography>
                </Box>
              </Box>
            </Box> */}
            <Box my={2}>
              <CustomTextfield
                name="expReason"
                label="Enter Explanation"
                fullWidth
                multiline
                minRows={5}
                size="small"
                variant="outlined"
                value={omissionComment}
                onChange={(e) => setOmissionComment(e.target.value)}
              />
            </Box>
            <Box>
              {omissionFile === "" || omissionFile === null ? (
                <div
                  style={{
                    width: "240px",
                    padding: "3px",
                    borderRadius: "5px",
                    backgroundColor: "#EBF1F8",
                  }}
                >
                  <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    onChange={(event) => setOmissionFile(event.target.files[0])}
                    className={classes.input1}
                  />
                  <label htmlFor="contained-button-activityFile">
                    <Box
                      display="flex"
                      component="span"
                      alignItems="center"
                      className={classes.pointer}
                    >
                      <AttachFileIcon
                        color="disabled"
                        size="small"
                        style={{ color: "#3374B9", fontsize: "1.2em" }}
                      />
                      <Box mx={2} className={classes.BoxG} width="100%">
                        <Typography
                          variant="caption"
                          style={{ color: "#3374B9" }}
                        >
                          Add Supporting Documents
                        </Typography>
                      </Box>
                    </Box>
                  </label>
                </div>
              ) : (
                <div
                  style={{
                    width: "240px",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    className={classes.input1}
                  />
                  <label htmlFor="contained-button-activityFile">
                    <Box
                      display="flex"
                      component="span"
                      alignItems="center"
                      className={classes.pointer}
                    >
                      <Box mx={2} className={classes.BoxG} width="100%">
                        <Typography
                          variant="caption"
                          style={{ color: "#3374B9" }}
                        >
                          {omissionFile.name}
                        </Typography>
                      </Box>
                      <ClearIcon
                        style={{ color: "#3374B9", fontsize: "1.2em" }}
                        onClick={() => {
                          setOmissionFile(null);
                        }}
                      />
                    </Box>
                  </label>
                </div>
              )}
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleRemoveOmissionDialog}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              updateOmission(
                login.token,
                assessment_id,
                currentOmitMetrics._id,
                false,
                sendGrandParentId
                  ? selectedGrandParentId
                  : currentOmitMetrics.parent_id,
                currentOmitMetrics.main_topic_id,
                true,
                omissionFile,
                omissionReason,
                omissionComment
              );
              handleRemoveOmissionDialog();
              setSendGrandParentId(false);
            }}
            variant="contained"
            color="primary"
          >
            Revert
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const subMetricSelectionDialog = () => (
    <Dialog
      maxWidth="lg"
      open={isSelectSubMetricsOpen}
      onClose={handleSelectSubMetricDialog}
    >
      <DialogTitle>
        <Grid
          container
          justifyContent="center"
          style={{ borderBottom: "1px solid #DADBE6" }}
        >
          <Grid item xs={3}>
            <Typography
              style={{
                paddingBottom: 5,
                borderBottom: "3px solid green",
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Sub Metric Selection
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div style={{ width: 850 }}>
          <UpdateDialogueContent
            dataType={dataType}
            metricTitle={metricTitle}
            metricDesc={metricDesc}
            metricResult={fieldData.metricResult}
            updateMetricResult={updateMetricResult}
            setIsSelectSubMetricsOpen={setIsSelectSubMetricsOpen}
            selectedSubmetricId={selectedSubmetricId}
            token={login.token}
            assessment_id={assessment_id}
            selectedMetricId={selectedMetricId}
            selectedTopicId={selectedTopicId}
          />
        </div>
      </DialogContent>
    </Dialog>
  );

  useEffect(() => {
    let filteredUsers = generalSettings.allUsers.filter((user) => {
      if (user.type === usersType || user.category === usersCategory) {
        return user;
      }
    });
    setSortedUsers(filteredUsers);
    if (usersType !== "All" && usersCategory !== "Show all") {
      let secondFilter = [];
      secondFilter = filteredUsers.filter((user) => {
        if (user.category === usersCategory && user.type === usersType) {
          return user;
        }
      });
      setSortedUsers(secondFilter);
    } else if (usersType === "All" && usersCategory === "Show all") {
      setSortedUsers([]);
    }
  }, [usersType, usersCategory]);

  useEffect(() => {
    // for stakeholder drawer
    let filteredUsers =
      fieldData &&
      fieldData.metrics &&
      fieldData.metrics.assigned_users_data &&
      fieldData?.metrics?.assigned_users_data.filter((user) => {
        if (user.type == stakeholderType) {
          return user;
        }
      });
    filteredUsers && setSortedUsers(filteredUsers);
  }, [stakeholderType]);

  const [currentMetricDataRedux, setCurrentMetricData] = useState(
    props.fieldData.metrics.metric_data
  );
  const [filtered, setFiltered] = useState([]);
  const [isApproverAccordion, setIsApproverAccordion] = useState(null);
  useEffect(() => {
    let copy =
      fieldData.metrics.metric_data &&
      JSON.parse(JSON.stringify(fieldData.metrics.metric_data));
    if (sort === "Show all") {
      let filtered =
        copy &&
        copy
          .map((item) => item)
          .filter((metric) => {
            return metric;
          });
      setCurrentMetricData(filtered);
      setFiltered([]);
    }
    if (sort === "Awaiting Approval") {
      let filtered = copy
        .map((item) => item)
        .filter((metric) => {
          if (metric.sub_metrics_data.length === 0) {
            if (metric.is_awaiting_approval && !metric.is_approved)
              return metric;
          } else if (metric.sub_metrics_data.length > 0) {
            let filteredSubMetric = metric.sub_metrics_data.filter((sm) => {
              if (sm.is_awaiting_approval && !sm.is_approved) {
                return sm;
              }
            });
            metric.sub_metrics_data = filteredSubMetric;

            if (filteredSubMetric.length > 0) return metric;
          }
        });

      setFiltered(filtered);
    }
    if (sort === "Yet to be Assigned") {
      let filtered = copy.filter((metric) => {
        if (metric.sub_metrics_data.length === 0) {
          if (
            !metric.is_awaiting_approval &&
            !metric.is_approved &&
            !metric.in_progress
          )
            return metric;
        } else if (metric.sub_metrics_data.length > 0) {
          let filteredSubMetric = metric.sub_metrics_data.filter((sm) => {
            if (
              !sm.is_awaiting_approval &&
              !sm.is_approved &&
              !metric.in_progress
            ) {
              return sm;
            }
          });
          metric.sub_metrics_data = filteredSubMetric;
          if (metric.sub_metrics_data.length > 0) return metric;
        } else {
        }
      });
      setFiltered(filtered);
    }
    if (sort === "Metrics In Progress") {
      let filtered = copy.filter((metric) => {
        if (metric.sub_metrics_data.length === 0) {
          if (
            !metric.is_awaiting_approval &&
            !metric.is_approved &&
            metric.in_progress
          )
            return metric;
        } else if (metric.sub_metrics_data.length > 0) {
          let filteredSubMetric = metric.sub_metrics_data.filter((sm) => {
            if (
              !sm.is_awaiting_approval &&
              !sm.is_approved &&
              metric.in_progress
            ) {
              return sm;
            }
          });
          metric.sub_metrics_data = filteredSubMetric;
          if (metric.sub_metrics_data.length > 0) return metric;
        } else {
        }
      });
      setFiltered(filtered);
    }
    if (sort === "Completed") {
      let filtered = copy.filter((metric) => {
        if (metric.sub_metrics_data.length === 0) {
          if (metric.is_completed && metric.is_approved) return metric;
        } else if (metric.sub_metrics_data.length > 0) {
          let filteredSubMetric = metric.sub_metrics_data.filter((sm) => {
            if (sm.is_completed && sm.is_approved) {
              return sm;
            }
          });
          metric.sub_metrics_data = filteredSubMetric;
          if (metric.sub_metrics_data.length > 0) return metric;
        }
      });
      setFiltered(filtered);
    }
    if (sort === "Approved") {
      let filtered = copy.filter((metric) => {
        if (metric.sub_metrics_data.length === 0) {
          if (!metric.is_completed && metric.is_approved) return metric;
        } else if (metric.sub_metrics_data.length > 0) {
          let filteredSubMetric = metric.sub_metrics_data.filter((sm) => {
            if (!sm.is_completed && sm.is_approved) {
              return sm;
            }
          });
          metric.sub_metrics_data = filteredSubMetric;
          if (metric.sub_metrics_data.length > 0) return metric;
        }
      });
      setFiltered(filtered);
    }
  }, [sort, fieldData.metrics.metric_data]);

  let disclosureStatus = [
    { icon: <StarBorderIcon />, text: "View All Disclosures" },
    { icon: <ScheduleIcon />, text: "Awaiting Disclosures" },
    { icon: <DoubleArrowIcon />, text: "Disclosures" },
    { icon: <DoneIcon />, text: "Completed Disclosures" },
  ];

  let userType = ["All", "Internal", "External"];

  const [viewGuidellines, setViewGuideLines] = useState(false);

  const showReviewMetricListRight = (toMap, anchorEl) => {
    return toMap.map((metric, index) => {
      return metric.sub_metrics.length > 0 ? (
        <React.Fragment key={index}>
          <TableRow>
            <TableCell component="th" scope="row">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "42px",
                  height: "30px",
                  padding: "5px",
                  border: "none",
                  backgroundColor: "#EBF1F8",
                  color: "#3374B9",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    border: "1px solid #3374B9",
                    backgroundColor: "#3374B9",
                    margin: "2px",
                    borderRadius: "5px",
                    width: "3px",
                    height: "18px",
                    fontWeight: "bold",
                  }}
                ></div>
                <div>
                  <Typography style={{ padding: "2px 5px 2px 2px" }}>
                    {index + 1}
                  </Typography>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ fontSize: "13px" }} colSpan={2}>
              {metric.metric_name}
              {metric.metric_desc}
            </TableCell>
          </TableRow>

          {metric.sub_metrics.map((subMetric, subIndex) => (
            <TableRow
              key={subIndex}
              style={
                subMetric.omit
                  ? { backgroundColor: "#e9e9e9", padding: "5px" }
                  : {}
              }
            >
              <TableCell
                component="th"
                style={{
                  textAlign: "center",
                  color: subMetric.omit ? "grey" : "inherit",
                }}
                scope="row"
              >
                {`${index + 1}(${subMetric.metric_name.split(":")[0]})`}
                {/* {`(${subMetric.metric_name.substring(
                    0,
                    1
                  )})`} */}
              </TableCell>
              <TableCell
                style={{
                  fontSize: "13px",
                  color: subMetric.omit ? "grey" : "inherit",
                }}
              >
                {subMetric.metric_name.split(":")[1]}
                {subMetric.metric_desc}
              </TableCell>
              <TableCell className={classes.statusCell}>
                <div>
                  {
                    <StatusMenu
                      {...props}
                      metric={subMetric}
                      parent_id={metric.parent_id}
                    />
                  }
                </div>
              </TableCell>
              <TableCell align="center" style={{ padding: 0 }}>
                {!subMetric.omit ? (
                  <Button
                    size="small"
                    className={classes.omissionButton}
                    onClick={() => {
                      setIsOmissionDialog(true);
                      setSendGrandParentId(true);
                      setSelectedGrandParentId(metric.parent_id);
                      setCurrentOmitMetrics(subMetric);
                    }}
                  >
                    Omission
                  </Button>
                ) : (
                  <Button
                    className={classes.omissionButton}
                    size="small"
                    onClick={() => {
                      setIsRemoveOmissionDialog(true);
                      setSendGrandParentId(true);
                      setSelectedGrandParentId(metric.parent_id);
                      setCurrentOmitMetrics(subMetric);
                    }}
                  >
                    Revert
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      ) : (
        <TableRow
          key={index}
          style={
            metric.omit ? { backgroundColor: "#e9e9e9", padding: "5px" } : {}
          }
        >
          <TableCell component="th" scope="row">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "42px",
                height: "30px",
                padding: "5px",
                border: "none",
                backgroundColor: "#EBF1F8",
                // backgroundColor: metric.omit ? "#e9e9e9": "#EBF1F8",
                color: "#3374B9",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  border: "1px solid #3374B9",
                  backgroundColor: "#3374B9",
                  margin: "2px",
                  borderRadius: "5px",
                  width: "3px",
                  height: "18px",
                  fontWeight: "bold",
                }}
              ></div>
              <div>
                <Typography style={{ padding: "2px 5px 2px 2px" }}>
                  {index + 1}
                </Typography>
              </div>
            </div>
          </TableCell>
          <TableCell
            style={{
              fontSize: "13px",
              color: metric.omit ? "grey" : "inherit",
            }}
          >
            {metric.metric_name}
            {metric.metric_desc}
          </TableCell>
          <TableCell className={classes.statusCell}>
            <div>
              {
                <StatusMenu
                  {...props}
                  metric={metric}
                  parent_id={metric.parent_id}
                />
              }
            </div>
          </TableCell>
          <TableCell align="center" style={{ pading: 0 }}>
            {!metric.omit ? (
              <Button
                className={classes.omissionButton}
                onClick={() => {
                  setIsOmissionDialog(true);
                  setCurrentOmitMetrics(metric);
                }}
                size="small"
              >
                Omission
              </Button>
            ) : (
              <Button
                size="small"
                className={classes.omissionButton}
                onClick={() => {
                  setIsRemoveOmissionDialog(true);
                  setCurrentOmitMetrics(metric);
                }}
              >
                Revert
              </Button>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };
  const [statusData, setStatusData] = useState({
    status: "",
    totalNumberOfDisclosures: 0,
  });
  useEffect(() => {
    // to check if all the metrics and submetrics are approved to
    // show the audit right tab status
    let totalNumberOfDisclosures = 0;
    let awaiting = 0,
      approved = 0,
      completed = 0,
      inProgress = 0;
    fieldData.reviewMetric &&
      fieldData.reviewMetric.map((metric) => {
        if (metric.sub_metrics.length === 0) {
          totalNumberOfDisclosures++;

          metric.is_completed
            ? completed++
            : metric.is_approved
            ? approved++
            : metric.is_awaiting_approval
            ? awaiting++
            : inProgress++;
        } else {
          metric.sub_metrics.map((submetric) => {
            totalNumberOfDisclosures++;

            submetric.is_completed
              ? completed++
              : submetric.is_approved
              ? approved++
              : submetric.is_awaiting_approval
              ? awaiting++
              : inProgress++;
          });
        }
      });
    inProgress
      ? setStatusData((prev) => ({
          ...prev,
          status: "In progress",
          totalNumberOfDisclosures,
        }))
      : awaiting
      ? setStatusData((prev) => ({
          ...prev,
          status: "Awaiting Approval",
          totalNumberOfDisclosures,
        }))
      : approved
      ? setStatusData((prev) => ({
          ...prev,
          status: "Approved",
          totalNumberOfDisclosures,
        }))
      : completed &&
        setStatusData((prev) => ({
          ...prev,
          status: "Completed",
          totalNumberOfDisclosures,
        }));
  }, [fieldData.reviewMetric]);

  return (
    <>
      {isAssignDialogOpen && assignDrawer()}
      {true && stakeholderDrawer()}
      {/* {isAssignDialogOpen && assignMetrics()} */}
      {isOmissionDialog && omissionDialog()}
      {isRemoveOmissionDialog && removeOmissionDialog()}
      {isSelectSubMetricsOpen && subMetricSelectionDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__reportRight"
      >
        <CardContent style={{ padding: 10 }}>
          {fieldData.reportTab === 0 && (
            <>
              {!finalData.open && !viewGuidellines ? (
                <>
                  {fieldData.currentMetric._id && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: guidelines?.length !== 0 ? "68%" : "80%",
                        }}
                        className={classes.homeHeadingContainer}
                      >
                        <TopicIcon />
                        <Typography
                          style={{
                            fontWeight: "600",
                            wordBreak: "break-all",
                            hyphens: "auto",
                          }}
                        >
                          {fieldData.currentMetric.metric_name}
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                        }}
                      >
                        {/* {guidelines.length !== 0 && (
                          <CustomButton
                            style={{ textTransform: "none", color: "#3374B9" }}
                            onClick={() => setViewGuideLines(true)}
                            startIcon={<NearMeOutlinedIcon />}
                          >
                            Guidelines
                          </CustomButton>
                        )} */}
                        <CustomButton
                          disabled={
                            fieldData.metrics?.metric_data &&
                            fieldData.metrics.metric_data.length === 0
                          }
                          onClick={() =>
                            handleOpenAssignMetric(
                              fieldData.metrics.metric_data
                            )
                          }
                          startIcon={<PersonAddOutlinedIcon />}
                        >
                          Assign
                        </CustomButton>
                      </div>
                    </Box>
                  )}
                  <div
                    className="scroll"
                    style={{ paddingBottom: "25px", height: "63vh" }}
                  >
                    {fieldData.metrics.metric_data &&
                    fieldData.metrics.metric_data.length > 0 ? (
                      <>
                        <div>
                          {false && (
                            <FormControl fullWidth style={{ color: "#3374B9" }}>
                              <InputLabel
                                style={{ color: "#3374B9" }}
                                id="demo-simple-select-label"
                              >
                                Guidelines
                              </InputLabel>
                              <Select
                                style={{ color: "#3374B9" }}
                                value=""
                                //   label="Age"
                                //   onChange={handleChange}
                              >
                                <MenuItem value={10}>
                                  <Guidelines
                                    guidelines={guidelines}
                                    setViewGuideLines={setViewGuideLines}
                                  />
                                </MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                              </Select>
                            </FormControl>
                          )}

                          {/* <FormControl
                            style={{ color: '#3374B9', width: '350px' }}
                          >
                            <Select
                              style={{
                                // border: '1px solid #C0D4E9',
                                backgroundColor: '#FEFEFE',
                                alignItems: 'center',
                                borderRadius: '8px',
                                height: '40px',
                                marginTop: '15px',
                              }}
                              value={sort}
                              onChange={(event) => {
                                const {
                                  target: { value },
                                } = event;
                                setSort(value);
                              }}
                              input={<OutlinedInput />}
                            >
                              {sortList.map((item) => {
                                return (
                                  <MenuItem
                                    style={{ alignItems: 'center' }}
                                    key={item}
                                    value={item.text}
                                  >
                                    {item.icon}
                                    {item.text}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl> */}
                          <Grid style={{ marginTop: "15px" }}>
                            <CustomTextfield
                              variant="outlined"
                              size="small"
                              select
                              value={sort}
                              style={{
                                width: "250px",
                                borderRadius: "8px",

                                height: "40px",
                              }}
                              onChange={(event) => {
                                const {
                                  target: { value },
                                } = event;
                                setSort(value);
                              }}
                            >
                              {sortList?.map((item, index) => (
                                <MenuItem
                                  style={{
                                    display: "flex",
                                  }}
                                  key={index}
                                  value={item.text}
                                >
                                  <Grid container>
                                    <Grid item>
                                      <Icon>{item.icon}</Icon>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "2px",
                                        }}
                                      >{`${item?.text}`}</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                              ))}
                            </CustomTextfield>
                          </Grid>
                          <Grid container item md={12} sm={12}>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                border: "none",
                                borderRadius: "8px",
                                width: "130px",
                                height: "110px",
                                // backgroundColor: '#EBF1F8',
                                alignItems: "center",
                                marginTop: "20px",
                              }}
                            >
                              <Box width="100%" mb="2px">
                                <Typography
                                  style={{
                                    color: "#3374B9",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  Total users Assigned
                                </Typography>
                              </Box>
                              <Box width="fit-content" mx="auto">
                                <AvatarGroup
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                  }}
                                  size="small"
                                  spacing="medium"
                                  max={3}
                                >
                                  {fieldData &&
                                    fieldData.metrics.assigned_users_data.map(
                                      (avatar, index) => (
                                        <Avatar
                                          key={index}
                                          src={avatar.profile_img.url}
                                          alt={avatar.name}
                                        />
                                      )
                                    )}
                                </AvatarGroup>
                              </Box>
                              <Grid
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsStakeholderDrawerOpen(true)}
                              >
                                <Typography
                                  style={{
                                    // wordBreak: 'break',
                                    color: "#3374B9",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    padding: "8px 2px 8px 8px",
                                  }}
                                >
                                  View
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                border: "1px solid lightGrey",
                                borderRadius: "8px",
                                width: "130px",
                                height: "110px",
                                // backgroundColor: '#EBF1F8',
                                marginTop: "20px",
                                marginLeft: "15px",
                              }}
                            >
                              <Grid
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                {" "}
                                <Typography
                                  style={{
                                    // wordBreak: 'break',
                                    color: "#3374B9",
                                    fontSize: "14px",

                                    padding: "8px 2px 8px 8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Total data points
                                </Typography>
                              </Grid>
                              <Grid
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "36px",
                                    alignContent: "bottom",
                                    padding: "0px 12px",
                                    color: "#3374B9",
                                    fontWeight: 500,
                                  }}
                                >
                                  {fieldData.metrics &&
                                  !fieldData.metrics.total_data_points
                                    ? "-"
                                    : fieldData.metrics &&
                                      fieldData.metrics.total_data_points}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                border: "1px solid lightGrey",
                                borderRadius: "8px",
                                width: "130px",
                                height: "110px",
                                // backgroundColor: '#EBF1F8',
                                marginTop: "20px",
                                marginLeft: "15px",
                              }}
                            >
                              <Grid style={{ width: "100%" }}>
                                {" "}
                                <Typography
                                  style={{
                                    // wordBreak: 'break',
                                    color: "#3374B9",
                                    fontSize: "14px",

                                    padding: "8px 2px 8px 8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Data Points Assigned
                                </Typography>
                              </Grid>
                              <Grid
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "36px",
                                    alignContent: "bottom",
                                    padding: "0px 12px",
                                    color: "#3374B9",
                                    fontWeight: 500,
                                  }}
                                >
                                  {fieldData.metrics &&
                                  fieldData.metrics.total_assigned_metrics === 0
                                    ? "-"
                                    : fieldData.metrics &&
                                      fieldData.metrics.total_assigned_metrics}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                border: "1px solid lightGrey",
                                borderRadius: "8px",
                                width: "130px",
                                height: "110px",
                                // backgroundColor: '#EBF1F8',
                                marginTop: "20px",
                                marginLeft: "15px",
                              }}
                            >
                              <Grid style={{ width: "100%" }}>
                                {" "}
                                <Typography
                                  style={{
                                    // wordBreak: 'break',
                                    color: "#3374B9",
                                    fontSize: "14px",
                                    // textAlign: 'left',
                                    padding: "8px 2px 8px 8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Data Points Approved
                                </Typography>
                              </Grid>
                              <Grid
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "36px",
                                    alignContent: "bottom",
                                    padding: "0px 12px",
                                    color: "#3374B9",
                                    fontWeight: 500,
                                  }}
                                >
                                  {fieldData.metrics &&
                                  fieldData.metrics.total_approved_metrics === 0
                                    ? "-"
                                    : fieldData.metrics &&
                                      fieldData.metrics.total_approved_metrics}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                        <Box>
                          {false && (
                            <>
                              <Typography className={classes.blueText}>
                                Technical Protocols
                              </Typography>
                              <Typography className={classes.grayText}>
                                1. Phasellus elementum integer libero, egestas
                                libero dignissim amet magna et. Mi lectus diam
                                elementum cras augue enim felis aliquet.
                              </Typography>
                              <Typography className={classes.grayText}>
                                2. Orci et vitae malesuada augue ultrices.
                                Accumsan, massa amet dictum vel eget sed duis
                                nam orci.
                              </Typography>
                              <Typography className={classes.blueText}>
                                Metric Notes
                              </Typography>
                              <Typography className={classes.grayText}>
                                1. Phasellus elementum integer libero, egestas
                                libero dignissim amet magna et. Mi lectus diam
                                elementum cras augue enim felis aliquet.
                              </Typography>
                              <Typography className={classes.grayText}>
                                2. Orci et vitae malesuada augue ultrices.
                                Accumsan, massa amet dictum vel eget sed duis
                                nam orci.
                              </Typography>
                            </>
                          )}
                        </Box>
                        {/* <TableContainer component={Paper}> */}
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    borderBottom: 0,
                                    padding: "5px 0px",
                                  }}
                                >
                                  Disclosure
                                </TableCell>
                                <TableCell
                                  style={{ borderBottom: 0, padding: 5 }}
                                >
                                  Description
                                </TableCell>
                                <TableCell
                                  style={{ borderBottom: 0, padding: 5 }}
                                  align="center"
                                >
                                  Status
                                </TableCell>
                                <TableCell
                                  style={{ borderBottom: 0, padding: 0 }}
                                  align="center"
                                >
                                  Explore
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sort === "Show all" ? (
                                currentMetricDataRedux &&
                                currentMetricDataRedux?.map((metric, index) => (
                                  <SingleMetricReport
                                    {...props}
                                    setFinalData={setFinalData}
                                    metric={metric}
                                    index={index}
                                    key={index}
                                    setGuidelines={setGuidelines}
                                  />
                                ))
                              ) : filtered.length === 0 ? (
                                <TableRow>
                                  <TableCell>
                                    <Typography style={{ textAlign: "center" }}>
                                      No Data Found
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                filtered.length !== 0 &&
                                filtered.map((metric, index) => (
                                  <SingleMetricReport
                                    {...props}
                                    setFinalData={setFinalData}
                                    metric={metric}
                                    index={index}
                                    key={index}
                                    setGuidelines={setGuidelines}
                                  />
                                ))
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : fieldData.currentMetric &&
                      fieldData.currentMetric._id &&
                      fieldData.metrics.metric_data &&
                      fieldData.metrics.metric_data.length === 0 ? (
                      <>
                        {" "}
                        <Card
                          variant="elevation"
                          elevation={0}
                          className={classes.CardLoader}
                        >
                          <CardContent className={classes.CardContentLoader}>
                            <CircularProgress className={classes.loader} />
                          </CardContent>
                        </Card>
                      </>
                    ) : (
                      <>
                        <Box className={classes.box}>
                          <Typography className={classes.typography}>
                            Select a Topic to begin with
                          </Typography>
                        </Box>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {viewGuidellines ? (
                    <Guidelines
                      guidelines={guidelines}
                      setViewGuideLines={setViewGuideLines}
                    />
                  ) : (
                    <FinalSingleMetricReport
                      {...finalData?.data}
                      {...props}
                      guidelines={guidelines}
                      index={finalData?.index}
                    />
                  )}
                </>
              )}
            </>
          )}

          {fieldData.reportTab === 1 && (
            <>
              {fieldData.reviewMetric && fieldData.reviewMetric.length > 0 ? (
                <>
                  <Box>
                    <Typography className={classes.typography}>
                      {fieldData.currentReviewMetric.metric_name}
                    </Typography>
                    {false && (
                      <Typography className={classes.grayText}>
                        Select the sub Metric you want to proceed with after
                        carefully reading them. Tempus quisque diam non tempus.
                        Semper risus, arcu amet.
                      </Typography>
                    )}
                  </Box>
                  <div
                    className="scroll"
                    style={{ paddingBottom: "25px", height: "63vh" }}
                    id="container"
                  >
                    <div
                      style={{
                        padding: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <CustomTextfield
                          variant="outlined"
                          size="small"
                          value={searchText}
                          placeholder="Search"
                          style={{
                            width: "300px",
                            borderRadius: "8px",
                            // height: '30px',
                          }}
                          onChange={handleReviewMetricSearch}
                          // onChange={(event) => {
                          //   const {
                          //     target: { value },
                          //   } = event;
                          //   // setSort(value);
                          // }}
                        ></CustomTextfield>
                      </div>
                      <div>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          bgcolor={true ? "#EBF6EF" : "#E9E9E9"}
                          color={
                            true
                              ? statusData.status === "Completed" ||
                                statusData.status === "Approved"
                                ? "#5BB37F"
                                : "#15314E"
                              : "#9FA1AF"
                          }
                          p={1}
                          borderRadius="8px"
                          maxHeight="32px"
                          maxWidth="147px"
                        >
                          <Box
                            borderRadius="50%"
                            height={12}
                            width={12}
                            bgcolor={
                              true
                                ? statusData.status === "Completed" ||
                                  statusData.status === "Approved"
                                  ? "#5BB37F"
                                  : "#EBD947"
                                : "#9FA1AF"
                            }
                          />

                          <Box pl={1} className={classes.statusText}>
                            {statusData.status}
                          </Box>
                        </Box>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          float: "right",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "large", fontWeight: "500" }}
                        >
                          {statusData.totalNumberOfDisclosures}
                        </Typography>
                        <Typography
                          style={{ fontSize: "13px", padding: "0px 5px" }}
                        >
                          Disclosures
                        </Typography>
                      </div>
                    </div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                borderBottom: 0,
                                padding: "5px 0px",
                              }}
                            >
                              Disclosure
                            </TableCell>
                            <TableCell style={{ borderBottom: 0, padding: 5 }}>
                              Description
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: 0, padding: 5 }}
                              align="center"
                            >
                              Status
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: 0, padding: 0 }}
                              align="center"
                            >
                              Explore
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reviewMetricFiltered.length > 0 ? (
                            showReviewMetricListRight(
                              reviewMetricFiltered,
                              anchorEl
                            )
                          ) : searchText === "" ? (
                            showReviewMetricListRight(
                              fieldData.reviewMetric,
                              anchorEl
                            )
                          ) : (
                            <TableRow>
                              <Typography>No Metric Found</Typography>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              ) : (
                // <div className="scroll" style={{ height: "61vh" }}>
                <div className="scroll">
                  <Box className={classes.box}>
                    <Typography className={classes.typography}>
                      Select a Metric to begin with
                    </Typography>
                  </Box>
                </div>
              )}
            </>
          )}

          {fieldData.reportTab === 2 && (
            <>
              <Box className={classes.title}>Blockchain data recorded</Box>
              <div
                className="scroll"
                // style={{ paddingTop: 25, height: "58vh" }}
                style={{ paddingTop: 25 }}
              >
                {fieldData.hashlinks.length !== 0 &&
                  fieldData.hashlinks.map((hashLink, index) => (
                    <ReportCard
                      key={index}
                      hashLink={hashLink.hash_link}
                      userName={hashLink.user_name}
                      date={hashLink.date}
                      profileUrl={hashLink.user_profile.url}
                      index={index}
                    />
                  ))}
                {fieldData.hashlinks.length === 0 && (
                  <>
                    <Box className={classes.imageContainer}>
                      <img src={blockchain} alt="" />
                    </Box>
                    <Paper
                      variant="outlined"
                      className={classes.blockChainPaper}
                    >
                      Once the data is frozen the corresponding hash link will
                      appear over here. Each revision will have its own hash
                      link.
                    </Paper>
                  </>
                )}

                {fieldData.freezeLoading && <EncryptionProgress />}
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

// export default ReportDataRight;2435
export default React.memo(ReportDataRight);

// {{fieldData.reviewMetric.map((metric, index) => (
//   <>
//     {/* {/* TO ADD AND REMOVE OMISSION  */}

//     {!metric.omit ? (
//       <Box
//         key={metric.metric_name}
//         className={classes.box1}
//       >
//         <Typography className={classes.typography}>
//           {metric.metric_name}
//         </Typography>
//         <Typography className={classes.typography1}>
//           {metric.metric_desc}
//         </Typography>
//         {metric.sub_metrics.length === 0 && (
//           <MuiThemeProvider theme={buttonTheme}>
//             <Box className={classes.box2}>
//               <Button
//                 onClick={() => {
//                   setIsOmissionDialog(true);
//                   setCurrentOmitMetrics(metric);
//                 }}
//                 variant="contained"
//                 color="primary"
//               >
//                 Omission
//               </Button>
//             </Box>
//           </MuiThemeProvider>
//         )}
//       </Box>
//     ) : (
//       <Box
//         style={{
//           backgroundColor: '#d7dfe5',
//           borderRadius: 5,
//           margin: '10px 0px',
//           padding: 15,
//         }}
//       >
//         <Typography
//           style={{ fontWeight: '600', color: '#9FA1AF' }}
//         >
//           {metric.metric_name}
//         </Typography>
//         <Typography
//           style={{
//             fontSize: 12,
//             padding: '15px 0px',
//             color: '#9FA1AF',
//           }}
//         >
//           {metric.metric_desc}
//         </Typography>
//         <MuiThemeProvider theme={buttonTheme}>
//           <Box className={classes.box2}>
//             <Button
//               onClick={() => {
//                 setIsRemoveOmissionDialog(true);
//                 setCurrentOmitMetrics(metric);
//               }}
//               variant="contained"
//               color="primary"
//             >
//               Remove Omission
//             </Button>
//           </Box>
//         </MuiThemeProvider>
//       </Box>
//     )}

//   {!metric.omit &&
//       metric.sub_metrics.length === 0 && (
//         <Box
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             width: '99%',
//           }}
//         >
//           <Box
//             style={{
//               display: 'flex',
//               justifyContent: 'space-between',
//               margin: '10px 0px',
//               alignItems: 'center',
//             }}
//           >
//             <Typography
//               style={{ fontWeight: '600', fontSize: 14 }}
//             >
//               {' '}
//             </Typography>
//             <Box
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//               }}
//             >
//               <CustomButton
//                 name={`${metric.metric_name.substring(
//                   0,
//                   4
//                 )}${index}`}
//                 disabled={metric.no_of_data === 0}
//                 onClick={() => {
//                   setDataType(metric.data_type);
//                   setMetricTitle(metric.metric_name);
//                   setSelectedSubmetricId(metric._id);
//                   setSelectedMetricId(metric.parent_id);
//                   setSelectedTopicId(metric.main_topic_id);
//                   setIsSelectSubMetricsOpen(true);
//                   metricResult(
//                     login.token,
//                     assessment_id,
//                     metric._id
//                   );
//                 }}
//               >
//                 Update
//               </CustomButton>
//               <Avatar
//                 style={{
//                   width: 30,
//                   height: 30,
//                   fontSize: 12,
//                   backgroundColor: '#D6E3F1',
//                   color: '#3374B9',
//                   marginLeft: 5,
//                 }}
//               >
//                 {metric.no_of_data}
//               </Avatar>
//             </Box>
//           </Box>
//           <SingleMetricReportReview
//             {...props}
//             metric={metric}
//             index={index}
//             key={index}
//           />
//         </Box>
//       )}

//     {!metric.omit &&
//       metric.sub_metrics.length > 0 &&
//       metric.sub_metrics.map((metric2, index2) => {
//         return (
//           <Box
//             key={metric2._id}
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//               width: '99%',
//             }}
//           >
//             <Box>
//               <Box
//                 style={{
//                   backgroundColor: metric2.omit
//                     ? '#d7dfe5'
//                     : '#DDE9F6',
//                   borderRadius: 5,
//                   margin: '10px 0px',
//                   padding: 15,
//                 }}
//               >
//                 <Typography
//                   style={{
//                     fontWeight: '600',
//                     fontSize: 14,
//                   }}
//                 >
//                   {metric2.metric_name}
//                 </Typography>
//                 <Typography
//                   style={{
//                     fontSize: 12,
//                     padding: '15px 0px',
//                   }}
//                 >
//                   {metric2.metric_desc}
//                 </Typography>
//                 {!metric2.omit ? (
//                   <MuiThemeProvider theme={buttonTheme}>
//                     <Box className={classes.box2}>
//                       <Button
//                         onClick={() => {
//                           setIsOmissionDialog(true);
//                           setSendGrandParentId(true);
//                           setSelectedGrandParentId(
//                             metric.parent_id
//                           );
//                           setCurrentOmitMetrics(metric2);
//                         }}
//                         variant="contained"
//                         color="primary"
//                       >
//                         Omission
//                       </Button>
//                     </Box>
//                   </MuiThemeProvider>
//                 ) : (
//                   <MuiThemeProvider theme={buttonTheme}>
//                     <Box className={classes.box2}>
//                       <Button
//                         onClick={() => {
//                           setIsRemoveOmissionDialog(true);
//                           setSendGrandParentId(true);
//                           setSelectedGrandParentId(
//                             metric.parent_id
//                           );
//                           setCurrentOmitMetrics(metric2);
//                         }}
//                         variant="contained"
//                         color="primary"
//                       >
//                         Remove Omission
//                       </Button>
//                     </Box>
//                   </MuiThemeProvider>
//                 )}
//               </Box>
//             </Box>
//             {!metric2.omit && (
//               <>
//                 <Box
//                   style={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'flex-end',
//                   }}
//                 >
//                   <CustomButton
//                     name={`${metric2.metric_name.substring(
//                       0,
//                       4
//                     )}${index2}`}
//                     disabled={metric2.no_of_data === 0}
//                     onClick={() => {
//                       setDataType(metric2.data_type);
//                       setMetricTitle(metric2.metric_name);
//                       setMetricDesc(metric2.metric_desc);
//                       setSelectedSubmetricId(metric2._id);
//                       setSelectedMetricId(metric.parent_id);
//                       setSelectedTopicId(
//                         metric2.main_topic_id
//                       );
//                       setIsSelectSubMetricsOpen(true);
//                       metricResult(
//                         login.token,
//                         assessment_id,
//                         metric2._id
//                       );
//                     }}
//                   >
//                     Update
//                   </CustomButton>
//                   <Avatar
//                     style={{
//                       width: 30,
//                       height: 30,
//                       fontSize: 12,
//                       backgroundColor: '#D6E3F1',
//                       color: '#3374B9',
//                       marginLeft: 5,
//                     }}
//                   >
//                     {metric2.no_of_data}
//                   </Avatar>
//                 </Box>

//                 <SingleMetricReportReview
//                   {...props}
//                   metric={metric2}
//                   index={index}
//                 />
//               </>
//             )}
//           </Box>
//         );
//       })}
//   </>
// ))}
// }
